import { Modal } from 'antd'
import { COMMON_IMAGE } from '../image-src'
import styled from 'styled-components'

const Background = styled.img`
  border-radius: 24px 24px 0 0;
`

const Button = styled.button`
  background: #ff8324;
  border-radius: 32px;
  width: 100%;
  padding: 14px 16px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
`

export const ModalError = ({
  title,
  desc,
  buttonText,
  buttonOnClick,
  isOpen = false,
  onClickClose,
}) => {
  return (
    <Modal
      footer={null}
      open={isOpen}
      className="app-modal-error"
      onCancel={onClickClose}
      centered
      maskClosable={false}
    >
      <Background
        src={COMMON_IMAGE.modalErrorBg}
        className="absolute top-0 left-0"
        alt="decor"
        loading="lazy"
      />
      <div className="absolute w-full left-0 bottom-[11rem] flex justify-center">
        <img className="w-[176px]" src={COMMON_IMAGE.modalErrorAvatar} alt="decor" loading="lazy" />
      </div>
      <div className="text-center text-xl font-medium mb-2">{title}</div>
      <div className="text-center text-base mb-6 text-[#858A94] h-[19px]">{desc}</div>
      <div>
        <Button onClick={buttonOnClick}>{buttonText}</Button>
      </div>
    </Modal>
  )
}
