import React from 'react'
import styled from 'styled-components'
import { useRouter } from '../../common/routing'
import { HISTORY_IMGS } from './image'

const Container = styled.div`
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  text-align: center;
  background-color: white;
`

const DivMain = styled.div`
  margin-top: 13rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const HistoryEmpty = () => {
  const router = useRouter()
  return (
    <Container>
      <DivMain>
        <div className="flex justify-center mb-[1rem]">
          <img src={HISTORY_IMGS.empty} alt="empty" />
        </div>
        <h2
          style={{
            color: '#0E1329',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '24px',
            marginBottom: '0.3rem',
          }}
        >
          คุณยังไม่มีประวัติการเล่น
        </h2>
        <a className="select-none" onClick={() => router.push('detail')}>
          <span
            style={{
              color: '#3979CB',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '16px',
            }}
          >
            ดูวิธีร่วมสนุกได้ที่นี่
          </span>
        </a>
      </DivMain>
    </Container>
  )
}
export default HistoryEmpty
