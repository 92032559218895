import { httpClient } from '../../common/http'

export const requestLatestTransaction = async () => {
  try {
    const res = await httpClient.get('api/coupons/transactions/latest')
    const couponTransaction = res.data.value
    const thisRewardAccumulativeCount = res.data.thisRewardAccumulativeCount
    return {
      thisRewardAccumulativeCount,
      refId: couponTransaction.RefID,
      rewardId: couponTransaction.RewardID,
    }
  } catch (error) {
    console.error(error)
    return null
  }
}

export const requestRewardById = async (rewardId) => {
  try {
    const res = await httpClient.get(`api/coupons/rewards/${rewardId}`)
    return res.data.value
  } catch (error) {
    console.error(error)
    return null
  }
}

export const requestUnusedCouponCount = async () => {
  try {
    const res = await httpClient.get('api/coupons/unusedcount')
    return res.data.count || 0
  } catch (error) {
    console.error(error)
    return 0
  }
}
