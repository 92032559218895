import { useEffect } from 'react'
import { loadFontToDocument } from './common/font'
import { ErrorMaintenancePage, ErrorPage } from './error'
import { useAppContext } from './context'
import { AppRouterProvider } from './routes'
import { getServiceConfig } from './features/service-config/api'
import { LoadingWrapper, useLoading } from './common/loading'
import { STATIC_URL } from './config'
import 'antd/dist/reset.css'
import './index.css'

loadFontToDocument('Graphik', `${STATIC_URL}/common/fonts/graphikth-regular.woff2`, '400')
loadFontToDocument('Graphik', `${STATIC_URL}/common/fonts/graphikth-medium.woff2`, '500')
loadFontToDocument('Graphik', `${STATIC_URL}/common/fonts/graphikth-semibold.woff2`, '600')
loadFontToDocument('Graphik', `${STATIC_URL}/common/fonts/graphikth-bold.woff2`, '700')

export const App = () => {
  const appContext = useAppContext()
  const loading = useLoading(1)

  const checkServiceConfig = async () => {
    const serviceConfig = await getServiceConfig()
    if (!serviceConfig) {
      appContext.setStatusToError()
      loading.doneStep()
      return
    }
    if (serviceConfig.isMaintenance || !serviceConfig.isCampaignActive) {
      appContext.setStatusToMaintenance()
    }
    loading.doneStep()
  }

  useEffect(() => {
    checkServiceConfig()
  }, [])

  if (appContext.isMaintenance) {
    return <ErrorMaintenancePage />
  }
  if (appContext.isError) {
    return <ErrorPage />
  }
  return (
    <LoadingWrapper isLoading={loading.isLoading}>
      <AppRouterProvider />
    </LoadingWrapper>
  )
}
