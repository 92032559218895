import { STATIC_URL } from '../../config'

const prefix = `${STATIC_URL}/game/imgs`

export const IMAGE_SRC_GAME = {
  bg1: `${prefix}/bg-1.png`,
  bg2: `${prefix}/bg-2.png`,
  bg3: `${prefix}/bg-3.png`,
  bg4: `${prefix}/bg-4.png`,
  bg5: `${prefix}/bg-5.png`,
  entry: `${prefix}/entry.png`,
  path1: `${prefix}/path-01.png`,
  path2: `${prefix}/path-02.png`,
  path3: `${prefix}/path-03.png`,
  path4: `${prefix}/path-04.png`,
  path5: `${prefix}/path-05.png`,
  path6: `${prefix}/path-06.png`,
  path7: `${prefix}/path-07.png`,
  path8: `${prefix}/path-08.png`,
  path9: `${prefix}/path-09.png`,
  path10: `${prefix}/path-10.png`,
  playerAvatar: `${prefix}/player-avatar.png`,
  playerAvatarAnimated: `${prefix}/player-avatar-animated.png`,
  playerAvatarEffect1: `${prefix}/player-avatar-effect-1.png`,
  cursorHand: `${prefix}/cursor-hand.png`,
  arrowWhite: `${prefix}/arrow-white.png`,
  loadingLogo: `${prefix}/loading-logo.png`,
  pathCover: `${prefix}/path-cover.png`,
  rewardDecorNormal: `${prefix}/reward-decor-normal.png`,
  rewardDecorSpecial: `${prefix}/reward-decor-special.png`,
}
