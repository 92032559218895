export const DATA_MAP_7 = {
  1: {
    rewardPosition: 2,
    paths: [
      { x: 0.205, y: 0.067 },
      { x: 0.205, y: 0.109 },
      { x: 0.208, y: 0.157 },
      { x: 0.235, y: 0.216 },
      { x: 0.277, y: 0.241 },
      { x: 0.317, y: 0.252 },
      { x: 0.363, y: 0.249 },
      { x: 0.427, y: 0.246 },
      { x: 0.507, y: 0.246 },
      { x: 0.568, y: 0.255 },
      { x: 0.624, y: 0.255 },
      { x: 0.709, y: 0.255 },
      { x: 0.781, y: 0.252 },
      { x: 0.843, y: 0.261 },
      { x: 0.904, y: 0.303 },
      { x: 0.931, y: 0.378 },
      { x: 0.931, y: 0.429 },
      { x: 0.933, y: 0.49 },
      { x: 0.933, y: 0.555 },
      { x: 0.931, y: 0.599 },
      { x: 0.925, y: 0.655 },
      { x: 0.909, y: 0.709 },
      { x: 0.867, y: 0.748 },
      { x: 0.803, y: 0.77 },
      { x: 0.731, y: 0.77 },
      { x: 0.664, y: 0.77 },
      { x: 0.595, y: 0.77 },
      { x: 0.544, y: 0.768 },
      { x: 0.496, y: 0.768 },
      { x: 0.451, y: 0.779 },
      { x: 0.411, y: 0.818 },
      { x: 0.395, y: 0.874 },
      { x: 0.395, y: 0.95 },
    ],
  },
  2: {
    rewardPosition: 3,
    paths: [
      { x: 0.4, y: 0.073 },
      { x: 0.403, y: 0.134 },
      { x: 0.4, y: 0.196 },
      { x: 0.4, y: 0.241 },
      { x: 0.424, y: 0.291 },
      { x: 0.443, y: 0.308 },
      { x: 0.48, y: 0.322 },
      { x: 0.52, y: 0.325 },
      { x: 0.552, y: 0.325, isFading: true },
      { x: 0.365, y: 0.504, isFading: true },
      { x: 0.365, y: 0.504 },
      { x: 0.312, y: 0.504 },
      { x: 0.269, y: 0.507 },
      { x: 0.211, y: 0.513 },
      { x: 0.133, y: 0.538 },
      { x: 0.091, y: 0.571 },
      { x: 0.067, y: 0.63 },
      { x: 0.069, y: 0.692 },
      { x: 0.077, y: 0.737 },
      { x: 0.123, y: 0.787 },
      { x: 0.173, y: 0.804 },
      { x: 0.232, y: 0.824 },
      { x: 0.283, y: 0.821 },
      { x: 0.344, y: 0.818 },
      { x: 0.411, y: 0.824 },
      { x: 0.459, y: 0.824 },
      { x: 0.512, y: 0.824 },
      { x: 0.563, y: 0.846 },
      { x: 0.592, y: 0.88 },
      { x: 0.6, y: 0.95 },
    ],
  },
  3: {
    rewardPosition: 4,
    paths: [
      { x: 0.589, y: 0.07 },
      { x: 0.597, y: 0.134 },
      { x: 0.597, y: 0.196 },
      { x: 0.597, y: 0.255 },
      { x: 0.597, y: 0.297 },
      { x: 0.597, y: 0.342 },
      { x: 0.587, y: 0.401 },
      { x: 0.568, y: 0.454 },
      { x: 0.512, y: 0.501 },
      { x: 0.475, y: 0.51 },
      { x: 0.437, y: 0.51 },
      { x: 0.408, y: 0.51 },
      { x: 0.368, y: 0.507, isFading: true },
      { x: 0.557, y: 0.322, isFading: true },
      { x: 0.557, y: 0.322 },
      { x: 0.613, y: 0.325 },
      { x: 0.669, y: 0.336 },
      { x: 0.723, y: 0.375 },
      { x: 0.749, y: 0.434 },
      { x: 0.757, y: 0.501 },
      { x: 0.744, y: 0.574 },
      { x: 0.699, y: 0.619 },
      { x: 0.643, y: 0.644 },
      { x: 0.6, y: 0.65 },
      { x: 0.541, y: 0.65, isFading: true },
      { x: 0.795, y: 0.86, isFading: true },
      { x: 0.795, y: 0.86 },
      { x: 0.795, y: 0.896 },
      { x: 0.795, y: 0.95 },
    ],
  },
  4: {
    rewardPosition: 1,
    paths: [
      { x: 0.792, y: 0.064 },
      { x: 0.795, y: 0.101 },
      { x: 0.795, y: 0.146 },
      { x: 0.795, y: 0.199 },
      { x: 0.789, y: 0.238 },
      { x: 0.779, y: 0.294 },
      { x: 0.76, y: 0.342 },
      { x: 0.728, y: 0.381 },
      { x: 0.683, y: 0.387 },
      { x: 0.627, y: 0.387 },
      { x: 0.581, y: 0.395 },
      { x: 0.517, y: 0.392 },
      { x: 0.469, y: 0.392 },
      { x: 0.408, y: 0.392 },
      { x: 0.365, y: 0.392 },
      { x: 0.317, y: 0.398 },
      { x: 0.275, y: 0.417 },
      { x: 0.243, y: 0.451 },
      { x: 0.213, y: 0.49 },
      { x: 0.203, y: 0.543 },
      { x: 0.203, y: 0.588 },
      { x: 0.203, y: 0.633 },
      { x: 0.203, y: 0.692 },
      { x: 0.208, y: 0.759 },
      { x: 0.208, y: 0.812 },
      { x: 0.205, y: 0.849 },
      { x: 0.205, y: 0.888 },
      { x: 0.205, y: 0.95 },
    ],
  },
}
