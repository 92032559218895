import { useNavigate, useSearchParams } from 'react-router-dom'
import { ROUTER_PATHS } from '../routes'

/**
 * return router object for navigate between page
 * with preserved query string
 */
export const useRouter = () => {
  const [params] = useSearchParams(window.location.search)
  const navigate = useNavigate()
  const push = (path) => navigate(`/${path}?${params.toString()}`)
  const replace = (path) => navigate(`/${path}?${params.toString()}`, { replace: true })
  return {
    PATHS: ROUTER_PATHS,
    paths: ROUTER_PATHS,
    params,
    push,
    replace,
    navigate,
  }
}
