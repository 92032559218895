import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000'
const API_KEY = process.env.REACT_APP_API_KEY || 'dev'

export const httpClient = axios.create({
  baseURL: API_URL,
  headers: {
    'x-api-key': API_KEY,
  },
})

httpClient.interceptors.request.use(
  (config) => {
    if (config.headers && !config.headers.Authorization) {
      const params = new URLSearchParams(window.location.search)
      const accessToken = params.get('token') || ''
      config.headers.Authorization = accessToken
    }
    config.params = {
      ...config.params,
      ts: new Date().getTime(),
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)
