export const DATA_MAP_10 = {
  1: {
    rewardPosition: 2,
    paths: [
      { x: 0.216, y: 0.07 },
      { x: 0.216, y: 0.12 },
      { x: 0.224, y: 0.165 },
      { x: 0.267, y: 0.204 },
      { x: 0.317, y: 0.21 },
      { x: 0.373, y: 0.218 },
      { x: 0.424, y: 0.224 },
      { x: 0.467, y: 0.241 },
      { x: 0.501, y: 0.289 },
      { x: 0.515, y: 0.336 },
      { x: 0.496, y: 0.392 },
      { x: 0.453, y: 0.434 },
      { x: 0.403, y: 0.454 },
      { x: 0.357, y: 0.459 },
      { x: 0.291, y: 0.465 },
      { x: 0.253, y: 0.487 },
      { x: 0.221, y: 0.527 },
      { x: 0.213, y: 0.585 },
      { x: 0.213, y: 0.647 },
      { x: 0.213, y: 0.711 },
      { x: 0.237, y: 0.77 },
      { x: 0.293, y: 0.804 },
      { x: 0.328, y: 0.815 },
      { x: 0.371, y: 0.821 },
      { x: 0.419, y: 0.821 },
      { x: 0.453, y: 0.821 },
      { x: 0.496, y: 0.818, isFading: true },
      { x: 0.104, y: 0.462, isFading: true },
      { x: 0.104, y: 0.462 },
      { x: 0.107, y: 0.513 },
      { x: 0.12, y: 0.569 },
      { x: 0.157, y: 0.602 },
      { x: 0.2, y: 0.613 },
      { x: 0.248, y: 0.622 },
      { x: 0.299, y: 0.625 },
      { x: 0.341, y: 0.636 },
      { x: 0.379, y: 0.664 },
      { x: 0.405, y: 0.697 },
      { x: 0.416, y: 0.751 },
      { x: 0.416, y: 0.796 },
      { x: 0.413, y: 0.849 },
      { x: 0.411, y: 0.891 },
      { x: 0.411, y: 0.95 },
    ],
  },
  2: {
    rewardPosition: 4,
    paths: [
      { x: 0.416, y: 0.073 },
      { x: 0.416, y: 0.132 },
      { x: 0.419, y: 0.188 },
      { x: 0.413, y: 0.249 },
      { x: 0.395, y: 0.303 },
      { x: 0.355, y: 0.325 },
      { x: 0.307, y: 0.328 },
      { x: 0.261, y: 0.331 },
      { x: 0.224, y: 0.331 },
      { x: 0.181, y: 0.339 },
      { x: 0.144, y: 0.359 },
      { x: 0.12, y: 0.395 },
      { x: 0.104, y: 0.448, isFading: true },
      { x: 0.536, y: 0.815, isFading: true },
      { x: 0.536, y: 0.815 },
      { x: 0.576, y: 0.821 },
      { x: 0.621, y: 0.821 },
      { x: 0.664, y: 0.818 },
      { x: 0.712, y: 0.821 },
      { x: 0.765, y: 0.835 },
      { x: 0.792, y: 0.871 },
      { x: 0.805, y: 0.899 },
      { x: 0.805, y: 0.95 },
    ],
  },
  3: {
    rewardPosition: 1,
    paths: [
      { x: 0.603, y: 0.064 },
      { x: 0.605, y: 0.112 },
      { x: 0.619, y: 0.171 },
      { x: 0.648, y: 0.207 },
      { x: 0.712, y: 0.218 },
      { x: 0.755, y: 0.221 },
      { x: 0.811, y: 0.232 },
      { x: 0.867, y: 0.241 },
      { x: 0.901, y: 0.269 },
      { x: 0.925, y: 0.311 },
      { x: 0.925, y: 0.356 },
      { x: 0.92, y: 0.401 },
      { x: 0.899, y: 0.44 },
      { x: 0.853, y: 0.462 },
      { x: 0.805, y: 0.473 },
      { x: 0.755, y: 0.482 },
      { x: 0.699, y: 0.49 },
      { x: 0.653, y: 0.518 },
      { x: 0.627, y: 0.566 },
      { x: 0.616, y: 0.613 },
      { x: 0.616, y: 0.655 },
      { x: 0.637, y: 0.7 },
      { x: 0.68, y: 0.731 },
      { x: 0.725, y: 0.748 },
      { x: 0.771, y: 0.754 },
      { x: 0.803, y: 0.751 },
      { x: 0.835, y: 0.739 },
      { x: 0.864, y: 0.725, isFading: true },
      { x: 0.624, y: 0.339, isFading: true },
      { x: 0.624, y: 0.339 },
      { x: 0.573, y: 0.356 },
      { x: 0.539, y: 0.375 },
      { x: 0.515, y: 0.42 },
      { x: 0.512, y: 0.473 },
      { x: 0.509, y: 0.527 },
      { x: 0.509, y: 0.58 },
      { x: 0.509, y: 0.636 },
      { x: 0.496, y: 0.681 },
      { x: 0.459, y: 0.711 },
      { x: 0.416, y: 0.723 },
      { x: 0.363, y: 0.725 },
      { x: 0.315, y: 0.725 },
      { x: 0.28, y: 0.728 },
      { x: 0.243, y: 0.739 },
      { x: 0.216, y: 0.782 },
      { x: 0.216, y: 0.826 },
      { x: 0.216, y: 0.877 },
      { x: 0.216, y: 0.905 },
      { x: 0.216, y: 0.95 },
    ],
  },
  4: {
    rewardPosition: 3,
    paths: [
      { x: 0.808, y: 0.07 },
      { x: 0.811, y: 0.134 },
      { x: 0.808, y: 0.202 },
      { x: 0.8, y: 0.261 },
      { x: 0.771, y: 0.297 },
      { x: 0.731, y: 0.331 },
      { x: 0.688, y: 0.333 },
      { x: 0.637, y: 0.336, isFading: true },
      { x: 0.888, y: 0.711, isFading: true },
      { x: 0.888, y: 0.711 },
      { x: 0.917, y: 0.669 },
      { x: 0.925, y: 0.611 },
      { x: 0.925, y: 0.566 },
      { x: 0.907, y: 0.515 },
      { x: 0.877, y: 0.459 },
      { x: 0.835, y: 0.431 },
      { x: 0.779, y: 0.42 },
      { x: 0.723, y: 0.426 },
      { x: 0.68, y: 0.457 },
      { x: 0.648, y: 0.487 },
      { x: 0.619, y: 0.543 },
      { x: 0.611, y: 0.58 },
      { x: 0.611, y: 0.644 },
      { x: 0.608, y: 0.686 },
      { x: 0.608, y: 0.745 },
      { x: 0.608, y: 0.787 },
      { x: 0.608, y: 0.824 },
      { x: 0.608, y: 0.877 },
      { x: 0.608, y: 0.95 },
    ],
  },
}
