import React from 'react'
import styled from 'styled-components'
import ButtonAll from '../../common/ButtonAll'
import HistoryEmpty from './HistoryEmpty'
import HistoryList from './HistoryList'
import { LoadingWrapper } from '../../common/loading'
import { useRouter } from '../../common/routing'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
`

const History = (props) => {
  const router = useRouter()
  return (
    <Container>
      <LoadingWrapper isLoading={props.isLoading}>
        {!!props.data.length && (
          <>
            <div className="p-4 text-xl font-bold bg-white">ประวัติการเล่นเกม</div>
            <div className="w-full h-full overflow-y-scroll px-4 pb-10">
              <HistoryList dataList={props.data} />
            </div>
          </>
        )}
        {!props.data.length && <HistoryEmpty />}
      </LoadingWrapper>
      <ButtonContainer>
        <ButtonAll
          Size="small"
          BgColor={'white'}
          Value={'กลับหน้าหลัก'}
          OnClickFunction={() => router.push('')}
        />
        <ButtonAll
          Size="small"
          BgColor={'rgb(255, 143, 0)'}
          Value={'เริ่มเกม'}
          Disabled={props.unusedCouponCount}
          OnClickFunction={() => router.push('game')}
        />
      </ButtonContainer>
    </Container>
  )
}
export default History
