import { useEffect, useState, useRef, useMemo } from 'react'
import styled from 'styled-components'
import {
  getStartPosition,
  isOverElement,
  randomNumber,
  useGamePathDebug,
  useIsMounted,
  wait,
} from './utils'
import { MAP } from './data'
import { IMAGE_SRC_GAME } from './image-src'
import { requestRewards, sendRequestUseCoupon } from './http'
import { useRouter } from '../../common/routing'
import { preloadImages } from '../../common/preload'
import { useModalController } from '../../common/modal-helper'
import { ModalCommonError } from '../../common/components/ModalCommonError'
import { LoadingSpin } from '../../common/loading'

const MAP_ID_MIN = 1
const MAP_ID_MAX = 10
const COUNTDOWN = 5
const SELECTION_COUNTDOWN = 10

const Page = styled.div`
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  margin: auto;
`

const PlayerAvatar = styled.img`
  position: absolute;
  z-index: 100;
  transition: all 0.1s;
`

const EntryImage = styled.img`
  width: 60px;
  margin-right: 0.1rem;
  margin-left: 0.1rem;
  z-index: 50;
  @media (min-width: 360px) {
    width: 70px;
  }
  @media (min-width: 390px) {
    margin-left: 0.375rem;
    margin-right: 0.375rem;
  }
`

const Reward = styled.div`
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  @media (min-width: 360px) {
    margin-left: -1.2rem;
    margin-right: -1.2rem;
  }
  @media (min-width: 390px) {
    margin-left: -0.9rem;
    margin-right: -0.9rem;
  }
`

const RewardImage = styled.img`
  position: absolute;
  object-fit: contain;
  height: 27.5px;
  max-width: 90px;
  @media (min-width: 360px) {
    height: 40px;
  }
  @media (min-width: 390px) {
    height: 45px;
    margin-left: -0.85rem;
    margin-right: -0.85rem;
  }
`

const RewardBase = styled.img`
  width: 80px;
  @media (min-width: 360px) {
    width: 115px;
  }
  @media (min-width: 390px) {
    width: 120px;
  }
`

const cache = {
  isAvatarFading: false,
  isAvatarFadingChanged: false,
}

const PathCover = styled.img`
  animation: pulsing 3s infinite, displaying 0.5s;
  animation-delay: 1s, 0s;
  @keyframes displaying {
    from {
      transform: scale(0.25);
    }
    to {
      transform: scale(1);
    }
  }
  @keyframes pulsing {
    from {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`

export const GamePage = () => {
  const router = useRouter()
  const modalError = useModalController()
  const isMounted = useIsMounted()
  const gameDebug = useGamePathDebug()
  const [rewards, setRewards] = useState([])
  const [countdown, setCountdown] = useState(0)
  const [selectionCountdown, setSelectionCountdown] = useState(0)
  const [isMaskVisible, setIsMaskVisible] = useState(true)
  const [isRewardLoading, setIsRewardLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isPathCoverVisible, setIsPathCoverVisible] = useState(false)
  // avatar for select path
  const [avatarVisible, setAvatarVisible] = useState(true)
  const [isAvatarTouched, setIsAvatarTouched] = useState(false)
  const [isInteractable, setIsInteractable] = useState(false)
  // avatar for running on track
  const [avatarOnTrackX, setAvatarOnTrackX] = useState(0)
  const [avatarOnTrackY, setAvatarOnTrackY] = useState(0)
  const [avatarOnTrackOpacity, setAvatarOnTrackOpacity] = useState(1)
  const [avatarOnTrackVisible, setAvatarOnTrackVisible] = useState(false)
  const avatarRef = useRef(null)
  const mapRef = useRef(null)
  const maskRef = useRef(null)
  const entryRef1 = useRef(null)
  const entryRef2 = useRef(null)
  const entryRef3 = useRef(null)
  const entryRef4 = useRef(null)
  const mapId = useMemo(() => {
    const map = +router.params.get('map')
    if (map && map >= MAP_ID_MIN && map <= MAP_ID_MAX) return map
    return randomNumber(MAP_ID_MIN, MAP_ID_MAX)
  }, [])
  const mapImageSrc = isMaskVisible ? IMAGE_SRC_GAME.path1 : IMAGE_SRC_GAME[`path${mapId}`]
  const backgroundImageSrc = useMemo(() => {
    const backgroundId = randomNumber(1, 4)
    return IMAGE_SRC_GAME[`bg${backgroundId}`]
  }, [])

  const onClickEntry = async (entryId) => {
    if (!isInteractable) return
    setIsPathCoverVisible(false)
    setIsInteractable(false)
    setAvatarVisible(false)
    setAvatarOnTrackVisible(true)
    const mapData = MAP[mapId][entryId]
    const rewardPosition = mapData.rewardPosition
    const rewardIndex = rewardPosition - 1
    const rewardRefId = rewards[rewardIndex].RefID
    const paths = mapData.paths
    const mapWidth = mapRef.current.width
    const mapHeight = mapRef.current.height
    for (const path of paths) {
      setAvatarOnTrackX(path.x * mapWidth - 35)
      setAvatarOnTrackY(path.y * mapHeight - 60)
      setAvatarOnTrackOpacity(path.isFading ? 0 : 1)
      const isFading = path.isFading || false
      let delay = 75
      if (isFading && isFading !== cache.isAvatarFading) {
        delay = 700
      }
      if (!isFading && isFading !== cache.isAvatarFading) {
        delay = 400
      }
      cache.isAvatarFadingChanged = isFading !== cache.isAvatarFading
      cache.isAvatarFading = isFading
      await wait(delay)
    }
    // if component is unmounted, do not send request
    if (!isMounted.current) return
    setIsRewardLoading(true)
    const isSuccess = await sendRequestUseCoupon(rewardRefId)
    await wait(250)
    if (!isSuccess) {
      modalError.open()
      setIsRewardLoading(false)
      return
    }
    setIsRewardLoading(false)
    router.replace(router.paths.gameReward)
  }

  const onTouchStart = () => {
    if (!isInteractable) return
    setIsAvatarTouched(true)
  }

  const onTouch = (event) => {
    if (!isInteractable) return
    const target = event.target
    if (isOverElement(target, entryRef1.current)) {
      onClickEntry(1)
      return
    }
    if (isOverElement(target, entryRef2.current)) {
      onClickEntry(2)
      return
    }
    if (isOverElement(target, entryRef3.current)) {
      onClickEntry(3)
      return
    }
    if (isOverElement(target, entryRef4.current)) {
      onClickEntry(4)
      return
    }
    const startPosition = getStartPosition()
    avatarRef.current.style.left = `${startPosition.x}px`
    avatarRef.current.style.top = `${startPosition.y}px`
    setIsAvatarTouched(false)
  }

  const onTouchMove = (event) => {
    if (!isInteractable) return
    const OFFSET = 27
    const UPDATING_THRESHOLD = 11
    const touchedElement = event.touches[0]
    const newAvatarX = touchedElement.clientX - OFFSET
    const newAvatarY = touchedElement.clientY - OFFSET
    const oldAvatarX = +avatarRef.current.style.left.replace('px', '')
    const oldAvatarY = +avatarRef.current.style.top.replace('px', '')
    if (Math.abs(oldAvatarX - newAvatarX) >= UPDATING_THRESHOLD) {
      avatarRef.current.style.left = `${newAvatarX}px`
    }
    if (Math.abs(oldAvatarY - newAvatarY) >= UPDATING_THRESHOLD) {
      avatarRef.current.style.top = `${newAvatarY}px`
    }
  }

  const onClickMask = () => {
    setRewards((prev) => {
      const cloned = [...prev]
      cloned.sort(() => Math.random() - 0.5)
      return cloned
    })
    setIsMaskVisible(false)
    setCountdown(COUNTDOWN)
  }

  useEffect(() => {
    ;(async () => {
      const startPosition = getStartPosition()
      avatarRef.current.style.left = `${startPosition.x}px`
      avatarRef.current.style.top = `${startPosition.y}px`
      const newRewards = await requestRewards()
      if (!newRewards.length) {
        setIsLoading(false)
        modalError.open()
        return
      }
      const rewardImages = newRewards.map((reward) => {
        return reward.RewardImageURL
      })
      setRewards(newRewards)
      await preloadImages([
        ...rewardImages,
        backgroundImageSrc,
        IMAGE_SRC_GAME.path1,
        IMAGE_SRC_GAME[`path${mapId}`],
        IMAGE_SRC_GAME.loadingLogo,
        IMAGE_SRC_GAME.arrowWhite,
        IMAGE_SRC_GAME.playerAvatar,
        IMAGE_SRC_GAME.playerAvatarAnimated,
        IMAGE_SRC_GAME.playerAvatarEffect1,
        IMAGE_SRC_GAME.cursorHand,
        IMAGE_SRC_GAME.entry,
        IMAGE_SRC_GAME.pathCover,
        IMAGE_SRC_GAME.rewardDecorNormal,
        IMAGE_SRC_GAME.rewardDecorSpecial,
      ])
      setTimeout(() => setIsLoading(false), 500)
    })()
  }, [])

  useEffect(() => {
    if (isMaskVisible) {
      return
    }
    if (!countdown) {
      setSelectionCountdown(SELECTION_COUNTDOWN)
      setIsInteractable(true)
      setIsPathCoverVisible(true)
      return
    }
    setTimeout(() => {
      setCountdown((prev) => prev - 1)
    }, 1000)
  }, [countdown])

  useEffect(() => {
    if (!selectionCountdown) {
      const randomizedEntryId = randomNumber(1, 4)
      onClickEntry(randomizedEntryId)
      return
    }
    if (!isInteractable) return
    setTimeout(() => {
      setSelectionCountdown((prev) => prev - 1)
    }, 1000)
  }, [selectionCountdown])

  return (
    <>
      <ModalCommonError
        isOpen={modalError.isVisible}
        onClick={async () => {
          modalError.close()
          await wait(250)
          router.replace(router.paths.home)
        }}
      />
      {isRewardLoading && (
        <div className="absolute flex justify-center items-center w-full h-full z-[10000] bg-[rgba(10,10,10,0.85)]">
          <LoadingSpin />
        </div>
      )}
      <Page backgroundImage={backgroundImageSrc}>
        {selectionCountdown > 0 && (
          <div className="absolute flex justify-end w-full pt-2 pr-2 text-4xl font-bold">
            {selectionCountdown}
          </div>
        )}
        {isLoading && (
          <div className="absolute flex justify-center items-center z-[300] bg-white w-full h-full">
            <div className="flex flex-col justify-center items-center">
              <img className="w-[120px] mb-4" src={IMAGE_SRC_GAME.loadingLogo} />
              <div className="text-2xl font-medium">เตรียมตัวสตาร์ท!...</div>
            </div>
          </div>
        )}
        {isMaskVisible && (
          <div
            ref={maskRef}
            className="absolute flex flex-col justify-evenly bg-[rgba(0,0,0,0.85)] text-white z-[200] w-full h-full"
            onClick={onClickMask}
          >
            <div>
              <div className="flex justify-center gap-[1.5rem]">
                <img src={IMAGE_SRC_GAME.arrowWhite} />
                <img src={IMAGE_SRC_GAME.arrowWhite} />
                <img src={IMAGE_SRC_GAME.arrowWhite} />
                <img src={IMAGE_SRC_GAME.arrowWhite} />
              </div>
              <div className="text-center mt-4 text-xl px-4">
                <div>เลือกของรางวัล และจำเส้นทางให้ได้ใน 5 วินาที</div>
                <div>จากนั้น ลากและปล่อยเด็กน้อยที่เส้นทาง!</div>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center">
              <img className="w-[37px] mb-3" src={IMAGE_SRC_GAME.cursorHand} />
              <div>แตะหน้าจอ เพื่อเริ่มเกม</div>
            </div>
          </div>
        )}
        {countdown > 0 && (
          <div className="absolute flex justify-center w-full mt-6">
            <div className="bg-white rounded-[48px] py-1 px-3 font-semibold text-base">
              จำเส้นทางให้ได้ใน 5 วินาที!
            </div>
          </div>
        )}
        {countdown > 0 && (
          <div className="absolute flex justify-center z-50 w-full h-full pt-[12rem]">
            <div className="flex bg-[rgba(0,0,0,0.70)] rounded-full w-[100px] h-[100px] justify-center items-center text-[3.5rem] font-semibold text-white">
              {countdown}
            </div>
          </div>
        )}
        <PlayerAvatar
          style={{
            width: isAvatarTouched ? 60 : 120,
            display: avatarVisible ? 'block' : 'none',
          }}
          ref={avatarRef}
          src={IMAGE_SRC_GAME.playerAvatarAnimated}
          onTouchStart={onTouchStart}
          onTouchCancel={onTouch}
          onTouchEnd={onTouch}
          onTouchMove={onTouchMove}
        />
        <div className="absolute overflow-hidden bottom-[2rem] left-[50%] translate-x-[-50%] w-full max-w-[425px]">
          <div className="flex justify-center mb-[-1.4rem]">
            <EntryImage
              ref={entryRef1}
              src={IMAGE_SRC_GAME.entry}
              onClick={() => onClickEntry(1)}
            />
            <EntryImage
              ref={entryRef2}
              src={IMAGE_SRC_GAME.entry}
              onClick={() => onClickEntry(2)}
            />
            <EntryImage
              ref={entryRef3}
              src={IMAGE_SRC_GAME.entry}
              onClick={() => onClickEntry(3)}
            />
            <EntryImage
              ref={entryRef4}
              src={IMAGE_SRC_GAME.entry}
              onClick={() => onClickEntry(4)}
            />
          </div>
          <div className="relative">
            <img
              className="absolute w-[180px] z-[101] opacity-[0.95]"
              style={{
                display: cache.isAvatarFadingChanged ? 'block' : 'none',
                top: avatarOnTrackY - 10,
                left: avatarOnTrackX - 50,
              }}
              src={IMAGE_SRC_GAME.playerAvatarEffect1}
            />
            <PlayerAvatar
              style={{
                width: 80,
                display: avatarOnTrackVisible ? 'block' : 'none',
                opacity: avatarOnTrackOpacity,
                top: avatarOnTrackY,
                left: avatarOnTrackX,
              }}
              src={IMAGE_SRC_GAME.playerAvatarAnimated}
            />
            <img
              className="z-0 w-full"
              ref={mapRef}
              src={mapImageSrc}
              onClick={gameDebug.logMapPosition}
            />
            {isPathCoverVisible && (
              <>
                <PathCover
                  className="absolute top-2 left-2 w-[200px]"
                  src={IMAGE_SRC_GAME.pathCover}
                />
                <PathCover
                  className="absolute top-2 right-2 w-[300px]"
                  src={IMAGE_SRC_GAME.pathCover}
                />
                <PathCover
                  className="absolute bottom-2 left-2 w-[325px]"
                  src={IMAGE_SRC_GAME.pathCover}
                />
                <PathCover
                  className="absolute bottom-32 left-2 w-[160px]"
                  src={IMAGE_SRC_GAME.pathCover}
                />
                <PathCover
                  className="absolute bottom-28 right-2 w-[150px]"
                  src={IMAGE_SRC_GAME.pathCover}
                />
                <PathCover
                  className="absolute bottom-2 right-2 w-[175px]"
                  src={IMAGE_SRC_GAME.pathCover}
                />
              </>
            )}
          </div>
          <div className="flex justify-center mt-[-2rem]">
            {rewards.map((reward) => (
              <Reward className="relative flex justify-center" key={reward.ID}>
                <RewardImage className="top-5" src={reward.RewardImageURL} />
                <RewardBase
                  src={
                    reward.IsGrandPrize
                      ? IMAGE_SRC_GAME.rewardDecorSpecial
                      : IMAGE_SRC_GAME.rewardDecorNormal
                  }
                />
              </Reward>
            ))}
          </div>
        </div>
      </Page>
    </>
  )
}
