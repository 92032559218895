export const DATA_MAP_1 = {
  1: {
    rewardPosition: 1,
    paths: [
      { x: 0.2, y: 0.07 },
      { x: 0.197, y: 0.092 },
      { x: 0.197, y: 0.12 },
      { x: 0.197, y: 0.157 },
      { x: 0.197, y: 0.188 },
      { x: 0.2, y: 0.23 },
      { x: 0.216, y: 0.275 },
      { x: 0.248, y: 0.294 },
      { x: 0.283, y: 0.291 },
      { x: 0.312, y: 0.291 },
      { x: 0.331, y: 0.291 },
      { x: 0.365, y: 0.286 },
      { x: 0.371, y: 0.305, isFading: true },
      { x: 0.205, y: 0.524, isFading: true },
      { x: 0.205, y: 0.524 },
      { x: 0.211, y: 0.529 },
      { x: 0.243, y: 0.543 },
      { x: 0.277, y: 0.543 },
      { x: 0.299, y: 0.543 },
      { x: 0.328, y: 0.56 },
      { x: 0.347, y: 0.583 },
      { x: 0.36, y: 0.597 },
      { x: 0.363, y: 0.622 },
      { x: 0.365, y: 0.653 },
      { x: 0.368, y: 0.689 },
      { x: 0.368, y: 0.717 },
      { x: 0.368, y: 0.751 },
      { x: 0.36, y: 0.79 },
      { x: 0.341, y: 0.832 },
      { x: 0.325, y: 0.843 },
      { x: 0.285, y: 0.857 },
      { x: 0.243, y: 0.86 },
      { x: 0.221, y: 0.88 },
      { x: 0.213, y: 0.908 },
      { x: 0.208, y: 0.902 },
      { x: 0.2, y: 0.95 },
      { x: 0.195, y: 0.95 },
    ],
  },
  2: {
    rewardPosition: 3,
    paths: [
      { x: 0.397, y: 0.07 },
      { x: 0.396, y: 0.08 },
      { x: 0.396, y: 0.09 },
      { x: 0.396, y: 0.1 },
      { x: 0.395, y: 0.106 },
      { x: 0.395, y: 0.148 },
      { x: 0.395, y: 0.182 },
      { x: 0.395, y: 0.218 },
      { x: 0.395, y: 0.263 },
      { x: 0.395, y: 0.3 },
      { x: 0.387, y: 0.347 },
      { x: 0.357, y: 0.384 },
      { x: 0.315, y: 0.415 },
      { x: 0.283, y: 0.423 },
      { x: 0.24, y: 0.423 },
      { x: 0.192, y: 0.423 },
      { x: 0.168, y: 0.423 },
      { x: 0.131, y: 0.437 },
      { x: 0.099, y: 0.465 },
      { x: 0.096, y: 0.485 },
      { x: 0.072, y: 0.518 },
      { x: 0.059, y: 0.569 },
      { x: 0.064, y: 0.627 },
      { x: 0.085, y: 0.686 },
      { x: 0.115, y: 0.731 },
      { x: 0.165, y: 0.751 },
      { x: 0.203, y: 0.754 },
      { x: 0.248, y: 0.756 },
      { x: 0.299, y: 0.756 },
      { x: 0.344, y: 0.759 },
      { x: 0.397, y: 0.759 },
      { x: 0.427, y: 0.759 },
      { x: 0.451, y: 0.759 },
      { x: 0.477, y: 0.759 },
      { x: 0.517, y: 0.759 },
      { x: 0.539, y: 0.762 },
      { x: 0.557, y: 0.773 },
      { x: 0.568, y: 0.793 },
      { x: 0.579, y: 0.821 },
      { x: 0.587, y: 0.84 },
      { x: 0.589, y: 0.882 },
      { x: 0.589, y: 0.91 },
      { x: 0.589, y: 0.95 },
      { x: 0.589, y: 0.98 },
    ],
  },
  3: {
    rewardPosition: 4,
    paths: [
      { x: 0.587, y: 0.073 },
      { x: 0.589, y: 0.12 },
      { x: 0.589, y: 0.165 },
      { x: 0.587, y: 0.199 },
      { x: 0.587, y: 0.238 },
      { x: 0.587, y: 0.275 },
      { x: 0.587, y: 0.317 },
      { x: 0.589, y: 0.345 },
      { x: 0.611, y: 0.389 },
      { x: 0.627, y: 0.42 },
      { x: 0.664, y: 0.443 },
      { x: 0.693, y: 0.448 },
      { x: 0.723, y: 0.451 },
      { x: 0.752, y: 0.451 },
      { x: 0.797, y: 0.448 },
      { x: 0.837, y: 0.406 },
      { x: 0.859, y: 0.37 },
      { x: 0.861, y: 0.331 },
      { x: 0.861, y: 0.291 },
      { x: 0.861, y: 0.258 },
      { x: 0.84, y: 0.216 },
      { x: 0.797, y: 0.188 },
      { x: 0.76, y: 0.165 },
      { x: 0.733, y: 0.162 },
      { x: 0.683, y: 0.148 },
      { x: 0.613, y: 0.148 },
      { x: 0.581, y: 0.154 },
      { x: 0.555, y: 0.154 },
      { x: 0.509, y: 0.154 },
      { x: 0.459, y: 0.154 },
      { x: 0.413, y: 0.154 },
      { x: 0.336, y: 0.154 },
      { x: 0.267, y: 0.154 },
      { x: 0.237, y: 0.16 },
      { x: 0.211, y: 0.176 },
      { x: 0.176, y: 0.196 },
      { x: 0.157, y: 0.246 },
      { x: 0.155, y: 0.328 },
      { x: 0.144, y: 0.395 },
      { x: 0.147, y: 0.44 },
      { x: 0.155, y: 0.473 },
      { x: 0.168, y: 0.499 },
      { x: 0.197, y: 0.527 },
      { x: 0.203, y: 0.521, isFading: true },
      // { x: 0.24, y: 0.515, isFading: true },
      // { x: 0.269, y: 0.51, isFading: true },
      // { x: 0.288, y: 0.499, isFading: true },
      // { x: 0.309, y: 0.482, isFading: true },
      // { x: 0.341, y: 0.443, isFading: true },
      // { x: 0.349, y: 0.392, isFading: true },
      // { x: 0.357, y: 0.364, isFading: true },
      // { x: 0.357, y: 0.35, isFading: true },
      // { x: 0.357, y: 0.331, isFading: true },
      { x: 0.368, y: 0.3, isFading: true },
      { x: 0.368, y: 0.3 },
      { x: 0.379, y: 0.291 },
      { x: 0.419, y: 0.294 },
      { x: 0.48, y: 0.291 },
      { x: 0.525, y: 0.314 },
      { x: 0.547, y: 0.347 },
      { x: 0.555, y: 0.403 },
      { x: 0.56, y: 0.459 },
      { x: 0.584, y: 0.507 },
      { x: 0.627, y: 0.541 },
      { x: 0.68, y: 0.549 },
      { x: 0.733, y: 0.549 },
      { x: 0.792, y: 0.549 },
      { x: 0.827, y: 0.549 },
      { x: 0.856, y: 0.557 },
      { x: 0.883, y: 0.583 },
      { x: 0.901, y: 0.625 },
      { x: 0.88, y: 0.647, isFading: true },
      // { x: 0.845, y: 0.636, isFading: true },
      // { x: 0.8, y: 0.636, isFading: true },
      // { x: 0.763, y: 0.633, isFading: true },
      // { x: 0.736, y: 0.633, isFading: true },
      // { x: 0.699, y: 0.641, isFading: true },
      // { x: 0.683, y: 0.65, isFading: true },
      // { x: 0.661, y: 0.653, isFading: true },
      // { x: 0.648, y: 0.664, isFading: true },
      { x: 0.645, y: 0.675, isFading: true },
      { x: 0.645, y: 0.675 },
      { x: 0.675, y: 0.678 },
      { x: 0.704, y: 0.675 },
      { x: 0.739, y: 0.692 },
      { x: 0.763, y: 0.725 },
      { x: 0.776, y: 0.762 },
      { x: 0.787, y: 0.801 },
      { x: 0.784, y: 0.843 },
      { x: 0.784, y: 0.871 },
      { x: 0.787, y: 0.899 },
      { x: 0.787, y: 0.93 },
      { x: 0.787, y: 0.96 },
    ],
  },
  4: {
    rewardPosition: 2,
    paths: [
      { x: 0.787, y: 0.07 },
      { x: 0.787, y: 0.115 },
      { x: 0.787, y: 0.168 },
      { x: 0.787, y: 0.218 },
      { x: 0.757, y: 0.272 },
      { x: 0.696, y: 0.291 },
      { x: 0.64, y: 0.291 },
      { x: 0.579, y: 0.291 },
      { x: 0.515, y: 0.297 },
      { x: 0.485, y: 0.322 },
      { x: 0.464, y: 0.342 },
      { x: 0.451, y: 0.384 },
      { x: 0.451, y: 0.44 },
      { x: 0.451, y: 0.49 },
      { x: 0.448, y: 0.535 },
      { x: 0.451, y: 0.569 },
      { x: 0.477, y: 0.616 },
      { x: 0.523, y: 0.65 },
      { x: 0.552, y: 0.658 },
      { x: 0.579, y: 0.661 },
      { x: 0.619, y: 0.661 },
      { x: 0.64, y: 0.664 },
      { x: 0.645, y: 0.669, isFading: true },
      // { x: 0.661, y: 0.664, isFading: true },
      // { x: 0.685, y: 0.655, isFading: true },
      // { x: 0.707, y: 0.644, isFading: true },
      // { x: 0.731, y: 0.644, isFading: true },
      // { x: 0.749, y: 0.644, isFading: true },
      // { x: 0.8, y: 0.633, isFading: true },
      // { x: 0.805, y: 0.639, isFading: true },
      // { x: 0.819, y: 0.639, isFading: true },
      // { x: 0.832, y: 0.639, isFading: true },
      // { x: 0.84, y: 0.639, isFading: true },
      // { x: 0.864, y: 0.639, isFading: true },
      // { x: 0.885, y: 0.641, isFading: true },
      // { x: 0.896, y: 0.658, isFading: true },
      { x: 0.915, y: 0.672, isFading: true },
      { x: 0.915, y: 0.672 },
      { x: 0.912, y: 0.667 },
      { x: 0.909, y: 0.689 },
      { x: 0.909, y: 0.709 },
      { x: 0.909, y: 0.742 },
      { x: 0.909, y: 0.77 },
      { x: 0.909, y: 0.804 },
      { x: 0.901, y: 0.86 },
      { x: 0.859, y: 0.86 },
      { x: 0.837, y: 0.874 },
      { x: 0.797, y: 0.874 },
      { x: 0.763, y: 0.874 },
      { x: 0.701, y: 0.877 },
      { x: 0.667, y: 0.882 },
      { x: 0.611, y: 0.888 },
      { x: 0.568, y: 0.888 },
      { x: 0.515, y: 0.888 },
      { x: 0.469, y: 0.891 },
      { x: 0.435, y: 0.899 },
      { x: 0.43, y: 0.899 },
      { x: 0.42, y: 0.9 },
      { x: 0.41, y: 0.95 },
      { x: 0.4, y: 1 },
    ],
  },
}
