import React from "react";
import styled from "styled-components";

const ButtonStart = styled.button`
  color: white;
  font-size: 1.2em;
  font-weight: 500;
  padding: 0.25em 1em;
  margin: 5px;
  margin-bottom: 0px;
  border-radius: 5px;
  height: 50px;
  width: ${props => (props.Size === "small" ? "45%" : "90%")};
  
  &:hover {
    will-change: opacity;
    opacity: 0.8;
  }
`;

// component button
const ButtonAll = props => {
  const {
    BgColor,
    Value,
    BorderColor,
    OnClickFunction,
    Disabled,
    Size,
    Color
  } = props;
  return (
    <ButtonStart
      onClick={OnClickFunction}
      disabled={!Disabled}
      style={{
        background: !Disabled ? "#e6e6e6" : BgColor,
        color: BgColor !== BorderColor ? BorderColor : Color,
        border: `2px solid ${!Disabled ? "#e6e6e6" : BorderColor}`,
        borderRadius: "2rem"
      }}
      Size={Size}>
      {Value}
    </ButtonStart>
  );
};
ButtonAll.defaultProps = {
  Disabled: true,
  Size: "big",
  BgColor: "rgb(255, 143, 0)",
  Color: "white",
  Value: "start",
  BorderColor: "rgb(255, 143, 0)",
  OnClickFunction: () => {
    console.log("boat");
  }
};
export default ButtonAll;
