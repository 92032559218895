import React from 'react'
import styled from 'styled-components'
// import ProgressiveImage from "react-progressive-image";

const Image = styled.img``

// if image big size convert image in image loading
const ImageLazyLoad = (props) => {
  const { alt, src, width } = props
  return (
    // <ProgressiveImage delay={300} placeholder={alt} src={src}>
    // {(src, loading) => {
    <img style={{ zIndex: 10 }} alt="" width={width} src={src} />
    // }}
    /* </ProgressiveImage> */
  )
}
export default ImageLazyLoad
