import styled from 'styled-components'

const Card = styled.div`
  position: relative;
  background: #ffffff;
  border: 1px solid #edeef3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding-top: 1.5rem;
  :not(:last-child) {
    padding-bottom: 1rem;
  }
`

export const QuotaSection = ({ label = 'label', children }) => {
  return (
    <div className="relative pt-4 mt-4">
      <div className="absolute top-1 left-4 z-50 bg-[#1E86DE] rounded-[40px] py-1 px-3 text-xs text-white">
        {label}
      </div>
      <Card>{children}</Card>
    </div>
  )
}
