import { ConvertDate } from './ConvertDate'

// return data modal
const FuncModalDetail = (props) => {
  let Title,
    Desction,
    BtnModal = ''
  let { ModalDetail, RewardDateNext } = props
  switch (ModalDetail) {
    case 'error':
      Title = 'ไม่สามารถดาวน์โหลดได้'
      Desction = 'กรุณาลองใหม่ภายหลัง'
      BtnModal = 'ตกลง'
      break
    case 'firstTime':
      Title = 'สิทธ์ในการเล่นเกม หมดแล้ว!'
      Desction = 'มาดูวิธี เพิ่มสิทธิ์การเล่นได้ง่ายๆ'
      BtnModal = 'ดูเลย'
      break
    case 'permissionempty':
      Title = 'สิทธิ์เล่นเกมของคุณหมดแล้ว'
      Desction = ''
      BtnModal = 'ดูวิธีรับสิทธิ์เพิ่ม'
      break
    case 'permissionfull':
      Title = 'คุณได้สิทธิ์เล่นเกมครบแล้ว'
      if (RewardDateNext) {
        Desction = `วันที่ ${ConvertDate(RewardDateNext)} มารับสิทธิ์เพิ่มได้อีกนะ`
      } else {
        Desction = 'เดือนหน้ามารับสิทธิ์เพิ่มได้อีกนะ'
      }
      BtnModal = 'ตกลง'
      break
    case 'getadded':
      Title = 'คุณได้สิทธิ์เล่นเกมเพิ่ม!'
      Desction = ''
      BtnModal = 'ไปซิ่งกัน!'
      break

    default:
      Title = 'ไม่สามารถดาวน์โหลดได้'
      Desction = 'กรุณาลองใหม่ภายหลัง'
      BtnModal = 'ตกลง'
      break
  }
  return { Title, Desction, BtnModal }
}
export { FuncModalDetail }
