import { useState, useEffect } from 'react'
import { Drawer } from 'antd'
import styled from 'styled-components'
import { requestLatestTransaction, requestRewardById, requestUnusedCouponCount } from './http'
import { GAME_REWARD_IMAGE } from './image-src'
import { IMAGE_SRC_GAME } from '../game/image-src'
import { COMMON_IMAGE } from '../../common/image-src'
import { useRouter } from '../../common/routing'
import { ModalNoCoupon } from '../../common/components/ModalNoCoupon'
import { useModalController } from '../../common/modal-helper'
import { preloadImages } from '../../common/preload'
import { LoadingWrapper, useLoading } from '../../common/loading'
import { useAppContext } from '../../context'

const Page = styled.div`
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-position: center center;
  position: relative;
  overflow: hidden;
  margin: auto;
  height: 100%;
  width: 100%;
`

const LinkButton = styled.button`
  font-size: 20px;
  font-weight: 500;
  padding: 8px 16px;
  color: #ff8324;
  width: 167.5px;
  height: 54px;
  background: #ffffff;
  border: 2px solid #ff8324;
  border-radius: 32px;
  white-space: nowrap;
`

const LinkButtonPrimary = styled(LinkButton)`
  color: #ffffff;
  background: #ff8324;
`

const Circle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  background: linear-gradient(180deg, #2ea0ff 0%, #0066bb 100%);
  box-shadow: inset 0px 4px 4px rgba(150, 207, 255, 0.6), inset 0px -4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 999px;
`

export const GameRewardPage = () => {
  const appContext = useAppContext()
  const router = useRouter()
  const modalNoCoupon = useModalController()
  const loading = useLoading(3)
  const [isPlayable, setIsPlayable] = useState(false)
  const [reward, setReward] = useState({
    AnalyticName: '',
    CoverImageUrl: '',
    CreatedAt: '',
    GameImageUrl: '',
    ID: 0,
    Name: '',
    Note: '',
    Note2: '',
    Order: 0,
    RefID: '',
    RewardImageURL: '',
    UpdatedAt: '',
  })
  const [transaction, setTransaction] = useState({
    thisRewardAccumulativeCount: 0,
    refId: '',
    rewardId: 0,
  })

  const onClickPlayAgain = async () => {
    if (!isPlayable) {
      modalNoCoupon.open()
      return
    }
    router.replace(router.PATHS.game)
  }

  const fetchUnusedCouponCount = async () => {
    const unusedCount = await requestUnusedCouponCount()
    setIsPlayable(unusedCount > 0)
    loading.doneStep()
  }

  const fetchTransactionAndReward = async () => {
    const latestTrans = await requestLatestTransaction()
    if (!latestTrans) {
      appContext.setStatusToError()
      return
    }
    const targetReward = await requestRewardById(latestTrans.rewardId)
    if (!targetReward) {
      appContext.setStatusToError()
      return
    }
    await preloadImages([targetReward.RewardImageURL])
    setReward(targetReward)
    setTransaction(latestTrans)
    loading.doneStep()
  }

  const loadImage = async () => {
    await preloadImages([COMMON_IMAGE.eventSpecial1])
    loading.doneStep()
  }

  useEffect(() => {
    fetchTransactionAndReward()
    fetchUnusedCouponCount()
    loadImage()
  }, [])

  return (
    <>
      <ModalNoCoupon
        isOpen={modalNoCoupon.isVisible}
        onClick={() => router.replace(router.PATHS.detail)}
        onClickClose={modalNoCoupon.close}
      />
      <div className="flex justify-center h-full bg-[#141414d9]">
        <Page backgroundImage={IMAGE_SRC_GAME[`bg1`]}>
          <div className="flex flex-col-reverse h-full pb-16 items-center">
            <img className="max-w-[375px]" src={IMAGE_SRC_GAME.path1} alt="decor" loading="lazy" />
            <div className="flex justify-center mb-[-1rem] z-10">
              <img className="w-[70px]" src={IMAGE_SRC_GAME.entry} alt="decor" loading="lazy" />
              <img className="w-[70px]" src={IMAGE_SRC_GAME.entry} alt="decor" loading="lazy" />
              <img className="w-[70px]" src={IMAGE_SRC_GAME.entry} alt="decor" loading="lazy" />
              <img className="w-[70px]" src={IMAGE_SRC_GAME.entry} alt="decor" loading="lazy" />
            </div>
          </div>
          {loading.isDone && (
            <>
              <div className="absolute top-0 z-[899]">
                <img src={GAME_REWARD_IMAGE.decor1} alt="decor" loading="lazy" />
              </div>
              <div className="absolute top-0 z-[900] flex flex-col items-center w-full text-white pt-4">
                <div className="mb-4 text-xl">ลุ้นรับรางวัลแล้ว</div>
                <Circle>
                  <div className="text-[48px] font-bold leading-[50px]">
                    {transaction.thisRewardAccumulativeCount}
                  </div>
                  <div className="font-medium">สิทธิ์</div>
                </Circle>
              </div>
            </>
          )}
          <Drawer
            open
            closable={false}
            getContainer={false}
            placement="bottom"
            rootClassName="app-drawer"
            zIndex={800}
            maskStyle={{
              backgroundColor: '#000000D9',
            }}
          >
            <LoadingWrapper isLoading={loading.isLoading}>
              <div className="absolute bottom-[335px] left-0 flex justify-center w-full px-8 max-h-[200px]">
                <img className="object-contain" src={reward.RewardImageURL} alt="reward" />
              </div>
              <div className="flex flex-col items-center mt-[1.5rem] mb-6">
                <div className="text-[#BBBFD1] mb-2 text-sm">Ref. {transaction.refId}</div>
                <div className="text-2xl font-bold mb-2 whitespace-nowrap truncate w-full text-center">
                  {reward.Name}
                </div>
                <div className="text-base text-[#858A94]">{reward.Note}</div>
                <div className="text-base text-[#858A94]">{reward.Note2}</div>
                <img
                  className="mt-5 max-w-[375px] w-full"
                  src={COMMON_IMAGE.eventSpecial2}
                  alt="event"
                />
              </div>
              <div className="flex justify-center gap-2">
                <LinkButton onClick={() => router.replace(router.PATHS.home)}>
                  กลับหน้าหลัก
                </LinkButton>
                <LinkButtonPrimary onClick={onClickPlayAgain}>เล่นอีกครั้ง</LinkButtonPrimary>
              </div>
            </LoadingWrapper>
          </Drawer>
        </Page>
      </div>
    </>
  )
}
