import { httpClient } from '../../common/http'

export const sendRequestAnalyticEnterPageDetail = async () => {
  try {
    await httpClient.post('api/analytics/enterdetail')
    return true
  } catch {
    return false
  }
}
