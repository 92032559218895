import { useCallback, useMemo, useState } from 'react'

export const useModalController = () => {
  const [isVisible, setIsVisible] = useState(false)
  const close = useCallback(() => setIsVisible(false), [])
  const open = useCallback(() => setIsVisible(true), [])
  return useMemo(
    () => ({
      isVisible,
      open,
      close,
    }),
    [isVisible, open, close]
  )
}
