export const DATA_MAP_4 = {
  1: {
    rewardPosition: 1,
    paths: [
      { x: 0.205, y: 0.064 },
      { x: 0.203, y: 0.109 },
      { x: 0.208, y: 0.168 },
      { x: 0.208, y: 0.221 },
      { x: 0.213, y: 0.255 },
      { x: 0.251, y: 0.289 },
      { x: 0.301, y: 0.289 },
      { x: 0.352, y: 0.286 },
      { x: 0.413, y: 0.294 },
      { x: 0.456, y: 0.294 },
      { x: 0.501, y: 0.3 },
      { x: 0.517, y: 0.319 },
      { x: 0.52, y: 0.353 },
      { x: 0.525, y: 0.395 },
      { x: 0.531, y: 0.437 },
      { x: 0.533, y: 0.493 },
      { x: 0.547, y: 0.513 },
      { x: 0.581, y: 0.532 },
      { x: 0.597, y: 0.538 },
      { x: 0.624, y: 0.543 },
      { x: 0.645, y: 0.543, isFading: true },
      { x: 0.219, y: 0.532, isFading: true },
      { x: 0.219, y: 0.532 },
      { x: 0.267, y: 0.541 },
      { x: 0.309, y: 0.546 },
      { x: 0.347, y: 0.569 },
      { x: 0.373, y: 0.608 },
      { x: 0.373, y: 0.664 },
      { x: 0.373, y: 0.686 },
      { x: 0.376, y: 0.728 },
      { x: 0.373, y: 0.779 },
      { x: 0.352, y: 0.84 },
      { x: 0.299, y: 0.849 },
      { x: 0.248, y: 0.863 },
      { x: 0.219, y: 0.891 },
      { x: 0.219, y: 0.95 },
    ],
  },
  2: {
    rewardPosition: 3,
    paths: [
      { x: 0.403, y: 0.073 },
      { x: 0.403, y: 0.118 },
      { x: 0.403, y: 0.176 },
      { x: 0.403, y: 0.235 },
      { x: 0.403, y: 0.275 },
      { x: 0.4, y: 0.319 },
      { x: 0.389, y: 0.375 },
      { x: 0.357, y: 0.395 },
      { x: 0.312, y: 0.406 },
      { x: 0.259, y: 0.412 },
      { x: 0.203, y: 0.417 },
      { x: 0.155, y: 0.423 },
      { x: 0.117, y: 0.459 },
      { x: 0.093, y: 0.499 },
      { x: 0.083, y: 0.529 },
      { x: 0.075, y: 0.58 },
      { x: 0.075, y: 0.622 },
      { x: 0.099, y: 0.675 },
      { x: 0.112, y: 0.711 },
      { x: 0.152, y: 0.745 },
      { x: 0.192, y: 0.759 },
      { x: 0.259, y: 0.759 },
      { x: 0.291, y: 0.759 },
      { x: 0.352, y: 0.756 },
      { x: 0.405, y: 0.756 },
      { x: 0.453, y: 0.756 },
      { x: 0.504, y: 0.756 },
      { x: 0.552, y: 0.77 },
      { x: 0.581, y: 0.793 },
      { x: 0.6, y: 0.826 },
      { x: 0.6, y: 0.868 },
      { x: 0.6, y: 0.91 },
      { x: 0.6, y: 0.95 },
    ],
  },
  3: {
    rewardPosition: 2,
    paths: [
      { x: 0.6, y: 0.067 },
      { x: 0.6, y: 0.109 },
      { x: 0.6, y: 0.154 },
      { x: 0.6, y: 0.19 },
      { x: 0.6, y: 0.249 },
      { x: 0.605, y: 0.325 },
      { x: 0.619, y: 0.381 },
      { x: 0.64, y: 0.415 },
      { x: 0.68, y: 0.44 },
      { x: 0.731, y: 0.454 },
      { x: 0.771, y: 0.454 },
      { x: 0.797, y: 0.44 },
      { x: 0.84, y: 0.415, isFading: true },
      { x: 0.797, y: 0.171, isFading: true },
      { x: 0.797, y: 0.171 },
      { x: 0.792, y: 0.227 },
      { x: 0.765, y: 0.269 },
      { x: 0.72, y: 0.289 },
      { x: 0.643, y: 0.3 },
      { x: 0.592, y: 0.3 },
      { x: 0.541, y: 0.3 },
      { x: 0.485, y: 0.3 },
      { x: 0.44, y: 0.319 },
      { x: 0.416, y: 0.373 },
      { x: 0.413, y: 0.42 },
      { x: 0.413, y: 0.457 },
      { x: 0.413, y: 0.515 },
      { x: 0.416, y: 0.56 },
      { x: 0.424, y: 0.599 },
      { x: 0.451, y: 0.641 },
      { x: 0.496, y: 0.658 },
      { x: 0.547, y: 0.678 },
      { x: 0.592, y: 0.675 },
      { x: 0.629, y: 0.672 },
      { x: 0.651, y: 0.672, isFading: true },
      { x: 0.888, y: 0.571, isFading: true },
      { x: 0.888, y: 0.571 },
      { x: 0.899, y: 0.58 },
      { x: 0.917, y: 0.616 },
      { x: 0.923, y: 0.658 },
      { x: 0.925, y: 0.711 },
      { x: 0.925, y: 0.779 },
      { x: 0.923, y: 0.835 },
      { x: 0.896, y: 0.871 },
      { x: 0.869, y: 0.877 },
      { x: 0.829, y: 0.882 },
      { x: 0.768, y: 0.882 },
      { x: 0.731, y: 0.882 },
      { x: 0.677, y: 0.882 },
      { x: 0.648, y: 0.882 },
      { x: 0.597, y: 0.88 },
      { x: 0.549, y: 0.88 },
      { x: 0.509, y: 0.882 },
      { x: 0.464, y: 0.888 },
      { x: 0.437, y: 0.899 },
      { x: 0.427, y: 0.95 },
    ],
  },
  4: {
    rewardPosition: 4,
    paths: [
      { x: 0.797, y: 0.064 },
      { x: 0.797, y: 0.098 },
      { x: 0.797, y: 0.14 },
      { x: 0.797, y: 0.174, isFading: true },
      { x: 0.856, y: 0.401, isFading: true },
      { x: 0.856, y: 0.401 },
      { x: 0.872, y: 0.359 },
      { x: 0.877, y: 0.328 },
      { x: 0.877, y: 0.283 },
      { x: 0.853, y: 0.235 },
      { x: 0.829, y: 0.199 },
      { x: 0.789, y: 0.168 },
      { x: 0.712, y: 0.157 },
      { x: 0.656, y: 0.157 },
      { x: 0.616, y: 0.157 },
      { x: 0.563, y: 0.148 },
      { x: 0.52, y: 0.148 },
      { x: 0.475, y: 0.154 },
      { x: 0.416, y: 0.154 },
      { x: 0.384, y: 0.154 },
      { x: 0.349, y: 0.154 },
      { x: 0.267, y: 0.154 },
      { x: 0.203, y: 0.179 },
      { x: 0.168, y: 0.23 },
      { x: 0.163, y: 0.277 },
      { x: 0.155, y: 0.333 },
      { x: 0.157, y: 0.342 },
      { x: 0.16, y: 0.381 },
      { x: 0.163, y: 0.415 },
      { x: 0.163, y: 0.454 },
      { x: 0.184, y: 0.487 },
      { x: 0.219, y: 0.527, isFading: true },
      { x: 0.653, y: 0.538, isFading: true },
      { x: 0.653, y: 0.538 },
      { x: 0.699, y: 0.555 },
      { x: 0.725, y: 0.549 },
      { x: 0.757, y: 0.549 },
      { x: 0.8, y: 0.546 },
      { x: 0.843, y: 0.549 },
      { x: 0.877, y: 0.563 },
      { x: 0.901, y: 0.571, isFading: true },
      { x: 0.656, y: 0.669, isFading: true },
      { x: 0.656, y: 0.669 },
      { x: 0.693, y: 0.669 },
      { x: 0.736, y: 0.689 },
      { x: 0.768, y: 0.72 },
      { x: 0.792, y: 0.756 },
      { x: 0.797, y: 0.798 },
      { x: 0.797, y: 0.843 },
      { x: 0.8, y: 0.888 },
      { x: 0.8, y: 0.95 },
    ],
  },
}
