import * as moment from "moment";
let arrmonth = [
  "ม.ค.",
  "ก.พ.",
  "มี.ค.",
  "เม.ย.",
  "พ.ค.",
  "มิ.ย.",
  "ก.ค.",
  "ส.ค.",
  "ก.ย.",
  "ต.ค.",
  "พ.ย.",
  "ธ.ค."
];

// function convert date format to TH
const ConvertDate = date => {
  if (!date) return null;
  let mdate = moment(date)
    .add("543", "year")
    .format("D M YY");

  let ReturnDate = mdate.split(" ").map((item, i) => {
    if (i === 1) {
      return arrmonth[item - 1];
    }
    return item;
  });
  return ReturnDate.join(" ");
};

export { ConvertDate };
