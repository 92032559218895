import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useAppContext } from '../../context'
import { httpClient } from '../../common/http'
import { useLoading } from '../../common/loading'
import { preloadImages } from '../../common/preload'
import { useRouter } from '../../common/routing'
import { HOME_IMGS } from './image'
import scrollReward from '../../assets/home/scrollReward.png'
import titleRewards from '../../assets/home/titleRewards.png'
import titleImage from '../../assets/home/titleImage.png'
import titleBigRewardImage from '../../assets/home/titleBigRewardImage.png'
import Home from './Home'
import Modal from '../../common/ModalStart'
import { ModalNoCouponLanding } from '../../common/components/ModalNoCoupon'
import { useModalController } from '../../common/modal-helper'
import { Winner } from '../../common/components/winner'

const Container = styled.div`
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  background-image: url(${HOME_IMGS.bg});
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
`

let isCouponEmptyChecked = false

const PageHome = () => {
  const appContext = useAppContext()
  const modalNoCouponLanding = useModalController()
  const router = useRouter()
  const loading = useLoading(6)
  const [modalvisible, setModalvisible] = useState(true)
  const [pageError, setPageError] = useState(true)
  const [data, setData] = useState('')
  const [token, setToken] = useState('')
  const [ModalType, setModalType] = useState('')
  const [ModalDetail, setModalDetail] = useState('')
  const [roundEndDate, setRoundEndDate] = useState('')
  const [unusedCouponCount, setUnusedCouponCount] = useState(0)
  const [listRewards, setListRewards] = useState([])
  const [listRewardStats, setListRewardStats] = useState([])

  const loadImages = async () => {
    await preloadImages([
      scrollReward,
      titleRewards,
      titleImage,
      titleBigRewardImage,
      HOME_IMGS.bg,
      HOME_IMGS.logo,
      HOME_IMGS.btnPlay,
      HOME_IMGS.btnHistory,
      HOME_IMGS.btnDetail,
    ])
    loading.doneStep()
  }

  const fetchListRewards = async () => {
    try {
      const res = await httpClient.get('/api/coupons/rewards?status=active')
      const rewards = res.data.value || []
      const images = rewards.map((reward) => reward.CoverImageUrl)
      await preloadImages(images)
      setListRewards(res?.data?.value || [])
    } catch (error) {}
    loading.doneStep()
  }
  const fetchListRewardStats = async () => {
    try {
      const res = await httpClient.get('/api/coupons/transactions/rewardstats')
      setListRewardStats(res?.data?.value)
    } catch (error) {}
    loading.doneStep()
  }

  const fetchActiveRound = async () => {
    try {
      const res = await httpClient.get('/api/rounds/active')
      const roundData = res?.data
      setRoundEndDate(roundData?.endedAt)
    } catch (error) {}
    loading.doneStep()
  }

  const fetchUnusedCouponCount = async () => {
    try {
      const res = await httpClient.get('api/coupons/unusedcount')
      const couponCountData = res.data
      // if (couponCountData?.count === 0 && Cookies.get('firstTime') === 'true') {
      //   //modal <<<<
      //   let typemodalstart = 'firstTime'
      //   setModalvisible(false)
      //   setModalType(typemodalstart)
      //   setModalDetail(typemodalstart)
      //   Cookies.set('firstTime', 'false')
      // }
      if (couponCountData?.count === 0 && !window.firstTime && !isCouponEmptyChecked) {
        // let typemodalstart = 'firstTime'
        // setModalvisible(false)
        // setModalType(typemodalstart)
        // setModalDetail(typemodalstart)
        modalNoCouponLanding.open()
        window.firstTime = true
      }
      const unusedCouponCount = couponCountData.count || 0
      setUnusedCouponCount(unusedCouponCount)
    } catch (error) {}
    isCouponEmptyChecked = true
    loading.doneStep()
  }

  const fetchData = async () => {
    let typemodalstart = ''
    try {
      const res = await httpClient.get('api/notifications')
      if (res.data.eventCouponReceived) {
        typemodalstart = 'getadded'
      }
      if (res.data.eventCouponReceivedFull) {
        typemodalstart = 'permissionfull'
      }
    } catch (error) {
      typemodalstart = 'error'
      setPageError(true)
      setModalvisible(false)
      setModalType('error')
      setModalDetail('error')
    }
    setPageError(true)
    setToken(token)
    setModalvisible(typemodalstart !== '' ? false : true)
    setModalType(
      typemodalstart === 'permissionfull' || typemodalstart === 'error' ? 'error' : 'success'
    )
    setModalDetail(typemodalstart)
    loading.doneStep()
  }

  const closemodal = async () => {
    setModalvisible(true)
    try {
      await httpClient.patch('api/notifications', {
        eventRefId: 'coupon_received',
      })
      await httpClient.patch('api/notifications', {
        eventRefId: 'coupon_received_full',
      })
    } catch (error) {
      console.error(error)
      appContext.setStatusToError()
    }
  }

  useEffect(() => {
    const token = router.params.get('token')
    if (!token) {
      appContext.setStatusToError()
      return
    }
    fetchData()
    fetchActiveRound()
    fetchUnusedCouponCount()
    fetchListRewards()
    fetchListRewardStats()
    loadImages()
  }, [])

  if (loading.isLoadingWithDelay) {
    return (
      <Container>
        <div className="flex flex-col w-full h-full items-center">
          <img className="w-[220px] mt-[50px]" src={HOME_IMGS.logo} />
          <Winner isShowLogo={false} />
          <div className="mt-[0px] w-[80%] h-6 mb-4 rounded-full border-4 border-white	shadow bg-[#E9EAEF]">
            <div
              className="h-4 bg-gradient-to-r from-red-600 to-yellow-300 rounded-full transition-all"
              style={{ width: `${loading.percent}%` }}
            />
          </div>
          <span className="text-sm font-medium tracking-[0.04rem] text-[#2C2C2C]">Loading...</span>
        </div>
      </Container>
    )
  }

  return (
    <>
      <Modal
        ModalType={ModalType}
        ModalDetail={ModalDetail}
        Visible={modalvisible}
        RewardDateNext={data?.RewardDateNext}
        OnClose={closemodal}
      />
      <ModalNoCouponLanding
        isOpen={modalNoCouponLanding.isVisible}
        onClickClose={modalNoCouponLanding.close}
        onClick={() => router.push(router.paths.detail)}
      />
      <Home
        pageError={pageError}
        token={token}
        data={data}
        roundEndDate={roundEndDate}
        unusedCouponCount={unusedCouponCount}
        listRewards={listRewards}
        listRewardStats={listRewardStats}
      />
    </>
  )
}

export default PageHome
