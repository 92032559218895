export const DATA_MAP_3 = {
  1: {
    rewardPosition: 2,
    paths: [
      { x: 0.203, y: 0.073 },
      { x: 0.203, y: 0.134 },
      { x: 0.203, y: 0.188 },
      { x: 0.205, y: 0.23 },
      { x: 0.221, y: 0.255 },
      { x: 0.253, y: 0.277 },
      { x: 0.301, y: 0.294 },
      { x: 0.331, y: 0.294 },
      { x: 0.355, y: 0.294, isFading: true },
      { x: 0.181, y: 0.426, isFading: true },
      { x: 0.147, y: 0.398 },
      { x: 0.131, y: 0.345 },
      { x: 0.128, y: 0.275 },
      { x: 0.147, y: 0.227 },
      { x: 0.168, y: 0.185 },
      { x: 0.227, y: 0.174 },
      { x: 0.288, y: 0.157 },
      { x: 0.325, y: 0.157 },
      { x: 0.389, y: 0.157 },
      { x: 0.472, y: 0.157 },
      { x: 0.531, y: 0.157 },
      { x: 0.595, y: 0.157 },
      { x: 0.661, y: 0.157 },
      { x: 0.715, y: 0.157 },
      { x: 0.76, y: 0.168 },
      { x: 0.8, y: 0.193 },
      { x: 0.821, y: 0.235 },
      { x: 0.832, y: 0.28 },
      { x: 0.832, y: 0.317 },
      { x: 0.837, y: 0.345 },
      { x: 0.837, y: 0.401 },
      { x: 0.837, y: 0.443 },
      { x: 0.819, y: 0.49 },
      { x: 0.789, y: 0.521 },
      { x: 0.76, y: 0.529 },
      { x: 0.72, y: 0.535 },
      { x: 0.677, y: 0.541 },
      { x: 0.664, y: 0.549, isFading: true },
      { x: 0.859, y: 0.725, isFading: true },
      { x: 0.827, y: 0.748 },
      { x: 0.779, y: 0.751 },
      { x: 0.736, y: 0.751 },
      { x: 0.688, y: 0.751 },
      { x: 0.637, y: 0.751 },
      { x: 0.589, y: 0.756 },
      { x: 0.536, y: 0.759 },
      { x: 0.493, y: 0.762 },
      { x: 0.456, y: 0.768 },
      { x: 0.419, y: 0.796 },
      { x: 0.413, y: 0.821 },
      { x: 0.4, y: 0.866 },
      { x: 0.4, y: 0.899 },
      { x: 0.4, y: 0.95 },
    ],
  },
  2: {
    rewardPosition: 1,
    paths: [
      { x: 0.397, y: 0.067 },
      { x: 0.397, y: 0.115 },
      { x: 0.397, y: 0.165 },
      { x: 0.397, y: 0.221 },
      { x: 0.397, y: 0.277 },
      { x: 0.395, y: 0.333 },
      { x: 0.379, y: 0.389 },
      { x: 0.341, y: 0.429 },
      { x: 0.304, y: 0.443 },
      { x: 0.253, y: 0.451 },
      { x: 0.216, y: 0.451 },
      { x: 0.184, y: 0.423, isFading: true },
      { x: 0.36, y: 0.294, isFading: true },
      { x: 0.403, y: 0.297 },
      { x: 0.435, y: 0.297 },
      { x: 0.485, y: 0.3 },
      { x: 0.525, y: 0.317 },
      { x: 0.541, y: 0.35 },
      { x: 0.557, y: 0.398 },
      { x: 0.557, y: 0.437 },
      { x: 0.56, y: 0.49 },
      { x: 0.555, y: 0.557 },
      { x: 0.544, y: 0.602 },
      { x: 0.515, y: 0.636 },
      { x: 0.475, y: 0.655 },
      { x: 0.419, y: 0.661 },
      { x: 0.371, y: 0.661 },
      { x: 0.341, y: 0.661 },
      { x: 0.291, y: 0.669 },
      { x: 0.259, y: 0.683 },
      { x: 0.227, y: 0.717 },
      { x: 0.211, y: 0.754 },
      { x: 0.208, y: 0.801 },
      { x: 0.203, y: 0.846 },
      { x: 0.203, y: 0.888 },
      { x: 0.203, y: 0.95 },
    ],
  },
  3: {
    rewardPosition: 4,
    paths: [
      { x: 0.595, y: 0.067 },
      { x: 0.595, y: 0.112 },
      { x: 0.6, y: 0.154 },
      { x: 0.597, y: 0.202 },
      { x: 0.595, y: 0.249 },
      { x: 0.595, y: 0.3 },
      { x: 0.605, y: 0.353 },
      { x: 0.632, y: 0.387 },
      { x: 0.667, y: 0.401 },
      { x: 0.725, y: 0.409 },
      { x: 0.787, y: 0.42 },
      { x: 0.827, y: 0.42 },
      { x: 0.867, y: 0.437 },
      { x: 0.893, y: 0.468 },
      { x: 0.917, y: 0.515 },
      { x: 0.931, y: 0.58 },
      { x: 0.923, y: 0.627 },
      { x: 0.901, y: 0.678 },
      { x: 0.877, y: 0.714, isFading: true },
      { x: 0.661, y: 0.55, isFading: true },
      { x: 0.632, y: 0.591 },
      { x: 0.621, y: 0.63 },
      { x: 0.619, y: 0.686 },
      { x: 0.619, y: 0.739 },
      { x: 0.627, y: 0.801 },
      { x: 0.645, y: 0.835 },
      { x: 0.707, y: 0.86 },
      { x: 0.752, y: 0.868 },
      { x: 0.779, y: 0.888 },
      { x: 0.779, y: 0.95 },
    ],
  },
  4: {
    rewardPosition: 3,
    paths: [
      { x: 0.792, y: 0.073 },
      { x: 0.797, y: 0.143 },
      { x: 0.787, y: 0.202 },
      { x: 0.779, y: 0.241 },
      { x: 0.739, y: 0.263 },
      { x: 0.688, y: 0.272 },
      { x: 0.621, y: 0.269 },
      { x: 0.573, y: 0.269 },
      { x: 0.523, y: 0.272 },
      { x: 0.477, y: 0.305 },
      { x: 0.467, y: 0.35 },
      { x: 0.464, y: 0.401 },
      { x: 0.456, y: 0.457 },
      { x: 0.448, y: 0.504 },
      { x: 0.413, y: 0.549 },
      { x: 0.344, y: 0.552 },
      { x: 0.291, y: 0.555 },
      { x: 0.245, y: 0.555 },
      { x: 0.205, y: 0.555 },
      { x: 0.165, y: 0.555 },
      { x: 0.128, y: 0.566 },
      { x: 0.091, y: 0.602 },
      { x: 0.075, y: 0.655 },
      { x: 0.075, y: 0.714 },
      { x: 0.075, y: 0.768 },
      { x: 0.075, y: 0.821 },
      { x: 0.109, y: 0.871 },
      { x: 0.149, y: 0.888 },
      { x: 0.195, y: 0.888 },
      { x: 0.251, y: 0.888 },
      { x: 0.288, y: 0.888 },
      { x: 0.328, y: 0.885 },
      { x: 0.371, y: 0.88 },
      { x: 0.432, y: 0.88 },
      { x: 0.469, y: 0.882 },
      { x: 0.525, y: 0.888 },
      { x: 0.563, y: 0.905 },
      { x: 0.565, y: 0.95 },
    ],
  },
}
