export const TermCondition = () => {
  const dataTable = (title, body, maxClaim) => {
    return (
      <>
        <td className="border border-black">
          <div className=" font-medium	p-[0.2rem]">{title}</div>
          <div className=" p-[0.2rem]">{body}</div>
        </td>
        <td className="border border-black p-[0.5rem]">สูงสุด {maxClaim} สิทธิ์</td>
      </>
    )
  }
  return (
    <div style={{ wordBreak: 'break-word' }} className="">
      <div className="flex justify-center font-medium ">ข้อกำหนดและเงื่อนไข</div>
      <div className="my-1  font-medium ">รายการส่งเสริมการขาย</div>
      <div className=" mb-[1.5rem]">เปย์ง่ายได้รถ</div>
      <div className=" mb-1 font-medium">ระยะเวลาของรายการส่งเสริมการขาย</div>
      <div className="mb-[2rem]">ตั้งแต่วันที่ 1 ธันวาคม 2566 ถึงวันที่ 31 มกราคม 2567</div>
      <div className=" mb-1 font-medium">วิธีการร่วมรายการส่งเสริมการขาย</div>
      <div className="font-medium">
        {' '}
        <span className="underline">ต่อที่ 1:</span> ทำรายการตามที่กำหนด
      </div>
      <div className="mb-1 font-medium">เพื่อรับสิทธิ์เล่นเกมและเข้าร่วมชิงโชคลุ้นรับของรางวัล</div>
      <div>1. ผู้เข้าร่วมรายการส่งเสริมการขายจะต้องมีบัญชีทรูมันนี่ ของตนเอง</div>
      <div className="mt-[0.5rem]">
        2. ผู้เข้าร่วมรายการส่งเสริมการขายจะต้องเข้าไปที่แอปพลิเคชันทรูมันนี่ แล้วเลือกไอคอน
        หรือแบนเนอร์ (Banner) ของรายการส่งเสริมการขาย “เปย์ง่ายได้รถ 2023”
        เพื่อเข้าสู่หน้าหลักของรายการส่งเสริมการขายก่อนเป็นลำดับแรก จากนั้น
        ผู้เข้าร่วมรายการส่งเสริมการขายจะต้อง
        ทำรายการประเภทใดประเภทหนึ่งตามที่กำหนดในตารางด้านล่างดังต่อไปนี้
        ผ่านช่องทางแอปพลิเคชันทรูมันนี่ โดยมีมูลค่าการทำรายการตั้งแต่ 20 (ยี่สิบ) บาท เป็นต้นไปต่อ 1
        (หนึ่ง) รายการ ผู้เข้าร่วมรายการส่งเสริมการขายจะได้รับ 1 (หนึ่ง) สิทธิ์
        เพื่อเข้าร่วมกิจกรรมเล่นเกมและลุ้นรับรางวัลตามเงื่อนไขของรายการส่งเสริมการขาย ทั้งนี้
        บริษัทจำกัดจำนวนสิทธิ์ที่ผู้เข้าร่วมรายการส่งเสริมการขายจะได้รับ สูงสุด 58 (ห้าสิบแปด)
        สิทธิ์ ต่อเดือน โดยแบ่งออกเป็นจำนวนสิทธิ์จำกัดสูงสุดต่อเดือน (ต่อรอบการจับรางวัล 1 รอบ)
        สำหรับการทำรายการผ่านแอปพลิเคชัน ทรูมันนี่ แต่ละประเภทดังต่อไปนี้:
      </div>
      {/* <div className="flex "> */}
      <table class="table-auto defaultScreen:table-auto border-collapse  border border-black my-[0.5rem]">
        <thead>
          <tr>
            <th className="border border-black ">ประเภทรายการ</th>
            <th className="border border-black w-[100px]">จำนวนสิทธิ์ที่ได้รับ / เดือน</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {dataTable(
              'ใช้จ่ายที่ 7-Eleven:',
              ' ใช้จ่ายที่ 7-Eleven, 7-Delivery หรือ ตู้สินค้าอัตโนมัติ 7-Eleven หมายเหตุ:ไม่รวมการจ่ายผ่านแอป 7-Eleven และ ไม่รวมถึงสินค้า เหล้า เบียร์ บุหรี่ ไพ่บัตรเติมเงินทุกชนิด บัตรเงินสดทุกชนิด สลิปเติมเงิน 7 Top-up การเติมเงินออนไลน์การเติมเงินเข้าบัญชีทรูมันนี่ การเติมเงินเข้าบัตร 7-Card การจองของพรีเมี่ยมการชำระบิลค่าสินค้าและบริการผ่านเคาน์เตอร์เซอร์วิส และการชำระค่าสินค้าใน 7 catalog',
              3
            )}
          </tr>
          <tr>
            {dataTable(
              'ใช้จ่ายที่ Lotus’s:',
              'ใช้จ่ายที่ Lotus’s หรือ ตู้สินค้าอัตโนมัติ Lotus’s',
              3
            )}
          </tr>
          <tr>
            {dataTable(
              'ใช้จ่ายที่ร้านค้าชั้นนำ: ',
              'ใช้จ่ายที่ร้านค้าชั้นนำ อาทิเช่น Dairy Queen, CP Fresh Mart, Fuji, B2S เป็นต้น หรือที่ตู้สินค้าอัตโนมัติของร้านค้า ซึ่งมีบริการตู้สินค้าอัตโนมัติสามารถตรวจสอบรายละเอียดร้านค้าชั้นนำเพิ่มเติม ได้ที่ https://www.truemoney.com/retail-store/',
              3
            )}
          </tr>
          <tr>
            {dataTable(
              'ใช้จ่ายที่ร้านค้า True Point & Pay: ',
              'สามารถตรวจสอบรายละเอียดร้านค้า True Point & Pay เพิ่มเติม ได้ที่ https://www.truemoney.com/true-pointandpay/ ',
              3
            )}
          </tr>
          <tr>
            {dataTable(
              'ใช้จ่ายต่างประเทศ ผ่าน Alipay+: ',
              'สามารถตรวจสอบรายละเอียดร้านค้าและบริการที่เข้าร่วม ได้ที่ https://www.truemoney.com/global-network-offline-payment/',
              3
            )}
          </tr>
          <tr>
            {dataTable(
              'ใช้จ่ายออนไลน์ เติมเกม หรือ ซื้อบัตรเงินสด จากร้านค้าที่กำหนด:',
              'อาทิเช่น Lazada, Foodpanda, MRT เป็นต้น สามารถตรวจสอบรายละเอียดร้านค้าและบริการที่เข้าร่วม ได้ที่ https://www.truemoney.com/onlineshopping/',
              3
            )}
          </tr>
          <tr>{dataTable('ใช้จ่ายบน TikTok Shop (ร้านค้าใดก็ได้)  ', '', 3)}</tr>
          <tr>
            {dataTable(
              'จ่ายบิล',
              'จ่ายบิลผ่านแอปพลิเคชันทรูมันนี่ โดยทำรายการผ่านเมนู “ชำระบิลทรูของฉัน” หรือ “ชำระบิลอื่นๆ”',
              3
            )}
          </tr>
          <tr>{dataTable('บริจาค', '', 3)}</tr>
          <tr>
            {dataTable(
              'ซื้อแพ็กเกจอินเทอร์เน็ต (แพ็กเกจใดก็ได้) หรือเติมเงินในเครือข่าย ทรูมูฟ เอช',
              '',
              3
            )}
          </tr>
          <tr>
            {dataTable('ซื้อแอปพลิเคชัน หรือเติมเงินผ่าน Google Play หรือ Apple App Store', '', 3)}
          </tr>
          <tr>
            {dataTable(
              'ซื้อคูปองส่วนลดจากร้านค้าชั้นนำบน Alipay+ Rewards',
              'สามารถตรวจสอบรายละเอียดร้านค้าและบริการที่เข้าร่วม ได้ที่ https://tmn.app.link/ESHOP',
              3
            )}
          </tr>
          <tr>
            <td className="border border-black p-[0.2rem]">
              <span style={{ wordBreak: 'break-all' }} className="flex  font-medium	">
                จ่ายค่าบริการ ประเภทดูหนัง ฟังเพลง:
              </span>
              <span style={{ wordBreak: 'break-all' }} className="flex mt-[0.3rem] ">
                Netflix, Spotify, Viu, WeTV, IQIYI, YouTube Premium และ Amazon Prime
              </span>
              <span className="flex mt-[0.2rem]">สามารถตรวจสอบรายละเอียดได้ที่</span>
              <span style={{ wordBreak: 'break-all' }} className="flex">
                Netflix: https://www.truemoney.com/netflix-content/
              </span>
              <span style={{ wordBreak: 'break-all' }} className="flex">
                Spotify: https://www.truemoney.com/spotify/
              </span>
              <span style={{ wordBreak: 'break-all' }} className="flex">
                Viu: https://www.truemoney.com/viu/
              </span>
              <span style={{ wordBreak: 'break-all' }} className="flex">
                WeTV: https://www.truemoney.com/wetv/
              </span>
              <span style={{ wordBreak: 'break-all' }} className="flex">
                IQIYI: https://www.truemoney.com/iqiyi/
              </span>
              <span style={{ wordBreak: 'break-all' }} className="flex">
                YouTube Premium: https://www.truemoney.com/youtubepremium/{' '}
              </span>
              <span style={{ wordBreak: 'break-all' }} className="flex">
                Amazon Prime: https://www.truemoney.com/prime/{' '}
              </span>

              {/* {dataTable(
                  'จ่ายค่าบริการ ประเภทดูหนัง ฟังเพลง:',
                  'Netflix, Spotify, Viu, WeTV, IQIYI, YouTube Premium และ Amazon Prime สามารถตรวจสอบรายละเอียดได้ที่ Netflix: https://www.truemoney.com/netflix-content/ Spotify: https://www.truemoney.com/spotify/ Viu: https://www.truemoney.com/viu/ WeTV: https://www.truemoney.com/wetv/ IQIYI: https://www.truemoney.com/iqiyi/ YouTube Premium: https://www.truemoney.com/youtubepremium/                   Amazon Prime: https://www.truemoney.com/prime/ '
                )} */}
            </td>
            <td className="border border-black p-[0.5rem]">สูงสุด 3 สิทธิ์</td>
          </tr>
          <tr>
            {dataTable(
              'ใช้จ่ายด้วยบัตร TrueMoney Mastercard หรือ True Mastercard แบบเติมเงิน',
              '',
              3
            )}
          </tr>
          <tr>{dataTable('ใช้จ่ายผ่านบัญชีเงินฝากออมทรัพย์ (My Saving)', '', 3)}</tr>
          <tr>{dataTable('ใช้จ่ายด้วยบัญชีสินเชื่อ เพย์ เน็กซ์ ', '', 3)}</tr>
          <tr>{dataTable('ใช้จ่ายด้วยบัญชีสินเชื่อ เพย์ เน็กซ์ เอ็กซ์ตร้า', '', 3)}</tr>
          <tr>{dataTable('โอนเงิน หรือสร้างซองของขวัญ ', '', 3)}</tr>
          <tr>{dataTable('ออมเงิน เข้าบัญชีเงินฝากออมทรัพย์ (My Saving)', '', 3)}</tr>
          <tr>{dataTable('เบิกถอนเงินสด (KKP Cash Now)', '', 1)}</tr>
        </tbody>
      </table>
      {/* </div> */}
      <div className="mb-[0.4rem]">
        ทั้งนี้ ผู้เข้าร่วมรายการส่งเสริมการขายจะได้รับสิทธิ์เล่นเกมและชิงโชคลุ้นรับรางวัลฟรี จำนวน
        1 (หนึ่ง) สิทธิ์ โดยไม่ต้องทำรายการตามที่กำหนดในตารางข้างต้น (จำกัดสิทธิ์ฟรีนี้จำนวน 1
        (หนึ่ง) สิทธิ์ ต่อ 1 (หนึ่ง) หมายเลขบัตรประจำตัวประชาชนเท่านั้น)
      </div>
      <div className="mb-[0.4rem]">
        หมายเหตุ: การทำรายการที่ไม่ได้รับสิทธิ์เล่นเกมและชิงโชคลุ้นรับรางวัลจากรายการส่งเสริมการขาย
        มีดังนี้{' '}
      </div>
      <ol>
        <li>● โอนเงินจากบัญชี ทรูมันนี่ เข้าบัญชีธนาคาร</li>
        <li>● เติมเงินเข้าบัญชี ทรูมันนี่ ผ่านช่องทางต่างๆ</li>
        <li>● รับซองของขวัญ</li>
        <li>● รายการอื่นใดนอกจากที่ระบุไว้ในตารางข้างต้น</li>
      </ol>
      <div className="mt-[1rem]">
        3.
        เมื่อผู้เข้าร่วมรายการส่งเสริมการขายได้รับสิทธิ์ในการเล่นเกมจากการทำรายการตามที่ระบุข้างต้นแล้ว
        ให้ผู้เข้าร่วมรายการส่งเสริมการขายเข้าไปที่แอปพลิเคชันทรูมันนี่ แล้วเลือกไอคอน หรือแบนเนอร์
        (Banner) ของรายการส่งเสริมการขาย “เปย์ง่ายได้รถ 2023”
        เพื่อเข้าสู่หน้าหลักของรายการส่งเสริมการขาย จากนั้น
        ผู้เข้าร่วมรายการส่งเสริมการขายจะต้องใช้สิทธิ์ในการเล่นเกมที่ได้มา
        โดยทำการเล่นเกมเพื่อให้ได้สิทธิ์ในการเข้าร่วมกิจกรรมชิงโชคของรางวัลต่อไป{' '}
      </div>
      <div className="mt-[0.5rem]">
        4. ผู้เข้าร่วมรายการส่งเสริมการขายต้องทำกิจกรรมเล่นเกมตามขั้นตอนดังต่อไปนี้
      </div>
      <div>4.1 กดปุ่ม “เล่นเกม” บนหน้าหลักของรายการส่งเสริมการขายเพื่อเริ่มเล่นเกม</div>
      <div>
        4.2 เมื่อเข้าสู่เกมแล้ว จะปรากฏไอคอนเด็กน้อย เส้นทางเดินรถ 4 เส้นทาง
        และประตูทางเข้าแต่ละเส้นทาง ซึ่งมุ่งไปสู่ปลายทางที่เป็นของรางวัลแต่ละอย่าง ประกอบด้วย รถยนต์
        Tesla, รถมอเตอร์ไซค์ Honda Click, โทรศัพท์มือถือ และทองคำแท่ง
        โดยผู้เข้าร่วมรายการส่งเสริมการขายสามารถเลือกของรางวัลที่ตนต้องการลุ้นรับจากการชิงโชคก่อน
        จากนั้นจะต้องจำเส้นทางที่จะพาเด็กน้อยขับรถไปให้ถึงของรางวัลที่ตนต้องการ
        ซึ่งอยู่ปลายทางของเส้นทางนั้น ทั้งนี้ มีเงื่อนไขพิเศษว่า
        ในระหว่างทางของแต่ละเส้นทางจะมีประตูมิติ ซึ่งแบ่งเป็นสีต่างๆ
        หากระหว่างเส้นทางมีการขับผ่านประตูมิติใด
        ไอคอนเด็กน้อยจะถูกเปลี่ยนเส้นทางไปอยู่อีกเส้นทางหนึ่งซึ่งมีประตูมิติสีเดียวกัน{' '}
      </div>
      <div>
        4.3 ผู้เข้าร่วมรายการส่งเสริมการขายจะมีเวลา 5 วินาที
        ในการจดจำเส้นทางและประตูมิติที่เป็นเงื่อนไขพิเศษที่จะพาเด็กน้อยไปอยู่ในเส้นทางสำหรับของรางวัลที่ตนต้องการ
        และเมื่อครบ 5 วินาทีแล้ว จะมีก้อนเมฆมาบังเส้นทางทั้งหมดนั้น
      </div>
      <div>
        4.4 จากนั้น จะต้องลากไอคอนเด็กน้อยไปที่ประตูทางเข้า
        เพื่อเข้าสู่เส้นทางที่จะนำไปยังของรางวัลที่ตนต้องการลุ้นชิงโชค ภายใน 10 วินาที
        โดยเมื่อถึงปลายทางแล้วรถของเด็กน้อยไปหยุดที่ของรางวัลใด
        ผู้เข้าร่วมรายการส่งเสริมการขายจะมีสิทธิ์เข้าร่วมการจับรางวัลชิงโชคสำหรับของรางวัลนั้น
        หากผู้เข้าร่วมรายการส่งเสริมการขายไม่ลากไอคอนเด็กน้อยไปที่ประตูทางเข้าใดภายใน 10
        วินาทีหลังจากมีก้อนเมฆมาบังเส้นทาง จะมีการเลือกเส้นทางเดินรถของเด็กน้อยโดยอัตโนมัติ
      </div>
      <div>
        4.5 หากต้องการได้รับสิทธิ์ในการเล่นเกมเพิ่มเติม
        ผู้เข้าร่วมรายการส่งเสริมการขายจะต้องทำรายการตามที่กำหนดไว้ ในข้อ 2. เพิ่มเติม
        โดยอยู่ภายใต้เงื่อนไขจำนวนสิทธิ์จำกัดตามที่กำหนดข้างต้น
      </div>
      <div className="mt-[0.5rem]">
        5. บริษัทจะทำการประกาศรางวัลชิงโชคเพื่อหาผู้โชคดีที่ได้รับรางวัล โดยแบ่งออกเป็น 2 รอบ
        ดังต่อไปนี้
      </div>
      <div className="mt-[0.3rem] mb-[0.1rem]">
        <span className="underline">ประกาศรางวัล รอบที่ 1:</span> วันศุกร์ที่ 12 มกราคม 2567 เวลา
        19:30 น.
      </div>
      <div>
        (รวบรวมสิทธิ์ลุ้นรางวัลชิงโชค ตั้งแต่วันที่ 1 ธันวาคม 2566 เวลา 00.01 น. ถึงวันที่ 30
        ธันวาคม 2566 เวลา 23.59 น.)
      </div>
      <div className="mt-[0.3rem] mb-[0.1rem]">
        <span className="underline ">ประกาศรางวัล รอบที่ 2:</span> วันจันทร์ที่ 12 กุมภาพันธ์ 2567
        เวลา 19:30 น.
      </div>
      <div>
        (รวบรวมสิทธิ์ลุ้นรางวัลชิงโชค ตั้งแต่วันที่ 1 มกราคม 2567 เวลา 00.01 น. ถึงวันที่ 31 มกราคม
        2567 เวลา 23.59 น.)
      </div>
      {/* <div className="mt-[0.3rem] mb-[0.1rem]">
        <span className="underline">ประกาศรางวัล รอบที่ 3:</span> วันจันทร์ที่ 12 ธันวาคม 2566 เวลา
        19:30 น.
      </div>
      <div>
        (รวบรวมสิทธิ์ลุ้นรางวัลชิงโชค ตั้งแต่วันที่ 1 พฤศจิกายน 2566 เวลา 00.01 น. ถึงวันที่ 30
        พฤศจิกายน 2566 เวลา 23.59 น.)
      </div> */}
      <div className="mt-[0.5rem]">
        6. โดยผู้เข้าร่วมรายการส่งเสริมการขาย
        จะมีสิทธิ์ชิงโชคลุ้นรับของรางวัลตามผลของรางวัลที่ตนได้รับจากการเล่นเกมเท่านั้น
        (สำหรับแต่ละสิทธิ์) ตัวอย่างเช่น
        ผู้เข้าร่วมรายการส่งเสริมการขายเล่นเกมและปรากฏว่าได้รับสิทธิ์ในการเข้าร่วมชิงโชคลุ้นรับ
        โทรศัพท์มือถือ iPhone 15 Pro
        ผู้เข้าร่วมรายการส่งเสริมการขายก็จะมีสิทธิ์เข้าร่วมการจับรางวัลชิงโชค โทรศัพท์มือถือ iPhone
        15 Pro เท่านั้น
      </div>
      <div className="mt-[0.5rem]">
        7. ผู้เข้าร่วมรายการส่งเสริมการขายทำรายการใช้จ่ายตามที่กำหนดในข้อ 2. ในเดือนใด
        จะต้องทำการเล่นเกมในเดือนนั้น
        และจะมีสิทธิ์เข้าร่วมการจับรางวัลชิงโชคสำหรับรอบการจับรางวัลตามรอบที่กำหนดไว้เท่านั้น
        (รอบต่อรอบตามที่กำหนดในข้อ 5.)
        โดยไม่สามารถสะสมสิทธิ์เล่นเกมหรือสิทธิ์การจับรางวัลชิงโชคของรางวัล
        เพื่อไปเข้าร่วมจับรางวัลชิงโชคในรอบ จับรางวัลรอบอื่นได้
      </div>
      <div className="mt-[0.5rem]">
        8. กติกาการตัดสินสำหรับการจับรางวัลชิงโชค
        บริษัทจะพิมพ์รายชื่อผู้ที่มีสิทธิ์ได้รับรางวัลจากการทำรายการลงบนกระดาษแล้วตัดเป็นชิ้นส่วนแต่ละคน
        แล้วนำมาใส่ภาชนะที่มองเห็นชิ้นส่วนภายในได้ชัดเจนแล้วใช้วิธีคลุกเคล้าชิ้นส่วนทั้งหมดให้ทั่วกันแล้วโยนขึ้นบนอากาศ
        เชิญแขกผู้มีเกียรติ และผู้ทรงคุณวุฒิที่น่าเชื่อถือ
        จับชิ้นส่วนต่อหน้าคณะกรรมการและสักขีพยานเพื่อให้ได้รับรางวัลตามที่กำหนดไว้
        พร้อมทั้งอ่านรายชื่อผู้โชคดีทันทีเพื่อให้ทุกๆ ท่านที่มาร่วมงานทราบโดยทั่วกัน
      </div>
      <div className="mt-[0.5rem]">
        9. บริษัทจะประกาศรายชื่อผู้ที่มีสิทธิ์ได้รับรางวัลสำหรับต่อที่หนึ่งแต่ละรางวัล ในแต่ละรอบ
        ผ่านเฟซบุ๊ค www.facebook.com/truemoney, แอปพลิเคชัน ทรูมันนี่ ในหน้ากิจกรรม “เปย์ง่ายได้รถ
        2023” เมนู “ดูผลรางวัล” และเว็บไซต์ https://www.truemoney.com/luckydraw-pay-announce
        หลังจากการจับรางวัลในแต่ละรอบเสร็จสิ้นแล้ว
      </div>
      <div className="mt-[1.5rem] mb-[0.5rem] font-medium">
        <span className="underline">ต่อที่สอง:</span> กิจกรรม Cap & Share
      </div>
      <div>
        เมื่อผู้เข้าร่วมรายการส่งเสริมการขายเล่นเกมจบแล้ว
        หน้าจอจะแสดงผลภาพของรางวัลที่ผู้เข้าร่วมรายการส่งเสริมการขาย
        จะได้รับสิทธิ์ลุ้นรับในการชิงโชค พร้อมทั้งแสดงเลขที่อ้างอิงเพื่อใช้ในการจับรางวัลชิงโชค{' '}
      </div>
      <div className="mt-[0.5rem]">
        1. ผู้เข้าร่วมรายการส่งเสริมการขาย
        ทำการแคปเจอร์ภาพหน้าจอที่แสดงผลของรางวัลที่ผู้เข้าร่วมรายการส่งเสริมการขายจะได้รับสิทธิ์ลุ้นรับในการชิงโชค
        ภาพหน้าจอดังกล่าวต้องแสดงเลขที่อ้างอิงชัดเจน
      </div>
      <div className="mt-[0.5rem]">
        2. ผู้เข้าร่วมรายการส่งเสริมการขาย ทำการแชร์ภาพที่ได้บันทึกไว้ดังกล่าว ลงบนหน้าเฟซบุ๊ค
        (​Facebook) หรือ ทวิตเตอร์ (Twitter) ของตนเอง โดยตั้งสถานะการแชร์เป็นแบบสาธารณะ (Public)
        พร้อมทั้งพิมพ์ข้อความและใส่แฮชแท็ก “#TrueMoneyเปย์ง่ายได้รถ”
      </div>
      <div className="mt-[0.5rem]">
        3. บริษัทจะทำการสุ่มคัดเลือกผู้โชคดีที่มีสิทธิ์ได้รับรางวัลต่อที่ 2 เป็นประจำทุกสัปดาห์
        สัปดาห์ละ 1 รางวัล โดยจะประกาศรายชื่อผู้ที่มีสิทธิ์ได้รับรางวัลของแต่ละสัปดาห์ ทั้งหมด 1 รอบ
        รอบละ 1 (หนึ่ง) รางวัล ผ่านทางเฟซบุ๊ค www.facebook.com/truemoney ตามวันและเวลาดังนี้
      </div>
      <div className="mt-[0.4rem]">
        <span className="underline">รอบที่ 1:</span> วันศุกร์ที่ 1 ธันวาคม 2566 เวลา 19.30 น.
        (ประกาศรายชื่อผู้โชคดี 1 ท่าน)
      </div>
      <div className="mt-[0.2rem]">
        <span className="underline">รอบที่ 2:</span> วันศุกร์ที่ 8 ธันวาคม 2566 เวลา 19.30 น.
        (ประกาศรายชื่อผู้โชคดี 1 ท่าน)
      </div>
      <div className="mt-[0.2rem]">
        <span className="underline">รอบที่ 3:</span> วันศุกร์ที่ 15 ธันวาคม 2566 เวลา 19.30 น.
        (ประกาศรายชื่อผู้โชคดี 1 ท่าน)
      </div>
      <div className="mt-[0.2rem]">
        <span className="underline">รอบที่ 4:</span> วันศุกร์ที่ 22 ธันวาคม เวลา 19.30 น.
        (ประกาศรายชื่อผู้โชคดี 1 ท่าน)
      </div>
      <div className="mt-[0.2rem]">
        <span className="underline">รอบที่ 5:</span> วันศุกร์ที่ 5 มกราคม 2567 เวลา 19.30 น.
        (ประกาศรายชื่อผู้โชคดี 1 ท่าน)
      </div>
      <div className="mt-[0.2rem]">
        <span className="underline">รอบที่ 6:</span> วันศุกร์ที่ 12 มกราคม 2567 เวลา 19.30 น.
        (ประกาศรายชื่อผู้โชคดี 1 ท่าน)
      </div>
      <div className="mt-[0.2rem]">
        <span className="underline">รอบที่ 7:</span> วันศุกร์ที่ 19 มกราคม 2567 เวลา 19.30 น.
        (ประกาศรายชื่อผู้โชคดี 1 ท่าน)
      </div>
      <div className="mt-[0.2rem]">
        <span className="underline">รอบที่ 8:</span> วันศุกร์ที่ 2 กุมภาพันธ์ 2567 เวลา 19.30 น.
        (ประกาศรายชื่อผู้โชคดี 1 ท่าน)
      </div>
      {/* <div className="mt-[0.2rem]">
        <span className="underline">รอบที่ 9:</span> วันศุกร์ที่ 3 พฤศจิกายน 2566 เวลา 19.30 น.
        (ประกาศรายชื่อผู้โชคดี 1 ท่าน)
      </div>
      <div className="mt-[0.2rem]">
        <span className="underline">รอบที่ 10:</span> วันศุกร์ที่ 10 พฤศจิกายน 2566 เวลา 19.30 น.
        (ประกาศรายชื่อผู้โชคดี 1 ท่าน)
      </div>
      <div className="mt-[0.2rem]">
        <span className="underline">รอบที่ 11:</span> วันศุกร์ที่ 17 พฤศจิกายน 2566 เวลา 19.30 น.
        (ประกาศรายชื่อผู้โชคดี 1 ท่าน)
      </div>
      <div className="mt-[0.2rem]">
        <span className="underline">รอบที่ 12:</span> วันศุกร์ที่ 24 พฤศจิกายน 2566 เวลา 19.30 น.
        (ประกาศรายชื่อผู้โชคดี 1 ท่าน)
      </div> */}
      <div className="mt-[1rem] mb-[0.1rem] font-medium">รายละเอียดของรางวัล</div>
      <div>
        <span className="underline font-medium">ต่อที่หนึ่ง:</span>{' '}
        ของรางวัลสำหรับกิจกรรมชิงโชคลุ้นรับของรางวัล สำหรับรอบการจับรางวัล 1 รอบ ดังต่อไปนี้
      </div>
      <div>{/* <span className="">สำหรับรอบการจับรางวัล 1 รอบ</span> ดังต่อไปนี้ */}</div>
      <div className="mt-[0.5rem]">
        1. รถยนต์ Tesla Model 3 Rear-Wheel Drive สีดำ (Solid Black) มูลค่า 1,599,000
        (หนึ่งล้านห้าแสนเก้าหมื่นเก้าพัน) บาท รวมภาษีมูลค่าเพิ่ม จำนวน 1 (หนึ่ง) รางวัล ต่อ 1
        (หนึ่ง) เดือน
      </div>
      <div className="mt-[0.5rem]">
        2. รถมอเตอร์ไซค์ Honda Click 125i 2022 ล้อแม็ก สีแดง-ดำ มูลค่า 55,500
        (ห้าหมื่นห้าพันห้าร้อย) บาท รวมภาษีมูลค่าเพิ่ม ต่อรางวัล จำนวน 2 (สอง) รางวัล ต่อ 1 (หนึ่ง)
        เดือน
      </div>
      <div className="mt-[0.5rem]">
        3. โทรศัพท์มือถือ iPhone 15 Pro มูลค่า 41,900 (สี่หมื่นหนึ่งพันเก้าร้อย) บาท
        รวมภาษีมูลค่าเพิ่ม ต่อรางวัล จำนวน 3 (สาม) รางวัล ต่อ 1 (หนึ่ง) เดือน
      </div>
      <div className="mt-[0.5rem]">
        4. ทองคำแท่ง 96.5% น้ำหนัก 1 (หนึ่ง) บาท มูลค่า 33,600 บาท (สามหมื่นสามพันหกร้อยบาท)
        รวมภาษีมูลค่าเพิ่มเฉพาะค่าบล็อก ต่อรางวัล จำนวน 3 (สาม) รางวัล ต่อ 1 (หนึ่ง) เดือน
      </div>
      <div className="mt-[0.8rem]">หมายเหตุ: </div>
      <div>
        ● ของรางวัลข้างต้นสำหรับการจับรางวัลชิงโชค 1 รอบจับรางวัลเท่านั้น ทั้งนี้
        การจับรางวัลชิงโชคจัดขึ้นทั้งหมด 3 รอบ ตลอดระยะเวลาของรายการส่งเสริมการขาย
      </div>
      <div>
        ● บริษัทจำกัดสิทธิ์ในการให้รางวัลรถยนต์ Tesla Model 3 Rear-Wheel Drive สีดำ (Solid Black),
        Honda Click 125i 2022, iPhone 15 Pro รุ่นความจุ 128 GB, ทองคำแท่ง 96.5% น้ำหนัก 1 (หนึ่ง)
        บาท โดยผู้เข้าร่วมรายการส่งเสริมการขาย มีสิทธิได้รับของรางวัลแต่ละอย่าง 1 (หนึ่ง) สิทธิ์ ต่อ
        1 (หนึ่ง) หมายเลขบัตรประจำตัวประชาชน ตลอดระยะเวลาของรายการส่งเสริมการขาย
      </div>
      <div className="mt-[1rem] ">
        <span className="underline font-medium">ต่อที่สอง:</span> ของรางวัลสำหรับกิจกรรม Cap & Share
      </div>
      <div className="my-[0.5rem]">
        สร้อยคอทองคำ 96.5% น้ำหนัก 50 (ห้าสิบ) สตางค์ มูลค่า 16,903 (หนึ่งหมื่นหกพันเก้าร้อยสาม) บาท
        รวมภาษีมูลค่าเพิ่ม ต่อรางวัล จำนวน 4 (สี่) รางวัลต่อเดือน รวมทั้งสิ้น 8 (แปด) รางวัล
        ตลอดระยะเวลาของรายการส่งเสริมการขาย
      </div>
      <div>
        หมายเหตุ: สงวนสิทธิ์การรับของรางวัลต่อที่ 2 สำหรับผู้เข้าร่วมรายการส่งเสริมการขาย 1 (หนึ่ง)
        ท่าน ต่อ 1 (หนึ่ง) รางวัล ตลอดระยะเวลาของรายการส่งเสริมการขายเท่านั้น
      </div>
      <div className="mt-[1.5rem] font-medium">
        รายละเอียดและเงื่อนไขอื่นๆ ของรายการส่งเสริมการขาย
      </div>
      <div className="mt-[0.5rem]">
        1.
        ข้อกำหนดและเงื่อนไขนี้มีขึ้นเพื่อให้ผู้เข้าร่วมรายการส่งเสริมการขายทราบถึงรายละเอียดและเงื่อนไขต่าง
        ๆ ในการเข้าร่วมรายการส่งเสริมการขาย “เปย์ง่ายได้รถ 2023”{' '}
        <span className="font-medium">(“รายการส่งเสริมการขาย”)</span> ทั้งนี้
        ในการเข้าร่วมรายการส่งเสริมการขาย ท่านจะต้องยอมรับ
        และตกลงในรายละเอียดระยะเวลารายการส่งเสริมการขาย, วิธีการร่วมรายการส่งเสริมการขายและรางวัล
        และรายละเอียดเงื่อนไขนี้
      </div>
      <div className="mt-[0.5rem]">
        2. รายการส่งเสริมการขาย จัดขึ้นโดย บริษัท ทรู มันนี่ จำกัด{' '}
        <span className="font-medium">(“บริษัท”)</span>
      </div>
      <div className="mt-[0.5rem]">
        3. ผู้เข้าร่วมรายการส่งเสริมการขายที่มีสิทธิ์ได้รับรางวัล คือ
        ผู้เข้าร่วมรายการส่งเสริมการขายที่ทำตามรายละเอียดวิธีการร่วมรายการส่งเสริมการขายครบถ้วนภายในระยะเวลารายการส่งเสริมการขาย
        และถูกต้องตามรายละเอียดเงื่อนไขนี้เท่านั้น
      </div>
      <div className="mt-[0.5rem]">
        4.
        เมื่อมีการประกาศผลรางวัลและรายชื่อผู้เข้าร่วมรายการส่งเสริมการขายที่มีสิทธิ์ได้รับรางวัลทั้งหมดจากรายการส่งเสริมการขายในแต่ละรอบแล้ว
        ผู้ที่มีสิทธิ์ได้รับรางวัล ทั้งจากกิจกรรมจับรางวัลชิงโชค (ต่อที่ 1) และกิจกรรม Cap & Share
        (ต่อที่ 2) จะต้องทำการติดต่อขอรับของรางวัลที่ตนมีสิทธิ์ได้รับจากบริษัท
        และต้องทำการยืนยันตัวตนกับบริษัทผ่านทางเบอร์โทรศัพท์ 1240 ภายในระยะเวลา 20 (ยี่สิบ) วัน
        นับจากวันที่ประกาศผลรางวัลในแต่ละรอบ ทั้งนี้
        ผู้ที่มีสิทธิ์ได้รับของรางวัลจะต้องติดต่อบริษัทภายในเวลาทำการ (วันจันทร์ถึงวันศุกร์ เวลา
        10.00 น. - 18.00 น.) ยกเว้น วันหยุดนักขัตฤกษ์และวันหยุดทำการของบริษัท
        โดยภายหลังจากยืนยันตัวตนกับทางบริษัทเรียบร้อยแล้ว
        บริษัทจะติดต่อกลับไปยังผู้มีสิทธิ์ได้รับของรางวัลภายใน 3 (สาม) วันทำการ โดยแจ้งวัน เวลา
        และสถานที่เพื่อนัดหมายให้มารับของรางวัล โดยมีข้อกำหนดและเงื่อนไข ดังต่อไปนี้
      </div>
      <div>
        4.1 ผู้มีสิทธิ์ได้รับของรางวัลที่ได้ทำการยืนยันตัวตนตามที่กำหนดข้างต้นกับบริษัทเรียบร้อยแล้ว
        ต้องมารับของรางวัลด้วยตนเอง ตามวัน เวลา และสถานที่ ตามที่บริษัทนัดหมายเท่านั้น ทั้งนี้
        ในการมารับของรางวัล ผู้มีสิทธิ์ได้รับของรางวัลจะต้องแสดงบัตรประชาชนตัวจริงของตน พร้อมทั้ง
        นำสำเนาบัตรประจำตัวประชาชนที่ยังไม่หมดอายุ และสำเนาทะเบียนบ้าน (ลงนามรับรองสำเนาถูกต้อง)
        มามอบให้แก่บริษัทในวันนัดหมายดังกล่าวด้วย หากเอกสารรายการใดไม่ครบถ้วน
        บริษัทขอสงวนสิทธิ์ในการยกเลิกการให้รางวัลดังกล่าว
        และมีสิทธิ์ติดต่อเพื่อมอบของรางวัลดังกล่าวให้แก่ผู้ที่มีสิทธิ์ได้รับรางวัล ในลำดับถัดไปแทน
      </div>
      <div>
        4.2 ผู้มีสิทธิ์ได้รับของรางวัลต้องเป็นผู้รับผิดชอบชำระภาษี ค่าธรรมเนียม ค่าใช้จ่าย
        และค่าดำเนินการอื่นๆที่เกี่ยวข้องกับของรางวัลที่ได้รับด้วยตนเองทั้งสิ้น อาทิเช่น ภาษีหัก ณ
        ที่จ่าย 5% และภาษีมูลค่าเพิ่ม 7% ของมูลค่าของรางวัลที่ได้รับ
        (กรณีได้รับของรางวัลซึ่งมีมูลค่าตั้งแต่ 1,000 (หนึ่งพัน) บาทขึ้นไป) ค่าจดทะเบียนรถใหม่
        ค่าดำเนินการอื่นใดเพื่อให้ได้รับของรางวัลดังกล่าวนั้นมาโดยถูกต้องสมบูรณ์
        โดยผู้มีสิทธิ์ได้รับของรางวัลจะต้องเตรียมเงินมาเพื่อชำระในวันที่มารับของรางวัลดังกล่าวด้วย
        ทั้งนี้ บริษัทไม่มีส่วนร่วมในการรับผิดชอบบรรดาภาษี ค่าใช้จ่าย
        และค่าดำเนินการอื่นใดข้างต้นทั้งสิ้น ทั้งนี้
        บริษัทขอสงวนสิทธิ์ในการยกเลิกการให้ของรางวัลหากผู้มีสิทธิ์ได้รับรางวัลไม่ชำระภาษีตามที่กฎหมายกำหนด
        หรือ
        ไม่ชำระค่าดำเนินการอื่นใดที่เกี่ยวข้องกับของรางวัลเพื่อให้ได้รับของรางวัลนั้นมาให้ครบถ้วน
      </div>
      <div>
        4.3 หากผู้ที่มีสิทธิ์ได้รับรางวัลในแต่ละรอบ ไม่ทำการติดต่อขอรับของรางวัลจากบริษัท ภายใน 20
        วันนับตั้งแต่วันที่ประกาศผลรางวัลในแต่ละรอบ
        บริษัทขอสงวนสิทธิ์ในการยกเลิกการให้รางวัลดังกล่าว
        และมีสิทธิ์ติดต่อเพื่อมอบของรางวัลดังกล่าวให้แก่ผู้ที่มีสิทธิ์ได้รับรางวัล ในลำดับถัดไปแทน
      </div>
      <div>
        4.4 ผู้มีสิทธิ์ได้รับของรางวัลต้องมารับของรางวัลด้วยตัวเองเท่านั้น
        มิเช่นนั้นจะถือว่าสละสิทธิ์การรับของรางวัลดังกล่าว
        และบริษัทสงวนสิทธิ์ในการติดต่อเพื่อมอบของรางวัลดังกล่าวให้แก่ผู้ที่มีสิทธิ์ได้รับรางวัล
        ในลำดับถัดไปแทน
      </div>
      <div className="mt-[0.5rem]">
        5. บริษัทจำกัดสิทธิ์ในการเข้าร่วมรายการส่งเสริมการขาย 1 (หนึ่ง) หมายเลขบัตรประจำตัวประชาชน
        ต่อ 1 (หนึ่ง) บัญชีทรูมันนี่ เท่านั้น
      </div>
      <div className="mt-[0.5rem]">
        6. รางวัลนี้ไม่สามารถแลกเปลี่ยนเป็นเงินสด หรือรางวัลอื่นได้
        รวมทั้งไม่สามารถโอนสิทธิ์ในการรับรางวัลให้กับผู้อื่น
      </div>
      <div className="mt-[0.5rem]">
        7. ผู้มีสิทธิ์ได้รับรางวัลต้องเข้ามาถ่ายรูปและรับรางวัล ณ วัน เวลา
        และสถานที่ตามที่บริษัทระบุ พร้อมทั้งรับทราบและตกลงให้บริษัทนำสื่อภาพ และ/หรือ วิดีโอ
        ของผู้มีสิทธิ์ได้รับรางวัลที่เกี่ยวข้องกับการรับรางวัลภายใต้รายการส่งเสริมการขายนี้ไปโปรโมทโฆษณาผ่านช่องทางต่างๆ
        ในนามของบริษัท
      </div>
      <div className="mt-[0.5rem]">
        8. ผู้เข้าร่วมรายการส่งเสริมการขายรับรองว่าข้อมูลที่ใช้สมัครบัญชีทรูมันนี่ และ/หรือ
        ใช้ยืนยันการรับสิทธิ์ตามวิธีการร่วมกิจกรรมของรายการส่งเสริมการขายเป็นข้อมูลของตนและเป็นข้อมูลที่ถูกต้องและตรงกันเท่านั้น
        หากบริษัทตรวจสอบพบว่าข้อมูลดังกล่าวเป็นข้อมูลเท็จหรือไม่ใช่ข้อมูลของผู้เข้าร่วมรายการส่งเสริมการขาย
        บริษัทขอสงวนสิทธิ์ในการยกเลิกการให้รางวัล เรียกของรางวัลคืน
        รวมทั้งมีสิทธิ์ในการเรียกค่าเสียหายจากผู้เข้าร่วมรายการส่งเสริมการขายได้{' '}
      </div>
      <div className="mt-[0.5rem]">
        9. บริษัทสงวนสิทธิ์ในการยกเลิก เปลี่ยนแปลงระยะเวลารายการส่งเสริมการขาย,
        วิธีการร่วมรายการส่งเสริมการขาย, รางวัล และ รายละเอียดเงื่อนไข
        และ/หรือรายละเอียดอื่นใดโดยไม่ต้องแจ้งให้ผู้เข้าร่วมรายการส่งเสริมการขายทราบ
        ในกรณีที่มีข้อโต้แย้งใดๆ เกิดขึ้นคำตัดสินของบริษัทถือเป็นที่สิ้นสุด
      </div>
      <div className="mt-[0.5rem]">
        10. การแจกของรางวัลเป็นดุลยพินิจของบริษัทเท่านั้น และบริษัทสงวนสิทธิ์ในการตรวจสอบข้อมูล
        ยกเลิกการให้ของรางวัล และ/หรือดำเนินการอื่นใดหากบริษัทตรวจสอบพบว่ามีการฉ้อฉล ทุจริต
        หรือดำเนินการใดๆ อันมิชอบด้วยกฎหมาย เพื่อให้ได้รับรางวัลหรือเพื่อวัตถุประสงค์อื่นใด
      </div>
      <div className="mt-[0.5rem]">
        11. กรณีที่ซื้อสินค้า และภายหลังทำการยกเลิกการซื้อสินค้าดังกล่าว (Void/ Refund/ Cancel)
        บริษัทสงวนสิทธิ์ในการตัดสิทธิ์การเข้าร่วมรายการส่งเสริมการขายนี้
        และ/หรือรายการส่งเสริมอื่นใดของบริษัท
        กับผู้เข้าร่วมรายการส่งเสริมการขายที่มีการกระทำดังกล่าว รวมทั้งยกเลิกการให้รางวัล
        หรือเรียกรางวัลคืนจากผู้เข้าร่วมรายการส่งเสริมการขายที่มีการกระทำดังกล่าวได้
      </div>
      <div className="mt-[0.5rem]">
        12. พนักงาน บริษัท แอสเซนด์ มันนี่ จำกัด และคณะกรรมการดำเนินงานทั้งหมด
        รวมถึงครอบครัวไม่มีสิทธิ์เข้าร่วมรายการส่งเสริมการขายนี้
      </div>
      <div className="mt-[0.5rem]">
        13. รายการส่งเสริมการขายนี้ จัดขึ้นโดยถูกต้องตามกฎหมาย
        อ้างอิงใบอนุญาตจัดให้มีการเล่นแถมพกหรือรางวัลในการเสี่ยงโชคฯ โดยศูนย์บริการประชาชน
        กรมการปกครอง เลขที่ 768-769/2566
      </div>
      <div className="mt-[0.5rem]">
        14. ในการเข้าร่วมรายการส่งเสริมการขายนี้
        ผู้เข้าร่วมรายการส่งเสริมการขายตกลงและรับทราบว่าบริษัทมีความจำเป็นที่จะต้องประมวลผลข้อมูลส่วนบุคคล
        อาทิเช่น ชื่อ-นามสกุล เบอร์โทรศัพท์
        รวมถึงข้อมูลบนบัตรประชาชนของผู้เข้าร่วมรายการส่งเสริมการขาย
        เพื่อวัตถุประสงค์ในการดำเนินการตามรายการส่งเสริมการขาย
        โดยบริษัทอาจเปิดเผยข้อมูลส่วนบุคคลของผู้เข้าร่วมรายการส่งเสริมการขายให้แก่บุคคลภายนอกซึ่งทำหน้าที่ประมวลผลข้อมูลส่วนบุคคลให้กับบริษัทอันเกี่ยวกับการดำเนินการตามรายการส่งเสริมการขายนี้
      </div>
      <div className="mt-[0.5rem]">15. สอบถามรายละเอียดเพิ่มเติมที่ 1240</div>
    </div>
  )
}
