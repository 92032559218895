export const isDeviceAndroid = () => {
  const isMatch = !!navigator.userAgent.match(/android/i)
  return isMatch
}

export const isDeviceIOS = () => {
  const isMatch = !!navigator.userAgent.match(/iphone|ipad|ipod/i)
  return isMatch
}

export const getDeviceData = () => {
  const isAndroid = isDeviceAndroid()
  const isIOS = isDeviceIOS()
  return {
    isAndroid: isAndroid,
    isIOS: isIOS,
    isOther: !isAndroid && !isIOS,
  }
}
