import { STATIC_URL } from '../../config'

const prefix = `${STATIC_URL}/home`

export const HOME_IMGS = {
  bg: `${prefix}/bg.png`,
  logo: `${prefix}/logo.png`,
  btnPlay: `${prefix}/btn-play.png`,
  btnHistory: `${prefix}/btn-history.png`,
  btnDetail: `${prefix}/btn-detail.png`,
  winnerImage: `${prefix}/winner-3.jpg`,
}
