import { useEffect, useRef } from 'react'

export const isOverElement = (dragElement, dropElement, dropOffset = 40) => {
  if (!dragElement || !dropElement) return false
  const dragRect = dragElement.getBoundingClientRect()
  const dropRect = dropElement.getBoundingClientRect()
  return (
    dragRect.left < dropRect.right - dropOffset &&
    dragRect.right > dropRect.left + dropOffset &&
    dragRect.top < dropRect.bottom - dropOffset &&
    dragRect.bottom > dropRect.top + dropOffset
  )
}

export const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const wait = (delayMs = 1000) => {
  return new Promise((resolve) => setTimeout(resolve, delayMs))
}

export const useIsMounted = () => {
  const ref = useRef(false)
  useEffect(() => {
    ref.current = true
    return () => {
      ref.current = false
    }
  }, [])
  return ref
}

export const useGamePathDebug = () => {
  const logMapPosition = (event) => {
    const xScale = +(event.nativeEvent.offsetX / event.target.width).toFixed(3)
    const yScale = +(event.nativeEvent.offsetY / event.target.height).toFixed(3)
    window.gameDebugPath.push({
      x: xScale,
      y: yScale,
    })
    console.log(JSON.stringify(window.gameDebugPath))
  }

  useEffect(() => {
    window.gameDebugPath = []
  }, [])

  return {
    logMapPosition,
  }
}

export const getStartPosition = () => {
  const OFFSET_X = 55
  const OFFSET_Y = 25
  return {
    x: window.innerWidth / 2 - OFFSET_X,
    y: window.innerHeight / 8 - OFFSET_Y,
  }
}
