export const loadFontToDocument = async (name, url, weight) => {
  try {
    const fontFace = new FontFace(name, `url(${url})`, {
      display: 'swap',
      weight,
    })
    const loadedFont = await fontFace.load()
    document.fonts.add(loadedFont)
  } catch (error) {
    console.error(error)
  }
}
