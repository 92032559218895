export const DATA_MAP_8 = {
  1: {
    rewardPosition: 1,
    paths: [
      { x: 0.211, y: 0.07 },
      { x: 0.211, y: 0.129 },
      { x: 0.211, y: 0.188 },
      { x: 0.219, y: 0.246 },
      { x: 0.253, y: 0.291 },
      { x: 0.301, y: 0.314 },
      { x: 0.376, y: 0.317 },
      { x: 0.437, y: 0.331 },
      { x: 0.48, y: 0.353 },
      { x: 0.515, y: 0.387 },
      { x: 0.523, y: 0.437 },
      { x: 0.523, y: 0.462, isFading: true },
      { x: 0.259, y: 0.709, isFading: true },
      { x: 0.259, y: 0.709 },
      { x: 0.227, y: 0.748 },
      { x: 0.205, y: 0.798 },
      { x: 0.205, y: 0.86 },
      { x: 0.205, y: 0.899 },
      { x: 0.205, y: 0.95 },
    ],
  },
  2: {
    rewardPosition: 4,
    paths: [
      { x: 0.405, y: 0.064 },
      { x: 0.405, y: 0.118 },
      { x: 0.405, y: 0.174 },
      { x: 0.416, y: 0.23 },
      { x: 0.445, y: 0.277 },
      { x: 0.499, y: 0.305 },
      { x: 0.555, y: 0.303 },
      { x: 0.595, y: 0.303 },
      { x: 0.629, y: 0.305 },
      { x: 0.675, y: 0.3, isFading: true },
      { x: 0.8, y: 0.675, isFading: true },
      { x: 0.8, y: 0.675 },
      { x: 0.8, y: 0.717 },
      { x: 0.803, y: 0.77 },
      { x: 0.8, y: 0.815 },
      { x: 0.8, y: 0.86 },
      { x: 0.8, y: 0.902 },
      { x: 0.8, y: 0.95 },
    ],
  },
  3: {
    rewardPosition: 3,
    paths: [
      { x: 0.605, y: 0.067 },
      { x: 0.605, y: 0.132 },
      { x: 0.592, y: 0.188 },
      { x: 0.56, y: 0.227 },
      { x: 0.512, y: 0.241 },
      { x: 0.451, y: 0.244 },
      { x: 0.387, y: 0.249 },
      { x: 0.331, y: 0.246 },
      { x: 0.269, y: 0.246 },
      { x: 0.221, y: 0.246 },
      { x: 0.187, y: 0.258 },
      { x: 0.139, y: 0.286 },
      { x: 0.107, y: 0.345 },
      { x: 0.099, y: 0.409 },
      { x: 0.107, y: 0.476 },
      { x: 0.139, y: 0.524 },
      { x: 0.187, y: 0.546 },
      { x: 0.243, y: 0.555 },
      { x: 0.296, y: 0.552 },
      { x: 0.357, y: 0.552 },
      { x: 0.421, y: 0.552 },
      { x: 0.483, y: 0.552 },
      { x: 0.539, y: 0.552 },
      { x: 0.597, y: 0.552 },
      { x: 0.645, y: 0.552 },
      { x: 0.699, y: 0.557 },
      { x: 0.747, y: 0.566 },
      { x: 0.784, y: 0.594 },
      { x: 0.797, y: 0.636 },
      { x: 0.803, y: 0.675, isFading: true },
      { x: 0.683, y: 0.303, isFading: true },
      { x: 0.683, y: 0.303 },
      { x: 0.731, y: 0.3 },
      { x: 0.784, y: 0.308 },
      { x: 0.835, y: 0.319 },
      { x: 0.888, y: 0.353 },
      { x: 0.909, y: 0.406 },
      { x: 0.912, y: 0.462 },
      { x: 0.912, y: 0.524 },
      { x: 0.912, y: 0.585 },
      { x: 0.912, y: 0.641 },
      { x: 0.912, y: 0.689 },
      { x: 0.909, y: 0.742 },
      { x: 0.883, y: 0.779 },
      { x: 0.84, y: 0.81 },
      { x: 0.795, y: 0.826 },
      { x: 0.76, y: 0.821 },
      { x: 0.715, y: 0.821 },
      { x: 0.664, y: 0.821 },
      { x: 0.619, y: 0.852 },
      { x: 0.605, y: 0.888 },
      { x: 0.605, y: 0.95 },
    ],
  },
  4: {
    rewardPosition: 2,
    paths: [
      { x: 0.8, y: 0.067 },
      { x: 0.803, y: 0.126 },
      { x: 0.803, y: 0.174 },
      { x: 0.8, y: 0.232 },
      { x: 0.8, y: 0.291 },
      { x: 0.797, y: 0.345 },
      { x: 0.779, y: 0.401 },
      { x: 0.733, y: 0.429 },
      { x: 0.672, y: 0.429 },
      { x: 0.632, y: 0.459 },
      { x: 0.613, y: 0.501 },
      { x: 0.611, y: 0.546 },
      { x: 0.608, y: 0.597 },
      { x: 0.608, y: 0.644 },
      { x: 0.608, y: 0.689 },
      { x: 0.6, y: 0.734 },
      { x: 0.571, y: 0.765 },
      { x: 0.531, y: 0.776 },
      { x: 0.472, y: 0.782 },
      { x: 0.408, y: 0.782 },
      { x: 0.352, y: 0.784 },
      { x: 0.285, y: 0.79 },
      { x: 0.216, y: 0.773 },
      { x: 0.144, y: 0.742 },
      { x: 0.101, y: 0.667 },
      { x: 0.093, y: 0.591 },
      { x: 0.104, y: 0.513 },
      { x: 0.136, y: 0.459 },
      { x: 0.197, y: 0.429 },
      { x: 0.251, y: 0.42 },
      { x: 0.304, y: 0.431 },
      { x: 0.347, y: 0.465 },
      { x: 0.376, y: 0.513 },
      { x: 0.397, y: 0.563 },
      { x: 0.405, y: 0.619 },
      { x: 0.405, y: 0.681 },
      { x: 0.408, y: 0.739 },
      { x: 0.413, y: 0.812 },
      { x: 0.408, y: 0.871 },
      { x: 0.408, y: 0.95 },
    ],
  },
}
