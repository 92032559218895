import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Home from './features/Home/'
import History from './features/History/'
import { DetailPage } from './features/page-detail'
import { GamePage } from './features/game'
import { GameRewardPage } from './features/game-reward'

export const ROUTER_PATHS = {
  home: '',
  history: 'history',
  detail: 'detail',
  game: 'game',
  gameReward: 'game/reward',
}

export const router = createBrowserRouter([
  {
    path: ROUTER_PATHS.home,
    element: <Home />,
  },
  {
    path: ROUTER_PATHS.history,
    element: <History />,
  },
  {
    path: ROUTER_PATHS.detail,
    element: <DetailPage />,
  },
  {
    path: ROUTER_PATHS.game,
    element: <GamePage />,
  },
  {
    path: ROUTER_PATHS.gameReward,
    element: <GameRewardPage />,
  },
])

export const AppRouterProvider = () => {
  return <RouterProvider router={router} />
}
