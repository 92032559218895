import styled from 'styled-components'

const Container = styled.div`
  margin-bottom: 1rem;
  :not(:last-child)::after {
    content: '';
    display: block;
    height: 1px;
    background-color: #edeef3;
    margin-left: 64px;
  }
`

const DetailButton = styled.a`
  color: #ff8324;
  border: 1px solid #ff8324;
  border-radius: 32px;
  padding: 6px 12px;
  font-size: 12px;
  height: 28px;
`

export const QuotaItem = ({
  imgSrc,
  title,
  desc,
  count = 0,
  countMax = 3,
  detailUrl = '',
  detailButtonData = [],
}) => {
  return (
    <Container>
      <div className="flex justify-between items-start mb-6">
        <img className="mx-3 w-[40px]" src={imgSrc} loading="lazy" />
        <div className="w-full">
          <div className="text-black font-medium text-base mb-[6px]">{title}</div>
          {desc && <div className="text-[#858A94] text-sm font-light mb-[6px]">{desc}</div>}
          {detailUrl && (
            <div className="mb-[10px] h-[17px]">
              <a className="text-[#1E86DE] text-sm font-light" href={detailUrl}>
                รายละเอียดเพิ่มเติม
              </a>
            </div>
          )}
          {detailButtonData.length > 0 && (
            <div className="flex flex-wrap gap-1">
              {detailButtonData.map((item) => (
                <DetailButton key={item.text} href={item.href}>
                  {item.text}
                </DetailButton>
              ))}
            </div>
          )}
        </div>
        <div
          className="text-white font-medium text-xs px-2 py-1 rounded-[40px] mr-3"
          style={{
            backgroundColor: count >= countMax ? '#1E86DE' : '#DBEAFF',
            color: count >= countMax ? '#FFFFFF' : '#1E86DE',
          }}
        >
          {`${count}/${countMax}`}
        </div>
      </div>
    </Container>
  )
}
