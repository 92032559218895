import { httpClient } from '../../common/http'

// example response:
// {
//   "spendingSevenEleven": 0,
//   "spendingLotus": 0,
//   "spendingRetailStore": 0,
//   "spendingTruePoint": 0,
//   "spendingAlipay": 0,
//   "spendingOnline": 0,
//   "spendingTikTok": 0,
//   "spendingBill": 0,
//   "spendingDonation": 0,
//   "spendingTruemoveH": 0,
//   "spendingGoogleOrApple": 0,
//   "spendingMediaWithTrueMoney": 0,
//   "spendingTrueMoneyMastercard": 0,
//   "paymentMethodMySaving": 0,
//   "paymentMethodPayNext": 0,
//   "paymentMethodPayNextExtra": 0,
//   "transferTrueMoneyWallet": 0,
//   "savingMySaving": 0,
//   "creditKKPCashnow": 0,
//   "isAllFull": false
// }
export const requestQuota = async () => {
  try {
    const res = await httpClient.get('api/coupons/quota')
    return res.data
  } catch (error) {
    return null
  }
}
