import { IMAGE_SRC_DETAIL } from '../image-src'

export const ExpandIcon = ({ isActive = false }) => {
  return (
    <img
      src={IMAGE_SRC_DETAIL.arrow}
      className="w-[12px] transition-transform"
      style={{
        transform: isActive ? 'rotate(180deg)' : '',
      }}
    />
  )
}
