import { DATA_MAP_1 } from './data-map-1'
import { DATA_MAP_10 } from './data-map-10'
import { DATA_MAP_2 } from './data-map-2'
import { DATA_MAP_3 } from './data-map-3'
import { DATA_MAP_4 } from './data-map-4'
import { DATA_MAP_5 } from './data-map-5'
import { DATA_MAP_6 } from './data-map-6'
import { DATA_MAP_7 } from './data-map-7'
import { DATA_MAP_8 } from './data-map-8'
import { DATA_MAP_9 } from './data-map-9'
// import { IMAGE_SRC_GAME } from './image-src'

// export const REWARDS = [
//   { id: 'bike', imageSrc: IMAGE_SRC_GAME.rewardBike },
//   { id: 'car', imageSrc: IMAGE_SRC_GAME.rewardCar },
//   { id: 'money', imageSrc: IMAGE_SRC_GAME.rewardCoin },
//   { id: 'phone', imageSrc: IMAGE_SRC_GAME.rewardPhone },
// ]

// access path data by MAP_PATH[mapId][entryId]
export const MAP = {
  1: DATA_MAP_1,
  2: DATA_MAP_2,
  3: DATA_MAP_3,
  4: DATA_MAP_4,
  5: DATA_MAP_5,
  6: DATA_MAP_6,
  7: DATA_MAP_7,
  8: DATA_MAP_8,
  9: DATA_MAP_9,
  10: DATA_MAP_10,
}
