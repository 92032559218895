import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSearchParams } from 'react-router-dom'
import { Collapse } from 'antd'
import { ExpandIcon } from './components/ExpandIcon'
import { QuotaPanel } from './components/QuotaPanel'
import { PanelHeader } from './components/PanelHeader'
import { requestQuota } from './http'
import { QuotaFull } from './components/QuotaFull'
import { HowToPlay } from './components/HowToPlay'
import { TermCondition } from './components/TermCondition'
import { IMAGE_SRC_DETAIL } from './image-src'
import { preloadImages } from '../../common/preload'
import { LoadingWrapper, useLoading } from '../../common/loading'
import { useAppContext } from '../../context'

const StyledCollapse = styled(Collapse)`
  .ant-collapse-item:not(.ant-collapse-item-active)::after {
    content: '';
    background-color: #edeef3;
    display: block;
    height: 1px;
    margin: 0 12px;
  }
`

export const DetailPage = () => {
  const appContext = useAppContext()
  const loading = useLoading(2)
  const [searchParams] = useSearchParams()
  const [quotaData, setQuotaData] = useState({})
  const defaultTabKey = +searchParams.get('tab') || 1

  const fetchQuota = async () => {
    const data = await requestQuota()
    if (!data) return appContext.setStatusToError()
    setQuotaData(data)
    loading.doneStep()
  }

  const loadImages = async () => {
    await preloadImages([
      IMAGE_SRC_DETAIL.arrow,
      IMAGE_SRC_DETAIL.event711,
      IMAGE_SRC_DETAIL.eventLotus,
      IMAGE_SRC_DETAIL.eventMakro,
      IMAGE_SRC_DETAIL.eventRetailStore,
      IMAGE_SRC_DETAIL.eventTruePoint,
      IMAGE_SRC_DETAIL.eventOversea,
      IMAGE_SRC_DETAIL.eventOnline,
      IMAGE_SRC_DETAIL.eventTikTok,
      IMAGE_SRC_DETAIL.eventBill,
      IMAGE_SRC_DETAIL.eventDonation,
    ])
    loading.doneStep()
  }

  useEffect(() => {
    fetchQuota()
    loadImages()
  }, [])

  return (
    <LoadingWrapper isLoading={loading.isLoading}>
      <div>
        {!!quotaData.isAllFull && <QuotaFull className="mt-2" />}
        <StyledCollapse
          ghost
          accordion
          defaultActiveKey={defaultTabKey}
          expandIconPosition="end"
          expandIcon={(props) => <ExpandIcon isActive={props.isActive} />}
        >
          <Collapse.Panel header={<PanelHeader>รายละเอียดรับสิทธิ์</PanelHeader>} key={1}>
            <QuotaPanel dataSource={quotaData} />
          </Collapse.Panel>
          <Collapse.Panel header={<PanelHeader>วิธีเล่นเกม</PanelHeader>} key={2}>
            <HowToPlay />
          </Collapse.Panel>
          <Collapse.Panel header={<PanelHeader>ข้อกำหนดเงื่อนไข</PanelHeader>} key={3}>
            <TermCondition />
          </Collapse.Panel>
        </StyledCollapse>
      </div>
    </LoadingWrapper>
  )
}
