import { ModalError } from './ModalError'

export const ModalNoCoupon = ({ onClick, isOpen, onClickClose }) => {
  return (
    <ModalError
      title="สิทธิ์เล่นเกมของคุณหมดแล้ว"
      buttonText="ดูวิธีรับสิทธิ์เพิ่ม"
      isOpen={isOpen}
      buttonOnClick={onClick}
      onClickClose={onClickClose}
    />
  )
}

export const ModalNoCouponLanding = ({ onClick, isOpen, onClickClose }) => {
  return (
    <ModalError
      title="สิทธ์ในการเล่นเกม หมดแล้ว!"
      desc="มาดูวิธี เพิ่มสิทธิ์การเล่นได้ง่ายๆ"
      buttonText="ดูเลย"
      isOpen={isOpen}
      buttonOnClick={onClick}
      onClickClose={onClickClose}
    />
  )
}
