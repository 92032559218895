export const DATA_MAP_9 = {
  1: {
    rewardPosition: 4,
    paths: [
      { x: 0.2, y: 0.064 },
      { x: 0.2, y: 0.12 },
      { x: 0.2, y: 0.176 },
      { x: 0.2, y: 0.235 },
      { x: 0.203, y: 0.28 },
      { x: 0.203, y: 0.339 },
      { x: 0.224, y: 0.392 },
      { x: 0.269, y: 0.431 },
      { x: 0.312, y: 0.44 },
      { x: 0.36, y: 0.44 },
      { x: 0.403, y: 0.44 },
      { x: 0.461, y: 0.44 },
      { x: 0.515, y: 0.44 },
      { x: 0.565, y: 0.431 },
      { x: 0.608, y: 0.431 },
      { x: 0.659, y: 0.437 },
      { x: 0.693, y: 0.445 },
      { x: 0.733, y: 0.468 },
      { x: 0.771, y: 0.513 },
      { x: 0.781, y: 0.546 },
      { x: 0.784, y: 0.591 },
      { x: 0.787, y: 0.633 },
      { x: 0.787, y: 0.678 },
      { x: 0.787, y: 0.711 },
      { x: 0.792, y: 0.762 },
      { x: 0.792, y: 0.801 },
      { x: 0.789, y: 0.854 },
      { x: 0.795, y: 0.908 },
      { x: 0.795, y: 0.95 },
    ],
  },
  2: {
    rewardPosition: 2,
    paths: [
      { x: 0.392, y: 0.067 },
      { x: 0.395, y: 0.112 },
      { x: 0.395, y: 0.165 },
      { x: 0.395, y: 0.21 },
      { x: 0.397, y: 0.249 },
      { x: 0.4, y: 0.289 },
      { x: 0.4, y: 0.328, isFading: true },
      { x: 0.837, y: 0.829, isFading: true },
      { x: 0.837, y: 0.829 },
      { x: 0.792, y: 0.846 },
      { x: 0.739, y: 0.86 },
      { x: 0.693, y: 0.86 },
      { x: 0.635, y: 0.863 },
      { x: 0.579, y: 0.86 },
      { x: 0.531, y: 0.863 },
      { x: 0.467, y: 0.877 },
      { x: 0.419, y: 0.908 },
      { x: 0.419, y: 0.95 },
    ],
  },
  3: {
    rewardPosition: 1,
    paths: [
      { x: 0.592, y: 0.07 },
      { x: 0.592, y: 0.134 },
      { x: 0.565, y: 0.196 },
      { x: 0.507, y: 0.235 },
      { x: 0.475, y: 0.238 },
      { x: 0.435, y: 0.238 },
      { x: 0.357, y: 0.241 },
      { x: 0.293, y: 0.241 },
      { x: 0.235, y: 0.238 },
      { x: 0.181, y: 0.249 },
      { x: 0.133, y: 0.272 },
      { x: 0.109, y: 0.314 },
      { x: 0.101, y: 0.364 },
      { x: 0.101, y: 0.417 },
      { x: 0.101, y: 0.501 },
      { x: 0.101, y: 0.555 },
      { x: 0.101, y: 0.625 },
      { x: 0.123, y: 0.697 },
      { x: 0.152, y: 0.734 },
      { x: 0.176, y: 0.748 },
      { x: 0.203, y: 0.77 },
      { x: 0.203, y: 0.807 },
      { x: 0.203, y: 0.857 },
      { x: 0.2, y: 0.908 },
      { x: 0.2, y: 0.95 },
    ],
  },
  4: {
    rewardPosition: 3,
    paths: [
      { x: 0.792, y: 0.067 },
      { x: 0.792, y: 0.123 },
      { x: 0.789, y: 0.185 },
      { x: 0.784, y: 0.232 },
      { x: 0.76, y: 0.289 },
      { x: 0.717, y: 0.319 },
      { x: 0.661, y: 0.333 },
      { x: 0.597, y: 0.336 },
      { x: 0.533, y: 0.333 },
      { x: 0.488, y: 0.333 },
      { x: 0.437, y: 0.333 },
      { x: 0.373, y: 0.333 },
      { x: 0.317, y: 0.35 },
      { x: 0.277, y: 0.381 },
      { x: 0.251, y: 0.434 },
      { x: 0.248, y: 0.479 },
      { x: 0.248, y: 0.527 },
      { x: 0.269, y: 0.577 },
      { x: 0.301, y: 0.613 },
      { x: 0.344, y: 0.641 },
      { x: 0.397, y: 0.65 },
      { x: 0.451, y: 0.653 },
      { x: 0.504, y: 0.653 },
      { x: 0.539, y: 0.653 },
      { x: 0.557, y: 0.653 },
      { x: 0.597, y: 0.65 },
      { x: 0.619, y: 0.65, isFading: true },
      { x: 0.387, y: 0.751, isFading: true },
      { x: 0.387, y: 0.751 },
      { x: 0.443, y: 0.759 },
      { x: 0.499, y: 0.765 },
      { x: 0.541, y: 0.779 },
      { x: 0.579, y: 0.815 },
      { x: 0.592, y: 0.874 },
      { x: 0.592, y: 0.95 },
    ],
  },
}
