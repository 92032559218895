import React from 'react'
import moment from 'moment'

const HistoryList = ({ dataList = [] }) => {
  return dataList?.map((data) => {
    return (
      <div className="flex gap-3" key={data.ID}>
        <div className="bg-[#FFF3E9] rounded-full w-[40px] h-[40px] p-[5px] flex-shrink-0">
          <img className="w-full h-full object-contain" src={data.CoverImageUrl} />
        </div>
        <div
          className="flex flex-col pb-4 mb-6 w-full"
          style={{
            borderBottom: '1px solid #EDEEF3',
          }}
        >
          <div
            style={{
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '16px',
            }}
          >
            ใช้สิทธิ์ลุ้น {data.rewardName}
          </div>
          <div>
            <span
              style={{
                color: '#858A94',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
              }}
            >
              {moment(data.CreatedAt).format('DD/MM/YY HH:mm น.')}
            </span>
          </div>
        </div>
      </div>
    )
  })
}

export default HistoryList
