import React from 'react'
import styled from 'styled-components'
import titleRewards from '../../assets/home/titleRewards.png'
import scrollReward from '../../assets/home/scrollReward.png'
import titleImage from '../../assets/home/titleImage.png'
import titleBigRewardImage from '../../assets/home/titleBigRewardImage.png'
import { ConvertDate } from '../../utils/ConvertDate'
import { useRouter } from '../../common/routing'
import { HOME_IMGS } from './image'
import { sendRequestAnalyticEnterPageDetail } from '../../services/analytics/detail'
import { Winner } from '../../common/components/winner'

const Container = styled.div`
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-image: url(${HOME_IMGS.bg});
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
`

const Home = (props) => {
  const router = useRouter()

  const renderListRewards = () => {
    return props.listRewards
      .sort((a, b) => (a.Order > b.Order ? 1 : -1))
      .map((data) => {
        if (data.IsActive) {
          return (
            <div
              key={data.ID}
              style={{
                borderRadius: 10,
                borderColor: 'black',
                marginRight: '0.6rem',
                paddingTop: '0.3rem',
              }}
            >
              <div
                className="w-[120px] h-[110px] defaultScreen:w-[148px] defaultScreen:h-[133.12px]"
                style={{
                  display: 'flex',
                  boxShadow: '1px 1px 1px 1px rgba(157, 157, 157, 0.2)',
                  borderRadius: 15,
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  backgroundImage: `url(${data?.CoverImageUrl})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                }}
              >
                {data.IsGrandPrize && (
                  <div
                    style={{
                      display: 'flex',
                      backgroundImage: `url(${titleBigRewardImage})`,
                      backgroundRepeat: 'no-repeat',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundSize: 'cover',
                      position: 'relative',
                      top: '-6px',
                      zIndex: 100,
                    }}
                    className="defaultScreen:mb-[5.6rem] mb-[4.8rem] defaultScreen:w-[70px] defaultScreen:h-[17px] w-[60px] h-[15px]"
                  >
                    <span
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 500,
                        color: '#FFFFFF',
                        textAlign: 'center',
                      }}
                      className="defaultScreen:text-[10px] text-[9px]"
                    >
                      รางวัลใหญ่
                    </span>
                  </div>
                )}
                {props.listRewardStats.map((data2) => {
                  if (data?.ID === data2?.rewardId) {
                    return (
                      <div
                        key={data2.rewardId}
                        className="defaultScreen:w-[94.29px] defaultScreen:h-[18px] w-[85px] h-[16px] defaultScreen:mb-[0.5rem] mb-[0.1rem]"
                        style={{
                          display: 'flex',
                          backgroundImage: `url(${titleImage})`,
                          justifyContent: 'center',
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                          alignItems: 'center',
                        }}
                      >
                        <span
                          style={{
                            fontStyle: 'normal',
                            fontWeight: 400,
                            color: '#FFFFFF',
                          }}
                          className="defaultScreen:text-[12px] text-[11px]"
                        >
                          {data2?.count > 0
                            ? ' ลุ้น ' + data2?.count + ' สิทธิ์ '
                            : 'ยังไม่ได้สิทธิ์ลุ้น'}
                        </span>
                      </div>
                    )
                  }
                })}
              </div>
            </div>
          )
        }
      })
  }
  return (
    <Container>
      <div className="flex flex-col	w-full h-full items-center justify-start">
        <div className="mt-8">
          <Winner isShowLogo />
        </div>
        <div
          className="relative flex flex-col justify-center items-center bg-contain bg-no-repeat defaultScreen:mt-1 w-full defaultScreen:w-96"
          style={{
            backgroundImage: `url(${scrollReward})`,
            backgroundPositionX: '50%',
          }}
        >
          <div
            className="w-[177px] h-[32px]"
            style={{
              backgroundImage: `url(${titleRewards})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: '50% 0%',
              position: 'absolute',
              top: '-15px',
            }}
          >
            <span
              className="flex text-white font-bold justify-center items-center w-full h-full"
              style={{ fontSize: '14px' }}
            >
              รางวัลใหญ่รอคุณอยู่
            </span>
          </div>
          <span className="mt-4 mb-[0.8rem] opacity-[0.45] text-[12px] font-normal">
            ประกาศผลรางวัล {ConvertDate(props.roundEndDate)}
          </span>
          <div className="flex overflow-scroll w-[295px] pl-[10px] rounded-b-[35px] defaultScreen:w-[335px] pb-[20px]">
            {renderListRewards()}
          </div>
        </div>
        <button
          className="mt-4"
          disabled={!props?.unusedCouponCount}
          onClick={() => router.push(router.PATHS.game)}
        >
          <img
            className="w-[210px]"
            src={HOME_IMGS.btnPlay}
            style={{ filter: props?.unusedCouponCount ? 'unset' : 'grayscale(100%)' }}
          />
        </button>
        <span className="mt-2 mb-6 width-400:mb-6 width-375:mb-0 font-semibold text-sm tracking-wide">
          เหลือสิทธิ์เล่นอีก {props.unusedCouponCount} ครั้ง
        </span>
        <div className="flex justify-center items-center w-full py-8">
          <div className="flex justify-between items-center w-full max-w-[350px] px-4">
            <button
              className="w-[80px] mx-[-16px] h-full"
              onClick={() => {
                sendRequestAnalyticEnterPageDetail()
                router.push(router.paths.detail)
              }}
            >
              <img src={HOME_IMGS.btnDetail} />
            </button>
            <a
              className="flex items-center justify-center border-[2px] w-[126px] h-[35px] border-[#FF8324] rounded-[32px] text-[#FF8324] font-medium"
              href="https://www.truemoney.com/luckydraw-pay-announce"
            >
              ดูผลรางวัล
            </a>
            {/* <div className="flex flex-col">
              <button
                onClick={() => {
                  router.params.set('tab', 3)
                  router.push(router.paths.detail)
                }}
              >
                <span className="text-[12px] text-gray">ข้อกำหนดเงื่อนไข</span>
              </button>
            </div> */}
            <button className="w-[48px]" onClick={() => router.push(router.paths.history)}>
              <img className="object-cover" src={HOME_IMGS.btnHistory} />
            </button>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Home
