export const preloadImages = (imagePaths = []) => {
  const promises = []
  for (const imagePath of imagePaths) {
    const promise = new Promise((resolve) => {
      const image = new Image()
      image.src = imagePath
      image.onload = () => {
        resolve(imagePath)
      }
      image.onerror = () => {
        resolve(imagePath)
      }
    })
    promises.push(promise)
  }
  return Promise.all(promises)
}
