import { httpClient } from '../../common/http'

export const getServiceConfig = async () => {
  try {
    const res = await httpClient.get('api/configs')
    const isMaintenance = res.data?.isMaintenance || false
    const isCampaignActive = res.data?.isCampaignActive || false
    return {
      isMaintenance,
      isCampaignActive,
    }
  } catch (error) {
    console.error(error)
    return null
  }
}
