export const DATA_MAP_6 = {
  1: {
    rewardPosition: 1,
    paths: [
      { x: 0.219, y: 0.07 },
      { x: 0.216, y: 0.123 },
      { x: 0.219, y: 0.165 },
      { x: 0.219, y: 0.216 },
      { x: 0.235, y: 0.246 },
      { x: 0.264, y: 0.275, isFading: true },
      { x: 0.179, y: 0.417, isFading: true },
      { x: 0.179, y: 0.417 },
      { x: 0.139, y: 0.37 },
      { x: 0.131, y: 0.325 },
      { x: 0.136, y: 0.275 },
      { x: 0.152, y: 0.235 },
      { x: 0.181, y: 0.196 },
      { x: 0.232, y: 0.165 },
      { x: 0.285, y: 0.148 },
      { x: 0.339, y: 0.148 },
      { x: 0.405, y: 0.151 },
      { x: 0.451, y: 0.151 },
      { x: 0.493, y: 0.148 },
      { x: 0.565, y: 0.148 },
      { x: 0.619, y: 0.151 },
      { x: 0.656, y: 0.151 },
      { x: 0.712, y: 0.154 },
      { x: 0.757, y: 0.157 },
      { x: 0.795, y: 0.168 },
      { x: 0.827, y: 0.199 },
      { x: 0.848, y: 0.23 },
      { x: 0.859, y: 0.283 },
      { x: 0.861, y: 0.339 },
      { x: 0.861, y: 0.384 },
      { x: 0.861, y: 0.42 },
      { x: 0.856, y: 0.459 },
      { x: 0.843, y: 0.487 },
      { x: 0.824, y: 0.513 },
      { x: 0.8, y: 0.532, isFading: true },
      { x: 0.352, y: 0.546, isFading: true },
      { x: 0.352, y: 0.546 },
      { x: 0.309, y: 0.546 },
      { x: 0.269, y: 0.552 },
      { x: 0.229, y: 0.549 },
      { x: 0.184, y: 0.549 },
      { x: 0.141, y: 0.566 },
      { x: 0.107, y: 0.594 },
      { x: 0.093, y: 0.65 },
      { x: 0.093, y: 0.667, isFading: true },
      { x: 0.368, y: 0.669, isFading: true },
      { x: 0.368, y: 0.669 },
      { x: 0.328, y: 0.672 },
      { x: 0.285, y: 0.689 },
      { x: 0.251, y: 0.72 },
      { x: 0.227, y: 0.765 },
      { x: 0.221, y: 0.801 },
      { x: 0.219, y: 0.846 },
      { x: 0.219, y: 0.88 },
      { x: 0.216, y: 0.908 },
      { x: 0.216, y: 0.95 },
    ],
  },
  2: {
    rewardPosition: 3,
    paths: [
      { x: 0.419, y: 0.07 },
      { x: 0.419, y: 0.123 },
      { x: 0.416, y: 0.179 },
      { x: 0.421, y: 0.224 },
      { x: 0.421, y: 0.269 },
      { x: 0.419, y: 0.305 },
      { x: 0.416, y: 0.361 },
      { x: 0.392, y: 0.409 },
      { x: 0.363, y: 0.429 },
      { x: 0.309, y: 0.445 },
      { x: 0.253, y: 0.445 },
      { x: 0.211, y: 0.445 },
      { x: 0.187, y: 0.426, isFading: true },
      { x: 0.272, y: 0.272, isFading: true },
      { x: 0.272, y: 0.272 },
      { x: 0.309, y: 0.291 },
      { x: 0.347, y: 0.291 },
      { x: 0.405, y: 0.294 },
      { x: 0.445, y: 0.294 },
      { x: 0.493, y: 0.3 },
      { x: 0.536, y: 0.314 },
      { x: 0.568, y: 0.342 },
      { x: 0.579, y: 0.387 },
      { x: 0.581, y: 0.445 },
      { x: 0.581, y: 0.499 },
      { x: 0.579, y: 0.541 },
      { x: 0.563, y: 0.591 },
      { x: 0.547, y: 0.627 },
      { x: 0.507, y: 0.655 },
      { x: 0.461, y: 0.669 },
      { x: 0.421, y: 0.672 },
      { x: 0.381, y: 0.664, isFading: true },
      { x: 0.088, y: 0.675, isFading: true },
      { x: 0.088, y: 0.675 },
      { x: 0.093, y: 0.723 },
      { x: 0.091, y: 0.782 },
      { x: 0.107, y: 0.84 },
      { x: 0.141, y: 0.871 },
      { x: 0.2, y: 0.877 },
      { x: 0.251, y: 0.877 },
      { x: 0.307, y: 0.88 },
      { x: 0.349, y: 0.88 },
      { x: 0.405, y: 0.88 },
      { x: 0.467, y: 0.88 },
      { x: 0.517, y: 0.882 },
      { x: 0.557, y: 0.894 },
      { x: 0.6, y: 0.908 },
      { x: 0.6, y: 0.95 },
    ],
  },
  3: {
    rewardPosition: 2,
    paths: [
      { x: 0.611, y: 0.064 },
      { x: 0.611, y: 0.112 },
      { x: 0.611, y: 0.146 },
      { x: 0.611, y: 0.182 },
      { x: 0.611, y: 0.227 },
      { x: 0.611, y: 0.269 },
      { x: 0.611, y: 0.333 },
      { x: 0.637, y: 0.381 },
      { x: 0.68, y: 0.401 },
      { x: 0.741, y: 0.417 },
      { x: 0.795, y: 0.417 },
      { x: 0.875, y: 0.431 },
      { x: 0.907, y: 0.473 },
      { x: 0.944, y: 0.527 },
      { x: 0.941, y: 0.577 },
      { x: 0.939, y: 0.639 },
      { x: 0.909, y: 0.709 },
      { x: 0.867, y: 0.739 },
      { x: 0.816, y: 0.759 },
      { x: 0.757, y: 0.759 },
      { x: 0.693, y: 0.756 },
      { x: 0.648, y: 0.756 },
      { x: 0.597, y: 0.756 },
      { x: 0.533, y: 0.756 },
      { x: 0.477, y: 0.77 },
      { x: 0.435, y: 0.804 },
      { x: 0.416, y: 0.857 },
      { x: 0.411, y: 0.905 },
      { x: 0.411, y: 0.95 },
    ],
  },
  4: {
    rewardPosition: 4,
    paths: [
      { x: 0.811, y: 0.07 },
      { x: 0.811, y: 0.123 },
      { x: 0.805, y: 0.182 },
      { x: 0.797, y: 0.232 },
      { x: 0.763, y: 0.261 },
      { x: 0.723, y: 0.269 },
      { x: 0.664, y: 0.272 },
      { x: 0.611, y: 0.269 },
      { x: 0.565, y: 0.269 },
      { x: 0.525, y: 0.275 },
      { x: 0.493, y: 0.308 },
      { x: 0.475, y: 0.353 },
      { x: 0.464, y: 0.415 },
      { x: 0.464, y: 0.468 },
      { x: 0.453, y: 0.518 },
      { x: 0.419, y: 0.552 },
      { x: 0.384, y: 0.552 },
      { x: 0.363, y: 0.543, isFading: true },
      { x: 0.8, y: 0.527, isFading: true },
      { x: 0.8, y: 0.527 },
      { x: 0.757, y: 0.535 },
      { x: 0.723, y: 0.538 },
      { x: 0.675, y: 0.555 },
      { x: 0.643, y: 0.608 },
      { x: 0.637, y: 0.658 },
      { x: 0.635, y: 0.703 },
      { x: 0.635, y: 0.751 },
      { x: 0.648, y: 0.798 },
      { x: 0.675, y: 0.84 },
      { x: 0.72, y: 0.852 },
      { x: 0.765, y: 0.868 },
      { x: 0.797, y: 0.894 },
      { x: 0.797, y: 0.95 },
    ],
  },
}
