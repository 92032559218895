export const DATA_MAP_5 = {
  1: {
    rewardPosition: 4,
    paths: [
      { x: 0.203, y: 0.067 },
      { x: 0.2, y: 0.12 },
      { x: 0.203, y: 0.168 },
      { x: 0.203, y: 0.218 },
      { x: 0.203, y: 0.269 },
      { x: 0.2, y: 0.319 },
      { x: 0.2, y: 0.37 },
      { x: 0.224, y: 0.412 },
      { x: 0.245, y: 0.443 },
      { x: 0.288, y: 0.465 },
      { x: 0.339, y: 0.473 },
      { x: 0.387, y: 0.476 },
      { x: 0.44, y: 0.476 },
      { x: 0.488, y: 0.476 },
      { x: 0.533, y: 0.479 },
      { x: 0.568, y: 0.473 },
      { x: 0.632, y: 0.473 },
      { x: 0.672, y: 0.479 },
      { x: 0.723, y: 0.485 },
      { x: 0.763, y: 0.513 },
      { x: 0.795, y: 0.566 },
      { x: 0.795, y: 0.616 },
      { x: 0.797, y: 0.667 },
      { x: 0.797, y: 0.709 },
      { x: 0.797, y: 0.768 },
      { x: 0.797, y: 0.815 },
      { x: 0.797, y: 0.866 },
      { x: 0.797, y: 0.95 },
    ],
  },
  2: {
    rewardPosition: 1,
    paths: [
      { x: 0.395, y: 0.067 },
      { x: 0.395, y: 0.104 },
      { x: 0.397, y: 0.143 },
      { x: 0.397, y: 0.199 },
      { x: 0.397, y: 0.255 },
      { x: 0.397, y: 0.303 },
      { x: 0.397, y: 0.387 },
      { x: 0.4, y: 0.426 },
      { x: 0.4, y: 0.485 },
      { x: 0.405, y: 0.529 },
      { x: 0.421, y: 0.563 },
      { x: 0.437, y: 0.58, isFading: true },
      { x: 0.203, y: 0.776, isFading: true },
      { x: 0.203, y: 0.776 },
      { x: 0.203, y: 0.829 },
      { x: 0.203, y: 0.866 },
      { x: 0.203, y: 0.902 },
      { x: 0.203, y: 0.95 },
    ],
  },
  3: {
    rewardPosition: 3,
    paths: [
      { x: 0.595, y: 0.073 },
      { x: 0.597, y: 0.123 },
      { x: 0.595, y: 0.168 },
      { x: 0.592, y: 0.221 },
      { x: 0.589, y: 0.263 },
      { x: 0.571, y: 0.322 },
      { x: 0.52, y: 0.353 },
      { x: 0.467, y: 0.353 },
      { x: 0.424, y: 0.359 },
      { x: 0.368, y: 0.359 },
      { x: 0.325, y: 0.359 },
      { x: 0.283, y: 0.364 },
      { x: 0.243, y: 0.387 },
      { x: 0.224, y: 0.406 },
      { x: 0.208, y: 0.44 },
      { x: 0.208, y: 0.482 },
      { x: 0.203, y: 0.513, isFading: true },
      { x: 0.44, y: 0.723, isFading: true },
      { x: 0.44, y: 0.723 },
      { x: 0.493, y: 0.725 },
      { x: 0.539, y: 0.742 },
      { x: 0.576, y: 0.779 },
      { x: 0.603, y: 0.829 },
      { x: 0.6, y: 0.894 },
      { x: 0.6, y: 0.95 },
    ],
  },
  4: {
    rewardPosition: 2,
    paths: [
      { x: 0.795, y: 0.064 },
      { x: 0.792, y: 0.098 },
      { x: 0.789, y: 0.137 },
      { x: 0.776, y: 0.185 },
      { x: 0.741, y: 0.224 },
      { x: 0.693, y: 0.241 },
      { x: 0.653, y: 0.241 },
      { x: 0.621, y: 0.241 },
      { x: 0.587, y: 0.241 },
      { x: 0.547, y: 0.241 },
      { x: 0.504, y: 0.241 },
      { x: 0.467, y: 0.241 },
      { x: 0.419, y: 0.241 },
      { x: 0.387, y: 0.241 },
      { x: 0.339, y: 0.246 },
      { x: 0.293, y: 0.246 },
      { x: 0.237, y: 0.244 },
      { x: 0.192, y: 0.246 },
      { x: 0.16, y: 0.261 },
      { x: 0.128, y: 0.283 },
      { x: 0.107, y: 0.303 },
      { x: 0.091, y: 0.336 },
      { x: 0.096, y: 0.378 },
      { x: 0.096, y: 0.417 },
      { x: 0.091, y: 0.476 },
      { x: 0.091, y: 0.521 },
      { x: 0.091, y: 0.563 },
      { x: 0.093, y: 0.608 },
      { x: 0.115, y: 0.653 },
      { x: 0.136, y: 0.683 },
      { x: 0.165, y: 0.706 },
      { x: 0.203, y: 0.717 },
      { x: 0.24, y: 0.725 },
      { x: 0.28, y: 0.728 },
      { x: 0.307, y: 0.725 },
      { x: 0.355, y: 0.723 },
      { x: 0.389, y: 0.723 },
      { x: 0.429, y: 0.72 },
      { x: 0.44, y: 0.72, isFading: true },
      { x: 0.203, y: 0.493, isFading: true },
      { x: 0.203, y: 0.493 },
      { x: 0.203, y: 0.527 },
      { x: 0.203, y: 0.555 },
      { x: 0.205, y: 0.605 },
      { x: 0.205, y: 0.647 },
      { x: 0.205, y: 0.686 },
      { x: 0.205, y: 0.728 },
      { x: 0.205, y: 0.776, isFading: true },
      { x: 0.44, y: 0.577, isFading: true },
      { x: 0.44, y: 0.577 },
      { x: 0.467, y: 0.588 },
      { x: 0.504, y: 0.597 },
      { x: 0.541, y: 0.597 },
      { x: 0.589, y: 0.597 },
      { x: 0.637, y: 0.597 },
      { x: 0.685, y: 0.597 },
      { x: 0.733, y: 0.597 },
      { x: 0.771, y: 0.597 },
      { x: 0.811, y: 0.599 },
      { x: 0.848, y: 0.616 },
      { x: 0.883, y: 0.641 },
      { x: 0.901, y: 0.683 },
      { x: 0.904, y: 0.72 },
      { x: 0.893, y: 0.776 },
      { x: 0.893, y: 0.8 },
      { x: 0.872, y: 0.815 },
      { x: 0.827, y: 0.846 },
      { x: 0.773, y: 0.857 },
      { x: 0.675, y: 0.866 },
      { x: 0.616, y: 0.854 },
      { x: 0.541, y: 0.86 },
      { x: 0.501, y: 0.863 },
      { x: 0.456, y: 0.88 },
      { x: 0.427, y: 0.902 },
      { x: 0.427, y: 0.95 },
    ],
  },
}
