import { STATIC_URL } from '../../config'

export const IMAGE_SRC_DETAIL = {
  arrow: `${STATIC_URL}/common/imgs/arrow.png`,
  quotaFull: `${STATIC_URL}/detail/imgs/quota-full.png`,
  event711: `${STATIC_URL}/detail/imgs/event-711.png`,
  eventLotus: `${STATIC_URL}/detail/imgs/event-lotus.png`,
  eventMakro: `${STATIC_URL}/detail/imgs/event-makro.png`,
  eventRetailStore: `${STATIC_URL}/detail/imgs/event-retail-store.png`,
  eventTruePoint: `${STATIC_URL}/detail/imgs/event-truepoint.png`,
  eventOversea: `${STATIC_URL}/detail/imgs/event-oversea.png`,
  eventOnline: `${STATIC_URL}/detail/imgs/event-online.png`,
  eventBill: `${STATIC_URL}/detail/imgs/event-bill.png`,
  eventTikTok: `${STATIC_URL}/detail/imgs/event-tiktok.png`,
  eventDonation: `${STATIC_URL}/detail/imgs/event-donation.png`,
  eventTopup: `${STATIC_URL}/detail/imgs/event-topup.png`,
  eventApple: `${STATIC_URL}/detail/imgs/event-apple.png`,
  eventGoogle: `${STATIC_URL}/detail/imgs/event-google.png`,
  eventTmnMastercard: `${STATIC_URL}/detail/imgs/event-tmn-mastercard.png`,
  eventAlipay: `${STATIC_URL}/detail/imgs/event-alipay.png`,
  eventMedia: `${STATIC_URL}/detail/imgs/event-media.png`,
  eventLoanKKP: `${STATIC_URL}/detail/imgs/event-loan-kkp.png`,
  eventSavingKKP: `${STATIC_URL}/detail/imgs/event-saving-kkp.png`,
  eventTransfer: `${STATIC_URL}/detail/imgs/event-transfer.png`,
  eventPayNext: `${STATIC_URL}/detail/imgs/event-pay-next.png`,
  eventPayNextExtra: `${STATIC_URL}/detail/imgs/event-pay-next-extra.png`,
}

export const IMAGE_HOW_TO_PLAY = {
  howToPlay1: `${STATIC_URL}/detail/imgs/how-1.png`,
  howToPlay2: `${STATIC_URL}/detail/imgs/how-2.png`,
  howToPlay3: `${STATIC_URL}/detail/imgs/how-3.png`,
}
