import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { useEffect, useState } from 'react'

const indicator = <LoadingOutlined className="text-[#ff8324]" />

export const LoadingSpin = () => {
  return <Spin size="large" indicator={indicator} />
}

export const LoadingWrapper = ({ children, isLoading = false }) => {
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <LoadingSpin />
      </div>
    )
  }
  return children
}

export const useLoading = (totalStep = 1) => {
  const [step, setStep] = useState(0)
  const [isLoadingWithDelay, setIsLoadingWithDelay] = useState(true)
  const doneStep = () => setStep((prev) => prev + 1)
  const doneAll = () => setStep(totalStep)
  const reset = () => setStep(0)
  const isLoading = step < totalStep
  const isDone = step >= totalStep
  const percent = (step / totalStep) * 100

  useEffect(() => {
    const timer = setTimeout(() => setIsLoadingWithDelay(!isDone), 500)
    return () => clearTimeout(timer)
  }, [step])

  return {
    step,
    percent,
    totalStep,
    isLoading,
    isLoadingWithDelay,
    isDone,
    setStep,
    doneStep,
    doneAll,
    reset,
  }
}
