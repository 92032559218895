import { httpClient } from '../../common/http'

export const sendRequestUseCoupon = async (rewardRefId) => {
  try {
    await httpClient.post('api/coupons/transactions', {
      rewardRefId,
    })
    return true
  } catch (error) {
    return false
  }
}

/**
 *
 * @returns {Promise<Array>}
 */
export const requestRewards = async () => {
  try {
    const rewardRes = await httpClient.get('api/coupons/rewards?status=active')
    const rewards = rewardRes.data.value || []
    return rewards
  } catch (error) {
    return []
  }
}
