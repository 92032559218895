export const DATA_MAP_2 = {
  1: {
    rewardPosition: 4,
    paths: [
      { x: 0.213, y: 0.07 },
      { x: 0.211, y: 0.132 },
      { x: 0.211, y: 0.188 },
      { x: 0.213, y: 0.249 },
      { x: 0.216, y: 0.311 },
      { x: 0.216, y: 0.364 },
      { x: 0.256, y: 0.412 },
      { x: 0.304, y: 0.434 },
      { x: 0.36, y: 0.437 },
      { x: 0.424, y: 0.434 },
      { x: 0.475, y: 0.434 },
      { x: 0.533, y: 0.434 },
      { x: 0.603, y: 0.437 },
      { x: 0.659, y: 0.44 },
      { x: 0.715, y: 0.443 },
      { x: 0.757, y: 0.459 },
      { x: 0.795, y: 0.501 },
      { x: 0.805, y: 0.566 },
      { x: 0.805, y: 0.611 },
      { x: 0.805, y: 0.653 },
      { x: 0.803, y: 0.706 },
      { x: 0.803, y: 0.759 },
      { x: 0.803, y: 0.812 },
      { x: 0.8, y: 0.882 },
      { x: 0.8, y: 0.908 },
      { x: 0.8, y: 0.95 },
    ],
  },
  2: {
    rewardPosition: 3,
    paths: [
      { x: 0.413, y: 0.073 },
      { x: 0.411, y: 0.112 },
      { x: 0.408, y: 0.174 },
      { x: 0.408, y: 0.213 },
      { x: 0.408, y: 0.263 },
      { x: 0.411, y: 0.305 },
      { x: 0.413, y: 0.367 },
      { x: 0.413, y: 0.412 },
      { x: 0.421, y: 0.459 },
      { x: 0.44, y: 0.501 },
      { x: 0.472, y: 0.529 },
      { x: 0.515, y: 0.543 },
      { x: 0.555, y: 0.546 },
      { x: 0.603, y: 0.546 },
      { x: 0.635, y: 0.546 },
      { x: 0.669, y: 0.546 },
      { x: 0.701, y: 0.546 },
      { x: 0.744, y: 0.546 },
      { x: 0.792, y: 0.555 },
      { x: 0.837, y: 0.569 },
      { x: 0.872, y: 0.594 },
      { x: 0.901, y: 0.633 },
      { x: 0.909, y: 0.683 },
      { x: 0.915, y: 0.728 },
      { x: 0.912, y: 0.773 },
      { x: 0.888, y: 0.801 },
      { x: 0.867, y: 0.812, isFading: true },
      { x: 0.549, y: 0.751, isFading: true },
      { x: 0.584, y: 0.787 },
      { x: 0.605, y: 0.824 },
      { x: 0.608, y: 0.866 },
      { x: 0.608, y: 0.908 },
      { x: 0.608, y: 0.95 },
    ],
  },
  3: {
    rewardPosition: 1,
    paths: [
      { x: 0.6, y: 0.067 },
      { x: 0.6, y: 0.101 },
      { x: 0.603, y: 0.148 },
      { x: 0.603, y: 0.188 },
      { x: 0.6, y: 0.241 },
      { x: 0.592, y: 0.283 },
      { x: 0.573, y: 0.314 },
      { x: 0.531, y: 0.339 },
      { x: 0.485, y: 0.35 },
      { x: 0.424, y: 0.35 },
      { x: 0.379, y: 0.347 },
      { x: 0.328, y: 0.364 },
      { x: 0.299, y: 0.384 },
      { x: 0.272, y: 0.437 },
      { x: 0.259, y: 0.493 },
      { x: 0.264, y: 0.56 },
      { x: 0.283, y: 0.602 },
      { x: 0.307, y: 0.633 },
      { x: 0.344, y: 0.644 },
      { x: 0.405, y: 0.653, isFading: true },
      { x: 0.117, y: 0.521, isFading: true },
      { x: 0.115, y: 0.546 },
      { x: 0.115, y: 0.588 },
      { x: 0.115, y: 0.639 },
      { x: 0.128, y: 0.683 },
      { x: 0.152, y: 0.72 },
      { x: 0.179, y: 0.728 },
      { x: 0.205, y: 0.756 },
      { x: 0.216, y: 0.796 },
      { x: 0.216, y: 0.846 },
      { x: 0.216, y: 0.885 },
      { x: 0.215, y: 0.9 },
      { x: 0.215, y: 0.95 },
    ],
  },
  4: {
    rewardPosition: 2,
    paths: [
      { x: 0.805, y: 0.064 },
      { x: 0.803, y: 0.112 },
      { x: 0.8, y: 0.162 },
      { x: 0.781, y: 0.204 },
      { x: 0.749, y: 0.232 },
      { x: 0.685, y: 0.241 },
      { x: 0.64, y: 0.241 },
      { x: 0.597, y: 0.241 },
      { x: 0.544, y: 0.241 },
      { x: 0.485, y: 0.241 },
      { x: 0.448, y: 0.241 },
      { x: 0.416, y: 0.241 },
      { x: 0.36, y: 0.241 },
      { x: 0.325, y: 0.241 },
      { x: 0.269, y: 0.241 },
      { x: 0.219, y: 0.246 },
      { x: 0.173, y: 0.261 },
      { x: 0.144, y: 0.286 },
      { x: 0.123, y: 0.319 },
      { x: 0.117, y: 0.35 },
      { x: 0.117, y: 0.375 },
      { x: 0.117, y: 0.42 },
      { x: 0.117, y: 0.479 },
      { x: 0.117, y: 0.499, isFading: true },
      { x: 0.424, y: 0.644, isFading: true },
      { x: 0.461, y: 0.65 },
      { x: 0.507, y: 0.65 },
      { x: 0.547, y: 0.65 },
      { x: 0.589, y: 0.655 },
      { x: 0.629, y: 0.667 },
      { x: 0.672, y: 0.697 },
      { x: 0.691, y: 0.745 },
      { x: 0.688, y: 0.796 },
      { x: 0.693, y: 0.846 },
      { x: 0.688, y: 0.852 },
      { x: 0.637, y: 0.854 },
      { x: 0.595, y: 0.857 },
      { x: 0.549, y: 0.86 },
      { x: 0.507, y: 0.86 },
      { x: 0.467, y: 0.863 },
      { x: 0.437, y: 0.891 },
      { x: 0.437, y: 0.9 },
      { x: 0.42, y: 0.95 },
    ],
  },
}
