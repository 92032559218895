import React, { useState, useEffect } from 'react'
import History from '../../features/History/History'
import { httpClient } from '../../common/http'
import { useAppContext } from '../../context'

const IndexHistory = () => {
  const appContext = useAppContext()
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const [unusedCouponCount, setUnusedCouponCount] = useState(0)

  const fetchUnusedCouponCount = async () => {
    try {
      const res = await httpClient.get('api/coupons/unusedcount')
      const couponCountData = res.data
      const unusedCouponCount = couponCountData.count || 0
      setUnusedCouponCount(unusedCouponCount)
    } catch (error) {}
  }

  const fetchData = async () => {
    try {
      const rewardRes = await httpClient.get('api/coupons/rewards')
      const rewards = rewardRes.data.value || []
      const couponTransactionRes = await httpClient.get('api/coupons/transactions')
      const couponTransactions = couponTransactionRes.data.value || []
      const mappedTransactions = couponTransactions.map((transaction) => {
        const reward = rewards.find((reward) => reward.ID === transaction.RewardID)
        return {
          ...transaction,
          rewardName: reward?.Name || '',
          CoverImageUrl: reward?.RewardImageURL || '',
        }
      })
      setData(mappedTransactions)
    } catch (error) {
      console.error(error)
      appContext.setStatusToError()
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
    fetchUnusedCouponCount()
  }, [])

  return <History isLoading={isLoading} data={data} unusedCouponCount={unusedCouponCount} />
}

export default IndexHistory
