import { QuotaItem } from './QuotaItem'
import { QuotaSection } from './QuotaSection'
import { IMAGE_SRC_DETAIL } from '../image-src'
import { getDeviceData } from '../../../common/user-agent'

const EXTERNAL_LINKS = {
  alipay:
    'https://tmn.app.link/?deeplink=ascendmoney%3A%2F%2Fwallet.truemoney.co.th%2Fapp%2F660000000022%3Ftype%3Dcontent%26method%3D%257B%2522url%2522%253A%2522https%253A%252F%252Fwww.truemoney.com%252Fglobal-network-offline-payment%252F%2522%252C%2522title_th%2522%253A%2522%25E0%25B8%2597%25E0%25B8%25A3%25E0%25B8%25B9%25E0%25B8%25A1%25E0%25B8%25B1%25E0%25B8%2599%25E0%25B8%2599%25E0%25B8%25B5%25E0%25B9%2588%2522%252C%2522title_en%2522%253A%2522TrueMoney%2522%257D&deeplink_no_attribution=true',
  tikTok: 'https://www.truemoney.com/a/tiktokshop-promotion-02',
  billMe: 'ascendmoney://wallet.truemoney.co.th/app/660000000020',
  billOther: 'ascendmoney://wallet.truemoney.co.th/app/660000000007',
  truemoveHInternet:
    'https://tmn.app.link/?deeplink=ascendmoney%3A%2F%2Fwallet.truemoney.co.th%2Fapp%2F660000000022%3Ftype%3Dcontent%26method%3D%257B%2522url%2522%253A%2522https%253A%252F%252Ftopping.truemoveh.com%252Fwallet-app%252Fauth%2522%252C%2522title_th%2522%253A%2522%25E0%25B8%2597%25E0%25B8%25A3%25E0%25B8%25B9%25E0%25B8%25A1%25E0%25B8%25B1%25E0%25B8%2599%25E0%25B8%2599%25E0%25B8%25B5%25E0%25B9%2588%2522%252C%2522title_en%2522%253A%2522TrueMoney%2522%257D&deeplink_no_attribution=true',
  truemoveHTopup:
    'https://tmn.app.link/?deeplink=ascendmoney%3A%2F%2Fwallet.truemoney.co.th%2Fapp%2F660000000022%3Ftype%3Dcontent%26method%3D%257B%2522url%2522%253A%2522https%253A%252F%252Fmobile-topup-mini-app.public-a-cloud1p.ascendmoney.io%252Fmobile-topup-mini-app%252Fauthorization%2522%252C%2522title_th%2522%253A%2522%25E0%25B8%2597%25E0%25B8%25A3%25E0%25B8%25B9%25E0%25B8%25A1%25E0%25B8%25B1%25E0%25B8%2599%25E0%25B8%2599%25E0%25B8%25B5%25E0%25B9%2588%2522%252C%2522title_en%2522%253A%2522TrueMoney%2522%257D&deeplink_no_attribution=true',
}

const deviceData = getDeviceData()

export const QuotaPanel = ({ dataSource }) => {
  return (
    <div>
      <div className="mt-[-1rem] text-[#858A94]">
        <span>รับสิทธิ์เล่นเกมทันที เมื่อมีการทำรายการขั้นต่ำ 20บ./รายการ</span>
        <span> </span>
        <span>(จำกัดสูงสุด 3 สิทธิ์/ประเภทรายการ/เดือน)</span>
      </div>
      <QuotaSection label="ใช้จ่าย">
        <QuotaItem
          desc="7-Eleven / 7-Delivery หรือ ตู้สินค้า อัตโนมัติ"
          title="ใช้จ่ายที่ 7-Eleven"
          imgSrc={IMAGE_SRC_DETAIL.event711}
          count={dataSource.spendingSevenEleven}
        />
        <QuotaItem
          desc="Lotus's หรือ ตู้สินค้าอัตโนมัติ"
          title="ใช้จ่ายที่ Lotus's"
          imgSrc={IMAGE_SRC_DETAIL.eventLotus}
          count={dataSource.spendingLotus}
        />
        <QuotaItem
          title="ใช้จ่ายที่ Makro"
          desc="Makro หรือ Makro Pro"
          imgSrc={IMAGE_SRC_DETAIL.eventMakro}
          count={dataSource.spendingMakro}
        />
        <QuotaItem
          title="ใช้จ่ายที่ร้านค้าชั้นนำ"
          desc="ร้านค้าชั้นนำ หรือ ตู้สินค้าอัตโนมัติ"
          imgSrc={IMAGE_SRC_DETAIL.eventRetailStore}
          count={dataSource.spendingRetailStore}
        />
        <QuotaItem
          title="ใช้จ่ายที่ร้านค้า True Point & Pay"
          desc=""
          imgSrc={IMAGE_SRC_DETAIL.eventTruePoint}
          count={dataSource.spendingTruePoint}
        />
        <QuotaItem
          title="ใช้จ่ายต่างประเทศ ผ่าน Alipay+"
          imgSrc={IMAGE_SRC_DETAIL.eventOversea}
          count={dataSource.spendingAlipay}
          detailUrl={EXTERNAL_LINKS.alipay}
        />
        <QuotaItem
          title="ใช้จ่ายออนไลน์ เติมเกม หรือ ซื้อบัตรเงินสด"
          desc="เช่น Lazada, Foodpanda , MRT"
          imgSrc={IMAGE_SRC_DETAIL.eventOnline}
          count={dataSource.spendingOnline}
        />
        <QuotaItem
          title="ใช้จ่ายบน TikTok Shop"
          imgSrc={IMAGE_SRC_DETAIL.eventTikTok}
          count={dataSource.spendingTikTok}
          detailUrl={EXTERNAL_LINKS.tikTok}
        />
        <QuotaItem
          title="จ่ายบิล"
          imgSrc={IMAGE_SRC_DETAIL.eventBill}
          count={dataSource.spendingBill}
          detailButtonData={[
            {
              text: 'ชำระบิลทรูของฉัน',
              href: EXTERNAL_LINKS.billMe,
            },
            {
              text: 'ชำระบิลอื่นๆ',
              href: EXTERNAL_LINKS.billOther,
            },
          ]}
        />
        <QuotaItem
          title="บริจาค"
          imgSrc={IMAGE_SRC_DETAIL.eventDonation}
          count={dataSource.spendingDonation}
        />
        <QuotaItem
          title="ซื้อแพ็กเน็ต หรือ เติมเงิน ทรูมูฟ เอช"
          imgSrc={IMAGE_SRC_DETAIL.eventTopup}
          count={dataSource.spendingTruemoveH}
          detailButtonData={[
            {
              text: 'ซิ้อแพ็กเน็ต',
              href: EXTERNAL_LINKS.truemoveHInternet,
            },
            {
              text: 'เติมเงินค่าโทร',
              href: EXTERNAL_LINKS.truemoveHTopup,
            },
          ]}
        />
        {(deviceData.isAndroid || deviceData.isOther) && (
          <QuotaItem
            title="ซื้อแอป เติมเกม บน Google Play"
            imgSrc={IMAGE_SRC_DETAIL.eventGoogle}
            count={dataSource.spendingGoogle}
            detailUrl="https://www.truemoney.com/google-play/"
            detailButtonData={[
              {
                text: 'เชื่อมบัญชี',
                href: 'https://play.app.goo.gl/y8iH',
              },
            ]}
          />
        )}
        {(deviceData.isIOS || deviceData.isOther) && (
          <QuotaItem
            title="ซื้อแอป เติมเกม บน App Store"
            imgSrc={IMAGE_SRC_DETAIL.eventApple}
            count={dataSource.spendingApple}
            detailUrl="https://www.truemoney.com/apple/"
            detailButtonData={[
              {
                text: 'เชื่อมบัญชี Apple ID',
                href: 'https://apple.co/TrueMoney-setup',
              },
            ]}
          />
        )}
        <QuotaItem
          title="ซื้อคูปองส่วนลดจากร้านค้าชั้นนำบน Alipay+ Rewards"
          imgSrc={IMAGE_SRC_DETAIL.eventAlipay}
          count={dataSource.spendingAlipayReward}
          detailUrl="https://tmn.app.link/?deeplink=ascendmoney://wallet.truemoney.co.th/app/660000000029?type%3Dalipayreward%26method%3D%257B%2522app_id%2522%253A%25222102000024800001%2522%252C%2522page%2522%253A%2522%252Fpages%252Fdynamic-content%252Findex%2522%252C%2522query%2522%253A%2522pageCode%253DHotSKU%2526chInfo%253DTMN_eWallet_TMN_Feeds_1BSKUS%2522%257D"
        />
        <QuotaItem
          title="ดูหนัง ฟังเพลง จ่ายตรงด้วย TrueMoney"
          imgSrc={IMAGE_SRC_DETAIL.eventMedia}
          count={dataSource.spendingMediaWithTrueMoney}
        />
        <QuotaItem
          title="ใช้จ่ายด้วยบัตร TrueMoney Mastercard หรือ True Mastercard แบบเติมเงิน"
          imgSrc={IMAGE_SRC_DETAIL.eventTmnMastercard}
          count={dataSource.spendingTrueMoneyMastercard}
          detailUrl="https://tmn.app.link/?deeplink=ascendmoney%3A%2F%2Fwallet.truemoney.co.th%2Fapp%2F660000000022%3Ftype%3Dcontent%26method%3D%257B%2522url%2522%253A%2522https%253A%252F%252Fwww.truemoney.com%252Ftruemoney-mastercard%252F%2522%252C%2522title_th%2522%253A%2522%25E0%25B8%2597%25E0%25B8%25A3%25E0%25B8%25B9%25E0%25B8%25A1%25E0%25B8%25B1%25E0%25B8%2599%25E0%25B8%2599%25E0%25B8%25B5%25E0%25B9%2588%2522%252C%2522title_en%2522%253A%2522TrueMoney%2522%257D&deeplink_no_attribution=true"
        />
      </QuotaSection>
      <QuotaSection label="ช่องทางชำระเงิน">
        <QuotaItem
          title="จ่ายด้วย My Saving"
          detailUrl="https://tmn.app.link/?deeplink=ascendmoney%3A%2F%2Fwallet.truemoney.co.th%2Fapp%2F660000000022%3Ftype%3Dcontent%26method%3D%257B%2522url%2522%253A%2522https%253A%252F%252Fwww.truemoney.com%252Fstartsaving%252F%2522%252C%2522title_th%2522%253A%2522%25E0%25B8%2597%25E0%25B8%25A3%25E0%25B8%25B9%25E0%25B8%25A1%25E0%25B8%25B1%25E0%25B8%2599%25E0%25B8%2599%25E0%25B8%25B5%25E0%25B9%2588%2522%252C%2522title_en%2522%253A%2522TrueMoney%2522%257D&deeplink_no_attribution=true"
          imgSrc={IMAGE_SRC_DETAIL.eventSavingKKP}
          count={dataSource.paymentMethodMySaving}
        />
        <QuotaItem
          title="จ่ายด้วย เพย์ เน็กซ์"
          desc="เงินติดมือ ใช้ก่อน จ่ายทีหลัง"
          detailUrl="https://tmn.app.link/?deeplink=ascendmoney%3A%2F%2Fwallet.truemoney.co.th%2Fapp%2F660000000022%3Ftype%3Dcontent%26method%3D%257B%2522url%2522%253A%2522https%253A%252F%252Fwww.truemoney.com%252Fpay-next-pay-later%252F%2522%252C%2522title_th%2522%253A%2522%25E0%25B8%2597%25E0%25B8%25A3%25E0%25B8%25B9%25E0%25B8%25A1%25E0%25B8%25B1%25E0%25B8%2599%25E0%25B8%2599%25E0%25B8%25B5%25E0%25B9%2588%2522%252C%2522title_en%2522%253A%2522TrueMoney%2522%257D&deeplink_no_attribution=true"
          imgSrc={IMAGE_SRC_DETAIL.eventPayNext}
          count={dataSource.paymentMethodPayNext}
        />
        <QuotaItem
          title="เลือกจ่ายด้วยเพย์ เน็กซ์ เอ็กซ์ตร้า"
          desc="วงเงินพร้อมใช้ สแกนจ่าย หรือ ผ่อน"
          detailUrl="https://tmn.app.link/?deeplink=ascendmoney%3A%2F%2Fwallet.truemoney.co.th%2Fapp%2F660000000022%3Ftype%3Dcontent%26method%3D%257B%2522url%2522%253A%2522https%253A%252F%252Fwww.truemoney.com%252Fpay-next-extra-pay-later%252F%2522%252C%2522title_th%2522%253A%2522%25E0%25B8%2597%25E0%25B8%25A3%25E0%25B8%25B9%25E0%25B8%25A1%25E0%25B8%25B1%25E0%25B8%2599%25E0%25B8%2599%25E0%25B8%25B5%25E0%25B9%2588%2522%252C%2522title_en%2522%253A%2522TrueMoney%2522%257D&deeplink_no_attribution=true"
          imgSrc={IMAGE_SRC_DETAIL.eventPayNextExtra}
          count={dataSource.paymentMethodPayNextExtra}
        />
      </QuotaSection>
      <QuotaSection label="โอนเงิน">
        <QuotaItem
          title="โอนเงิน หรือ สร้างซองของขวัญ"
          imgSrc={IMAGE_SRC_DETAIL.eventTransfer}
          count={dataSource.transferTrueMoneyWallet}
          detailButtonData={[
            {
              text: 'โอนเงิน',
              href: 'ascendmoney://wallet.truemoney.co.th/app/660000000005',
            },
            {
              text: 'สร้างซองของขวัญ',
              href: 'ascendmoney://wallet.truemoney.co.th/app/660000000015',
            },
          ]}
        />
      </QuotaSection>
      <QuotaSection label="ออมเงิน">
        <QuotaItem
          title="ออมเงิน My Saving"
          imgSrc={IMAGE_SRC_DETAIL.eventSavingKKP}
          count={dataSource.savingMySaving}
          detailUrl="https://www.truemoney.com/startsaving/"
        />
      </QuotaSection>
      <QuotaSection label="สินเชื่อ">
        <QuotaItem
          title="เบิกเงินสด KKP Cash Now"
          imgSrc={IMAGE_SRC_DETAIL.eventLoanKKP}
          count={dataSource.creditKKPCashnow}
          detailUrl="https://www.truemoney.com/cashnow-kkp/"
          countMax={1}
        />
      </QuotaSection>
    </div>
  )
}
