import { STATIC_URL } from './config'

export const ErrorPage = () => {
  return (
    <div className="flex flex-col justify-center items-center h-full">
      <img
        className="grayscale w-[120px] mb-4"
        src={`${STATIC_URL}/common/imgs/ma.png`}
        alt="error"
      />
      <div className="flex flex-col justify-center items-center font-medium text-base text-[#858A94]">
        <div>ไม่สามารถโหลดหน้ากิจกรรมได้</div>
        <div>กรุณาลองใหม่ภายหลัง</div>
      </div>
    </div>
  )
}

export const ErrorMaintenancePage = () => {
  return (
    <div className="flex flex-col justify-center items-center h-full">
      <img
        className="grayscale w-[120px] mb-4"
        src={`${STATIC_URL}/common/imgs/ma.png`}
        alt="error"
      />
      <div className="flex flex-col justify-center items-center font-medium text-base text-[#858A94]">
        <div className="mb-3 text-[#0E1329] text-2xl font-medium">ปิดปรับปรุงชั่วคราว</div>
        <div>ไม่สามารถโหลดหน้ากิจกรรมได้</div>
        <div>กรุณาลองใหม่ภายหลัง</div>
      </div>
    </div>
  )
}
