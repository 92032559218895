import { HOME_IMGS } from '../../features/Home/image'

export const Winner = ({ isShowLogo = false }) => {
  return (
    <div className="relative flex flex-col justify-center items-center mb-6">
      <div
        className="flex justify-center items-center mb-[-20px]"
        style={{
          transform: isShowLogo ? 'translateX(-18px)' : 'unset',
        }}
      >
        {isShowLogo && (
          <div className="z-[110] w-[106px] m-[-12px]">
            <img src={HOME_IMGS.logo} />
          </div>
        )}
        <div
          className="z-[100] bg-white border-[2px] border-[#FF8324] rounded-[6.25rem] px-3 py-2"
          style={{}}
        >
          <div className="flex justify-center">
            <span className="font-[400] mr-[0.3rem] width-250:text-[7.5px] width-300:text-[10.5px] width-375:text-[12px] whitespace-nowrap">
              ผู้โชคดีรอบที่ 4{' '}
            </span>
            <span className="font-[700] width-250:text-[7.5px] width-300:text-[10.5px] width-375:text-[12px] whitespace-nowrap">
              คุณ อ่อนสา อุ่นเจริญ
            </span>
          </div>
        </div>
      </div>
      <div className="w-[96%] max-w-[280px]">
        <img
          src={HOME_IMGS.winnerImage}
          className="w-full rounded-2xl"
          style={{
            boxShadow: '6px 6px 0px -1px #FF8324',
            WebkitBoxShadow: '6px 6px 0px -1px #FF8324',
            MozBoxShadow: '6px 6px 0px -1px #FF8324',
          }}
        />
      </div>
    </div>
  )
}
export default Winner
