import React, { Fragment } from 'react'
import styled from 'styled-components'
import BgHead from '../assets/alert/bannerPopup.png'
import { FuncModalDetail } from '../utils/FuncModalDetail'
import ButtonAll from './ButtonAll'
import ImageLazyLoad from './ImageLazyLoad'
import kidDriveGetMoreGame from '../assets/alert/kidDriveGetMoreGame.png'
import kidDriveNoMoreGame from '../assets/alert/kidDriveNoMoreGame.png'
import { useRouter } from '../common/routing'

const BoxVisible = styled.div`
  opacity: ${(props) => (props.Visible ? 0 : 1)};
  overflow: hidden;
`
const BoxModal = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 98;
  margin: auto;
  left: 0;
  top: 0;
  /* will-change: opacity; */
  @keyframes fadein {
    from {
      opacity: ${(props) => (props.fadeout ? 1 : 0)};
    }
    to {
      opacity: ${(props) => (props.fadeout ? 0 : 1)};
    }
  }

  -webkit-animation: fadein 0.4s;
  -moz-animation: fadein 0.4s;
  -ms-animation: fadein 0.4s;
  -o-animation: fadein 0.4s;
  animation: fadein 0.4s;
`
const BoxModalDetail = styled.div`
  width: 90%;
  position: relative;
  margin: 38vh auto;
  background: #fff;
  border-radius: 2rem;

  @keyframes ${(props) => props.SlideIn.toLowerCase()} {
    from {
      ${(props) => props.SlideIn.toLowerCase()}: -150%;
    }
    to {
      ${(props) => props.SlideIn.toLowerCase()}: 0;
    }
  }

  -webkit-animation: ${(props) => props.SlideIn.toLowerCase()} 0.4s;
  -moz-animation: ${(props) => props.SlideIn.toLowerCase()} 0.4s;
  -ms-animation: ${(props) => props.SlideIn.toLowerCase()} 0.4s;
  -o-animation: ${(props) => props.SlideIn.toLowerCase()} 0.4s;
  animation: ${(props) => props.SlideIn.toLowerCase()} 0.4s;
`
const BoxImageTitle = styled.div`
  top: -5.8rem;
  display: flex;
  position: absolute;
  width: 100%;
  margin: auto;
  text-align: center;
  justify-content: center;
`
const DivCloseModal = styled.div`
  position: absolute;
  right: 0;
  margin: 20px;

  cursor: pointer;
  z-index: 100;
`
const DivMain = styled.div`
  margin: 1.2rem auto;
  text-align: center;
  font-weight: 500;
  font-size: 1.3rem;
  span {
    color: rgb(153, 153, 153);
    font-size: 1rem;
    font-weight: normal;
  }
`
const Divbottom = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
`
const ReturnModalImage = (Type) => {
  if (Type === 'success') {
    return <ImageLazyLoad alt={kidDriveGetMoreGame} src={kidDriveGetMoreGame} />
  } else {
    return <ImageLazyLoad alt={kidDriveNoMoreGame} src={kidDriveNoMoreGame} />
  }
}
const ReturnModalDetail = (Token, props, router) => {
  const { Title, Desction, BtnModal } = FuncModalDetail(props)
  const { ModalDetail } = props
  return (
    <Fragment>
      <DivMain>
        {Title}
        <br />
        <span>{Desction}</span>
      </DivMain>
      <Divbottom>
        <ButtonAll
          BgColor={'rgb(255, 143, 0)'}
          Value={BtnModal}
          OnClickFunction={() => ReturnGoTo(ModalDetail, props, router)}
        />
      </Divbottom>
    </Fragment>
  )
}
const ReturnGoTo = (Path, props, router) => {
  if (Path === 'error' || Path === 'permissionempty') {
    window.location = `ascendmoney://wallet.truemoney.co.th/app/660000000001?type=home`
  } else if (Path === 'firstTime') {
    router.params.set('tab', 1)
    router.push(router.paths.detail)
  } else {
    props.OnClose()
  }
}

// modal show status permission
const ModalStart = (props) => {
  const router = useRouter()

  const { SlideIn, Visible, OnClose, Token, ModalDetail, ModalType } = props
  console.log({ props })
  return (
    <BoxVisible Visible={Visible}>
      <BoxModal>
        <BoxModalDetail SlideIn={SlideIn}>
          {/* <div className="bg-red-500">{ReturnModalImage(ModalType)}</div> */}
          <BoxImageTitle>{ReturnModalImage(ModalType)}</BoxImageTitle>
          {ModalDetail === 'error' || !ModalType ? null : (
            <DivCloseModal
              onClick={() => {
                OnClose()
              }}
            >
              <span style={{ fontSize: '1.3rem', color: '#4B5060' }}>X</span>
            </DivCloseModal>
          )}

          {ModalType === 'success' ? (
            <div
              style={{
                borderRadius: '2rem 2rem 0 0',
                backgroundImage: `url(${BgHead})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: '113.78px',
              }}
              className="flex justify-center"
            ></div>
          ) : (
            <div
              style={{
                filter: 'grayscale(100%)',
                borderRadius: '2rem 2rem 0 0',
                backgroundImage: `url(${BgHead})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: '113.78px',
              }}
              className="flex justify-center"
            ></div>
          )}

          {ReturnModalDetail(Token, props, router)}
        </BoxModalDetail>
      </BoxModal>
    </BoxVisible>
  )
}
ModalStart.defaultProps = {
  Visible: false,
  SlideIn: 'bottom',
  OnClose: () => console.log('boat'),
}
export default ModalStart
