import { IMAGE_HOW_TO_PLAY } from '../image-src'

export const HowToPlay = () => {
  return (
    <div>
      <div className="flex justify-center">
        <div className="overflow-hidden w-[150px] h-[266px] mr-7">
          <img src={IMAGE_HOW_TO_PLAY.howToPlay1} loading="lazy" />
        </div>
        <div className="overflow-hidden w-[150px] h-[266px]">
          <img src={IMAGE_HOW_TO_PLAY.howToPlay2} loading="lazy" />
        </div>
      </div>
      <div className="flex text-[#4B5060] mb-5">
        <div className="mr-1">1.</div>
        <div className="text-sm">
          เลือกของรางวัล และจำเส้นทางให้ได้ใน 5 วินาที ก่อนที่จะมีเมฆมาบัง
        </div>
      </div>
      <div className="flex justify-center">
        <div className="overflow-hidden w-[150px] h-[266px]">
          <img src={IMAGE_HOW_TO_PLAY.howToPlay3} loading="lazy" />
        </div>
      </div>
      <div className="flex text-[#4B5060]">
        <div className="mr-1">2.</div>
        <div className="text-sm">
          <div className="mb-4">กดลาก “เด็กน้อย” ไปปล่อยที่หน้าทางเข้า ที่ต้องการ ลุ้นรางวัล</div>
          <div className="text-xs text-[#858A94]">
            หมายเหตุ: อ่านรายละเอียดและวันที่การจับ/ประกาศรางวัลผู้ชนะ เพิ่มเติมได้ที่{' '}
            <span className="font-medium">"ข้อกำหนดและเงื่อนไข"</span>
          </div>
        </div>
      </div>
    </div>
  )
}
