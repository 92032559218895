import React, { createContext, useState } from 'react'

export const AppContext = createContext({
  isError: false,
  setStatusToError: () => {},
  isMaintenance: false,
  setStatusToMaintenance: () => {},
})

export const AppContextProvider = ({ children }) => {
  const [isError, setIsError] = useState(false)
  const [isMaintenance, setIsMaintenance] = useState(false)
  const setStatusToError = () => setIsError(true)
  const setStatusToMaintenance = () => setIsMaintenance(true)
  const providedValue = {
    isError,
    setStatusToError,
    isMaintenance,
    setStatusToMaintenance,
  }
  return <AppContext.Provider value={providedValue}>{children}</AppContext.Provider>
}

export const useAppContext = () => {
  return React.useContext(AppContext)
}
