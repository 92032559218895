import { ModalError } from './ModalError'

export const ModalCommonError = ({ onClick, isOpen, onClickClose }) => {
  return (
    <ModalError
      title="ไม่สามารถดาวน์โหลดได้"
      desc="กรุณาลองใหม่ภายหลัง"
      buttonText="ตกลง"
      isOpen={isOpen}
      buttonOnClick={onClick}
      onClickClose={onClickClose}
    />
  )
}
